import { FC } from 'react';
import { Tooltip } from 'react-tooltip';
import styled from 'styled-components';
import infoIcon from '../../images/infoIcon.svg';
import { HeaderText } from '../typography/HeaderText';
import { BodyText } from '../typography/BodyText';
import Card from '../outlines/Card';

interface BoxProps {
  icon: string;
  title: string;
  text: string;
  tooltip?: string;
}

const BoxContent = styled.div`
  padding-top: 17px;
  display: flex;
  align-items: flex-start;
  gap: 12px;
`;

const BoxIconContainer = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 14px;
`;

const TooltipIcon = styled.img`
  width: 1rem;
  height: 1rem;
`;

const Box: FC<BoxProps> = ({ icon, title, text, tooltip }) => (
  <Card width="229px" height="131px" borderStyle="none">
    <BoxContent>
      <BoxIconContainer>
        <img src={icon} alt={title} />
      </BoxIconContainer>
      <div>
        <HeaderText size="medium">{title}</HeaderText>
        <BodyText size="large">
          {text}
          {tooltip && (
            <>
              {' '}
              <TooltipIcon src={infoIcon} id={title} data-tooltip-content={tooltip} />
              <Tooltip
                anchorId={title}
                style={{
                  backgroundColor: '#FFFFFF',
                  color: '#1E1E1E',
                  border: '1px solid #FAFBFC',
                  borderRadius: '4px',
                  maxWidth: '20.1875rem',
                  maxHeight: '6.875rem',
                  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.12)',
                }}
                place="bottom"
              />
            </>
          )}
        </BodyText>
      </div>
    </BoxContent>
  </Card>
);

const BoxRowContainer = styled.div`
  display: flex;
  padding: 0px 14px 104px 0px;
  gap: 32px;

  flex-wrap: wrap;
  gap: 24px;
  width: 100%;

  > div {
    flex: 1 0 calc(25% - 24px);
    padding: 16px;
  }

  @media (max-width: 800px) {
    > div {
      flex: 1 0 calc(50% - 24px);
    }
  }
`;

interface BoxRowProps {
  boxes: BoxProps[];
}

export const IconBoxRow: FC<BoxRowProps> = ({ boxes }) => (
  <BoxRowContainer>
    {boxes.map((box, index) => (
      <Box key={index} icon={box.icon} title={box.title} text={box.text} tooltip={box.tooltip} />
    ))}
  </BoxRowContainer>
);
