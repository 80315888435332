import { StyledEmail } from './common-styles';
import { BodyText } from '../typography/BodyText';
import styled from 'styled-components';

const StyledContentContainer = styled.div`
  margin-top: 32px;
`;

export function AutoUploadModalContent() {
  return (
    <StyledContentContainer>
      <BodyText size="large">
        You can create a script to automatically pass data to Nickels for analysis every month based
        on an automated pull of your checking account data.
        <br />
        <br />
        To get started with Auto Upload please reach out to{' '}
        <StyledEmail>support@asknickels.com</StyledEmail> so that we can provide you with file
        transfer details.
      </BodyText>
    </StyledContentContainer>
  );
}
