import { FC } from 'react';
import styled from 'styled-components';
import { PageLayout } from '../components/PageLayout';

const StyledContenatLayout = styled.div`
  /* responsive */
  padding: 48px;
`;

const StyledContentTitle = styled.h1`
  margin-top: 0;
  color: black;
`;

export const NotFoundPage: FC = () => (
  <PageLayout>
    <StyledContenatLayout>
      <StyledContentTitle>Not Found</StyledContentTitle>
    </StyledContenatLayout>
  </PageLayout>
);
