import { PageLayout } from '../../components/PageLayout';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Title } from '../../components/TextComponents/TextComponents';
import { HeaderText } from '../../components/typography/HeaderText';
import Card from '../../components/outlines/Card';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import styled from 'styled-components';
import TextBoxRow from '../../components/TextBoxRow';
import BackButton from '../../components/buttons/BackButton';
import BarGraph from '../../components/charts/BarGraph';
import { PageLoader } from '../../components/PageLoader';
import { useClientStore } from '../../stores/clientStore';
import { getUserType } from '../../utils/account-holder-translation';
import {
  getAnalysisResultOutputFileKeys,
  getAnalysisResultOutputPresignedUrls,
  getUniqueClientMonthlyStats,
} from '../../services/client-monthly-stats.service';
import {
  getRecentRevolverCount,
  type RecentRevolverCount,
} from '../../services/member-categorization-records.service';
import { getRevolvingAccounts } from '../../services/revolving-accounts.service';
import { ClientMonthlyStats } from '../../models/ClientMonthlyStats';
import { RevolvingAccounts } from '../../models/RevolvingAccounts';
import { TopCardProviderPayments } from '../../models/TopCardProviderPayments';
import { getTopCardProviderPayments } from '../../services/top-card-provider-payments.service';
import { getFullMonthNameFromString } from '../../utils/date';
import {
  abbreviateToInt,
  abbreviateNumber,
  sanitizeLikelyRevolverData,
  compareTabs,
  areAllNumberFieldsZero,
} from '../../utils/common';
import { bankNameMapping } from './helpers';
import { useRollbar } from '@rollbar/react';
import { BodyText } from '../../components/typography/BodyText';
import { DesignSystemColorsTheme, baseColors } from '../../components/themeing/themes';
import Tooltip from '../../components/Tooltip';
import greenDot from '../../images/green-dot.svg';
import greyDot from '../../images/grey-dot.svg';
import blueDot from '../../images/blue-dot.svg';
import union from '../../images/union.svg';
import creditCardIcon from '../../images/creditCardIcon.svg';
import rotatingArrows from '../../images/rotating-arrows.svg';
import dollarSign from '../../images/dollarSign.svg';
import RoundBoxRow from '../../components/RoundedBox';
import PrimaryButton from '../../components/buttons/PrimaryButton';

// Commented out as we use this for the hyperlink portion of the breakdown boxes
// which links to specfic markerting campaign which we have not fleshed out yet
// import TertiaryButton from '../../components/buttons/TertiaryButton';

// const StyledTertiary = styled(TertiaryButton)`
//   border: 1px solid ${(props) => props.theme.primary.primary100};
//   font-style: normal;
//   font-weight: 600;
//   line-height: 20px;
//   color: transparent;
// `;
import TabsNavigation, { TabData } from '../../components/navigation/Tabs';
import RevolverOverviewTab from '../tabs/RevolverOverview';
import HeavyRevolversTab from '../tabs/HeavyRevolvers';
import LightRevolversTab from '../tabs/LightRevolvers';
import NewRevolversTab from '../tabs/NewRevolvers';
import JSZip from 'jszip';
import PrimaryMultiSelectDropdown, {
  Option,
} from '../../components/dropdowns/PrimaryMultiSelectDropdown';
import AccountStatsTab from '../tabs/AccountStats';
import ProviderInsightsTab from '../tabs/ProviderInsights';
import { MonthlyNewRevolverStats } from '../../models/MonthlyNewRevolverStats';
import { getMonthlyNewRevolverStats } from '../../services/monthly-new-revolver-stats.service';
import AmountsPaidTab from '../tabs/AmountsPaid';
import TransactorOverviewTab from '../tabs/TransactorOverview';
import { TransactorTopBankPayments } from '../../models/TransactorTopBankPayments';
import { TransactorAccountsCount } from '../../models/TransactorAccountsCount';
import { getTransactorTopBankPayments } from '../../services/transactor-top-bank-payments';
import { RevolverTopBankPayments } from '../../models/RevolverTopBankPayments';
import { getRevolverTopBankPayments } from '../../services/revolver-top-bank-payments.service';
import { getTransactorAccountsCount } from '../../services/transactor-accounts-count';

const BackButtonContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 12px;
`;

const TransactorTabsContainer = styled.div`
  margin-top: 40px;
`;

const StyledCard = styled(Card)`
  padding 40px;
  box-shadow: 0px 8px 10px rgba(65, 61, 231, 0.03), 0px 2px 5px rgba(85, 85, 88, 0.02);
  border-radius: 24px;
  margin-top: 48px;
  margin-bottom: 48px;

  border: ${(props) => `1px solid ${props.theme.primary.primary20}`};


`;

const StyledTitle = styled(Title)`
  padding-top: 0.25rem;
`;

const SectionDivider = styled.div`
  margin-top: 3.5rem;
  margin-bottom: 3rem;
`;

const BarChartContainer = styled.div`
  max-width: 95%;
  height: 282px;
  padding-top: 16px;
`;

const BarChartHeader = styled.div`
  h1 {
    margin-bottom: 16px;
  }
  p {
    margin-bottom: 32px;
  }
`;

const ThirdPartyTitle = styled(HeaderText)`
  margin-bottom: 16px;
`;

const ThirdPartySubtitle = styled(BodyText)`
  margin-bottom: 32px;
`;

const TitleButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
`;

const HighlightsHeader = styled(HeaderText)`
  margin-bottom: 16px;
`;

const HighlightsSubheader = styled(BodyText)`
  margin-bottom: 32px;
`;

const DetermineFullAnalysis = (dataYear: string) => {
  // Full analysis file for clients changed in Jan 2024
  // We make previous version available for backward compatability with previous analysis
  if (Number(dataYear) < 2024) {
    return 'analysis_results.csv';
  }
  return 'full_analysis_results.csv';
};

const ViewMonthlyAnalysisPage = () => {
  const rollbar = useRollbar();
  const [revolverTabs, setRevolverTabs] = useState<TabData[] | null>(null);
  const [transactorTabs, setTransactorTabs] = useState<TabData[] | null>(null);
  const [clientMonthlyStats, setClientMonthlyStats] = useState<ClientMonthlyStats>();
  const [recentCount, setRecentCount] = useState<RecentRevolverCount>();
  const [newRevolverStats, setNewRevolverStats] = useState<MonthlyNewRevolverStats>();
  const [revolvingAccounts, setRevolvingAccounts] = useState<RevolvingAccounts>();
  const [revolverTopBankPayments, setRevolverTopBankPayments] =
    useState<RevolverTopBankPayments[]>();
  const [topCardProviderPayments, setTopCardProviderPayments] =
    useState<TopCardProviderPayments[]>();
  const [transactorTopBankPayments, setTransactorTopBankPayments] =
    useState<TransactorTopBankPayments[]>();
  const [transactorAccountsCount, setTransactorAccountsCount] =
    useState<TransactorAccountsCount[]>();
  const [availableFilesForDownload, setAvailableFilesForDownload] = useState<string[]>([]);
  const [availableOptions, setAvailableOptions] = useState<Option[]>([]);
  const { year, month } = useParams();
  const { getAccessTokenSilently, user } = useAuth0();

  // default to using analysis file w/ out recent revolver
  const [exportFileValues, setExportFileValues] = useState<Option[]>([
    { label: 'Full Analysis', value: DetermineFullAnalysis(year || '2024') },
  ]);

  const userClient = useClientStore((state) => state.client);
  const institutionType = userClient?.institutionType;

  // Custom comparison function
  const customRevolverSort = useMemo(() => {
    // tab sorting order
    const customMapping: Record<string, number> = {
      Overview: 1,
      'New Revolver Stats': 2,
      'Provider Insights': 3,
      'Account Stats': 4,
    };
    return (a: TabData, b: TabData) => {
      const aMapped = customMapping[a.name];
      const bMapped = customMapping[b.name];
      return aMapped - bMapped;
    };
  }, []);

  const customTransactorSort = useMemo(() => {
    // tab sorting order
    const customMapping: Record<string, number> = {
      Overview: 1,
      'Amounts Paid': 2,
    };
    return (a: TabData, b: TabData) => {
      const aMapped = customMapping[a.name];
      const bMapped = customMapping[b.name];
      return aMapped - bMapped;
    };
  }, []);

  let highlightsText = '';
  let highlightsSubheader = '';
  let boxes = null;
  let roundboxes = null;
  let breakdownBoxes = null;
  let barData = null;
  let likelyRevolverData = null;
  let percentageOfLightRevolvers = '';
  let percentageOfTransactors = '';
  let percentageNotIdentified = '';
  let percentageOfHeavyRevolvers = '';
  let percentageCardPayerRevolvers = '';
  let totalNumberOfMembers = 0;
  let refinanceOpportunity = 0;

  const handleDownload = useCallback(
    async (analysisYear: string, analysisMonth: string) => {
      const accessToken = await getAccessTokenSilently();

      // Check if 'Full Analysis' is selected
      const FullAnalysisValue = DetermineFullAnalysis(analysisYear);
      if (accessToken) {
        let desiredFiles = availableFilesForDownload?.filter((file) =>
          exportFileValues.some((desiredFile) => {
            // Extract filename from the desiredFiles entry
            const fileName = file.split('/').pop();
            // Match only if the file name is exactly the same as the option value
            return fileName === desiredFile.value;
          }),
        );

        const fullAnalysisSelected = exportFileValues.some(
          (option) => option.value === FullAnalysisValue,
        );

        // If 'Full Analysis' is selected, ensure 'new_revolvers.csv' is included
        if (fullAnalysisSelected) {
          const newRevolverFile = availableFilesForDownload?.find((file) =>
            file.endsWith('/new_revolvers.csv'),
          );
          if (newRevolverFile && !desiredFiles.includes(newRevolverFile)) {
            desiredFiles = [...desiredFiles, newRevolverFile];
          }
        }

        if (desiredFiles) {
          const presignedUrls = await getAnalysisResultOutputPresignedUrls(
            accessToken,
            desiredFiles,
          );

          if (presignedUrls.data) {
            const zip = new JSZip();

            // Map each URL to a fetch request and store in promises array
            const promises = presignedUrls.data.map(async (presignedUrl, index) => {
              const response = await fetch(presignedUrl);
              const blob = await response.blob();

              // Extract filename from the desiredFiles entry
              const fileName = desiredFiles[index].split('/').pop();

              // Add the blob to the zip file using the extracted filename
              if (fileName) {
                zip.file(fileName, blob);
              }
            });

            // Wait for all fetches to complete
            Promise.all(promises).then(async () => {
              // Generate the zip file
              const zipBlob = await zip.generateAsync({ type: 'blob' });

              // Download the zip file
              const fileURL = window.URL.createObjectURL(zipBlob);
              const alink = document.createElement('a');
              alink.href = fileURL;
              alink.download = `results-${analysisYear}-${analysisMonth}.zip`;
              alink.click();
            });
          }
        }
      }
    },
    [getAccessTokenSilently, availableFilesForDownload, exportFileValues],
  );

  useEffect(() => {
    const getUserInfo = async () => {
      const accessToken = await getAccessTokenSilently();

      if (user && user.email) {
        if (year && month) {
          // Full analysis file for clients changed in Jan 2024
          // We make previous version available for backward compatability with previous analysis

          // List of all potential options for the dropdown which will be filtered below
          const potentialDownloadOptions: Option[] = [
            { label: 'Full Analysis', value: DetermineFullAnalysis(year) },
            { label: 'Heavy Revolvers', value: 'heavy_revolvers.csv' },
            { label: 'Light Revolvers', value: 'light_revolvers.csv' },
            { label: 'New Revolvers', value: 'new_revolvers.csv' },
            { label: 'Revolver Primary Cards', value: 'revolver_primary_cards.csv' },
            { label: 'Multi-Account Revolvers', value: 'multi_account_revolvers.csv' },
            { label: 'All Transactors', value: 'all_transactors.csv' },
            { label: 'High Spend Transactors', value: 'high_spend_transactors.csv' },
          ];

          // 1. call client-monthly-stats API
          const { data: monthlyStats } = await getUniqueClientMonthlyStats(
            accessToken,
            year,
            month,
          );
          if (monthlyStats) {
            setClientMonthlyStats(monthlyStats as ClientMonthlyStats);
          }

          // 2. call revolving-accounts API
          const { data: revAccounts } = await getRevolvingAccounts(accessToken, year, month);
          if (revAccounts) {
            setRevolvingAccounts(revAccounts);
          }

          // 3. call top-card-providers-payments API
          const { data: providerPayments } = await getTopCardProviderPayments(
            accessToken,
            year,
            month,
          );
          if (providerPayments) {
            setTopCardProviderPayments(providerPayments);
          }

          // 4. call recent-revolver-count API
          const response =
            userClient && (await getRecentRevolverCount(accessToken, userClient.id, year, month));
          const recentRevolverCount = response?.data as RecentRevolverCount;
          if (recentRevolverCount) {
            setRecentCount(recentRevolverCount);
          }
          // 5. fetch available downloads from S3 (this just returns a list of S3 object keys)
          const availableFiles = await getAnalysisResultOutputFileKeys(accessToken, year, month);

          let localAvailableFilesForDownload: string[] = [];
          if (availableFiles.data) {
            localAvailableFilesForDownload = availableFiles.data;
          }

          // 6. set the dropdown options for the available downloads
          const availableFileOptions = potentialDownloadOptions.filter((option) =>
            localAvailableFilesForDownload.some((file) => {
              // Split the file path to get the file name
              const fileName = file.split('/').pop();
              // Match only if the file name is exactly the same as the option value
              return fileName === option.value;
            }),
          );

          if (availableFileOptions) {
            setAvailableOptions(availableFileOptions);
          }

          // Update the state
          setAvailableFilesForDownload(localAvailableFilesForDownload);

          // 6. Call transactor-top-bank-payments API
          const { data: transactorPayments } = await getTransactorTopBankPayments(
            accessToken,
            year,
            month,
          );
          if (transactorPayments) {
            setTransactorTopBankPayments(transactorPayments);
          }
          // 7. call revolver-top-bank-payments API
          const { data: revolverTopBankPaymentsData } = await getRevolverTopBankPayments(
            accessToken,
            year,
            month,
          );
          if (revolverTopBankPaymentsData) {
            setRevolverTopBankPayments(revolverTopBankPaymentsData);
          }
          // 8. call transactors-accounts-count API
          const { data: accountsCount } = await getTransactorAccountsCount(
            accessToken,
            year,
            month,
          );
          if (accountsCount) {
            setTransactorAccountsCount(accountsCount);
          }
        }
      }
    };
    getUserInfo();
  }, [getAccessTokenSilently, user, year, month, userClient]);

  useEffect(() => {
    const getNewRevolverStatsInfo = async (clientStatsId: string) => {
      const accessToken = await getAccessTokenSilently();
      const { data: newStats } = await getMonthlyNewRevolverStats(accessToken, clientStatsId);
      if (newStats) {
        setNewRevolverStats(newStats as MonthlyNewRevolverStats);
      }
    };
    if (clientMonthlyStats !== undefined && newRevolverStats === undefined) {
      getNewRevolverStatsInfo(clientMonthlyStats.id);
    }
  }, [getAccessTokenSilently, clientMonthlyStats, newRevolverStats]);

  useEffect(() => {
    const buildTabs = () => {
      const revolverTabData: TabData[] = [];
      const transactorTabData: TabData[] = [];
      // Build subtabs and parent tab for the overview tab if recent revolver count is > 0
      const overviewSubtabs = [];
      if (
        recentCount &&
        clientMonthlyStats !== undefined &&
        recentCount.recentHeavyRevolverCount > 0 &&
        recentCount.recentLightRevolverCount > 0
      ) {
        overviewSubtabs.push({
          name: 'Heavy Revolvers',
          active: false,
          content: (
            <HeavyRevolversTab
              recentCount={recentCount.recentHeavyRevolverCount}
              clientMonthlyStats={clientMonthlyStats}
              institutionType={institutionType}
            />
          ),
        });
        overviewSubtabs.push({
          name: 'Light Revolvers',
          active: false,
          content: (
            <LightRevolversTab
              recentCount={recentCount.recentLightRevolverCount}
              clientMonthlyStats={clientMonthlyStats}
              institutionType={institutionType}
            />
          ),
        });
      }
      if (clientMonthlyStats !== undefined && revolvingAccounts !== undefined) {
        revolverTabData.push({
          name: 'Account Stats',
          active: false,
          content: (
            <AccountStatsTab
              clientMonthlyStats={clientMonthlyStats}
              revolvingAccounts={revolvingAccounts}
            />
          ),
          subtabs: [],
        });
      }
      if (clientMonthlyStats !== undefined) {
        revolverTabData.push({
          name: 'Overview',
          active: true,
          content: (
            <RevolverOverviewTab
              totalNumberOfMembers={totalNumberOfMembers}
              clientMonthlyStats={clientMonthlyStats}
              percentageOfLightRevolvers={percentageOfLightRevolvers}
              percentageOfHeavyRevolvers={percentageOfHeavyRevolvers}
            />
          ),
          subtabs: overviewSubtabs,
        });
      }
      if (newRevolverStats !== undefined && areAllNumberFieldsZero(newRevolverStats) === false) {
        revolverTabData.push({
          name: 'New Revolver Stats',
          active: false,
          content: <NewRevolversTab newRevolverStats={newRevolverStats} />,
          subtabs: [],
        });
      }
      if (
        transactorTopBankPayments !== undefined &&
        clientMonthlyStats !== undefined &&
        transactorTopBankPayments.length > 0
      ) {
        transactorTabData.push({
          name: 'Amounts Paid',
          active: false,
          content: (
            <AmountsPaidTab
              transactorTopBankPayments={transactorTopBankPayments}
              clientMonthlyStats={clientMonthlyStats}
            />
          ),
          subtabs: [],
        });
      }
      if (
        transactorAccountsCount !== undefined &&
        clientMonthlyStats !== undefined &&
        transactorAccountsCount.length > 0
      ) {
        transactorTabData.push({
          name: 'Overview',
          active: true,
          content: (
            <TransactorOverviewTab
              numTransactingAccountsData={transactorAccountsCount}
              clientMonthlyStats={clientMonthlyStats}
            />
          ),
          subtabs: [],
        });
      }
      if (revolverTopBankPayments !== undefined && clientMonthlyStats !== undefined) {
        revolverTabData.push({
          name: 'Provider Insights',
          active: false,
          content: (
            <ProviderInsightsTab
              revolverTopBankPayments={revolverTopBankPayments}
              clientMonthlyStats={clientMonthlyStats}
            />
          ),
          subtabs: [],
        });
      }
      revolverTabData.sort(customRevolverSort);
      if (
        (revolverTabs === null && revolverTabData.length > 0) ||
        (revolverTabs !== null && compareTabs(revolverTabs, revolverTabData) === false)
      ) {
        setRevolverTabs(revolverTabData);
      }
      transactorTabData.sort(customTransactorSort);
      if (
        (transactorTabs === null && transactorTabData.length > 0) ||
        (transactorTabs !== null && compareTabs(transactorTabs, transactorTabData) === false)
      ) {
        setTransactorTabs(transactorTabData);
      }
    };
    buildTabs();
  }, [
    newRevolverStats,
    clientMonthlyStats,
    institutionType,
    percentageOfHeavyRevolvers,
    percentageOfLightRevolvers,
    recentCount,
    totalNumberOfMembers,
    revolverTabs,
    transactorTopBankPayments,
    transactorTabs,
    transactorAccountsCount,
    revolvingAccounts,
    customRevolverSort,
    customTransactorSort,
    revolverTopBankPayments,
  ]);

  const changeActiveRevolverTab = (tabName: string) => {
    const newTabs =
      revolverTabs &&
      revolverTabs.map((tab) => {
        if (tab.name === tabName) {
          tab.active = true;
        } else {
          tab.active = false;
        }
        tab.subtabs?.map((subtab) => {
          if (subtab.name === tabName) {
            subtab.active = true;
          } else {
            subtab.active = false;
          }
          return subtab;
        });
        return tab;
      });
    newTabs && newTabs.sort(customRevolverSort);
    setRevolverTabs(newTabs);
  };

  const changeActiveTransactorTab = (tabName: string) => {
    const newTabs =
      transactorTabs &&
      transactorTabs.map((tab) => {
        if (tab.name === tabName) {
          tab.active = true;
        } else {
          tab.active = false;
        }
        tab.subtabs?.map((subtab) => {
          if (subtab.name === tabName) {
            subtab.active = true;
          } else {
            subtab.active = false;
          }
          return subtab;
        });
        return tab;
      });
    newTabs && newTabs.sort(customTransactorSort);
    setTransactorTabs(newTabs);
  };

  if (clientMonthlyStats) {
    const { heavyRevolvers, lightRevolvers, transactors, cantCategorize, nonCardPayers } =
      clientMonthlyStats;

    totalNumberOfMembers =
      heavyRevolvers + lightRevolvers + transactors + cantCategorize + nonCardPayers;
    const thirdPartyPayers = totalNumberOfMembers - nonCardPayers;

    const totalRevolvers = heavyRevolvers + lightRevolvers;

    percentageOfHeavyRevolvers = ((heavyRevolvers / totalRevolvers) * 100).toFixed(0);
    percentageOfLightRevolvers = ((lightRevolvers / totalRevolvers) * 100).toFixed(0);

    // competitor card payer breakdown - note these are based on card payers - not total members
    const cardPayerRevolvers = Math.round((totalRevolvers / thirdPartyPayers) * 100);
    const cardPayerTransactors = Math.round((transactors / thirdPartyPayers) * 100);

    percentageCardPayerRevolvers = cardPayerRevolvers.toFixed(0);
    percentageOfTransactors = cardPayerTransactors.toFixed(0);
    percentageNotIdentified = (100 - cardPayerRevolvers - cardPayerTransactors).toFixed(0);

    const totalNumberOfLikelyRevolvers = heavyRevolvers + lightRevolvers;

    refinanceOpportunity = totalNumberOfLikelyRevolvers * 6000;

    if (month && year) {
      highlightsText = `The following analysis reflects insights quantified from provided data for 
        ${getFullMonthNameFromString(month)} ${year}.`;
    }
    highlightsSubheader = `${thirdPartyPayers.toLocaleString()} of your ${getUserType(
      institutionType,
      false,
      'plural',
    )} pay competitor credit cards.
      This is how our methodology categorizes their competitor card usage between Likely Revolvers and Likely Transcators.`;

    boxes = [
      {
        title: totalNumberOfMembers.toLocaleString(),
        text: ` ${getUserType(institutionType, true, 'plural')} with checking accounts`,
        icon: union,
        fontSize: '24px',
      },
      {
        title: thirdPartyPayers.toLocaleString(),
        text: ` ${getUserType(institutionType, true, 'plural')} paid competitor cards`,
        icon: creditCardIcon,
        fontSize: '24px',
      },
      {
        title: totalNumberOfLikelyRevolvers.toLocaleString(),
        text: `Of competitor card payers are Likely Revolvers`,
        icon: rotatingArrows,
        fontSize: '24px',
      },
      {
        title: `$${abbreviateNumber(refinanceOpportunity)}`,
        text: 'Potential refi opportunity for Likely Revolvers',
        icon: dollarSign,
        fontSize: '24px',
      },
    ];

    roundboxes = [
      {
        numberOfPayers: totalNumberOfLikelyRevolvers,
        totalMembers: thirdPartyPayers,
        zIndex: 3,
        color: baseColors.primary.primary100,
      },
      {
        numberOfPayers: transactors,
        totalMembers: thirdPartyPayers,
        zIndex: 2,
        color: baseColors.accent.accentGreen100,
      },
      {
        numberOfPayers: cantCategorize,
        totalMembers: thirdPartyPayers,
        zIndex: 1,
        color: baseColors.text.textBlue40,
      },
    ];

    if (topCardProviderPayments) {
      const sortedTopCardProviders = topCardProviderPayments.sort((a, b) =>
        a.paymentsToAmount < b.paymentsToAmount ? 1 : -1,
      );
      barData = {
        labels: sortedTopCardProviders.map(({ bankName }) => {
          if (bankNameMapping[bankName] !== undefined) {
            return bankNameMapping[bankName];
          } else {
            rollbar.error('Bank name not found in bankNameMapping', { bankName });
            return bankName;
          }
        }),
        datasets: [
          {
            label: 'Dollars',
            data: sortedTopCardProviders.map(({ paymentsToAmount }) => paymentsToAmount),
            backgroundColor: DesignSystemColorsTheme.primary.primary20,
            borderRadius: { topLeft: 8, topRight: 8 },
            barThickness: 40,
            hoverBackgroundColor: DesignSystemColorsTheme.primary.primary100,
          },
        ],
      };
    }

    if (revolvingAccounts) {
      likelyRevolverData = revolvingAccounts;
      likelyRevolverData.revolvingAccountsCountsPayments = sanitizeLikelyRevolverData(
        revolvingAccounts.revolvingAccountsCountsPayments,
      );
    }
    breakdownBoxes = [
      {
        title: 'Likely Revolvers',
        text: `${percentageCardPayerRevolvers}% of competitor card payers `,
        icon: blueDot,
        background: baseColors.primary.primary5,
        borderColor: baseColors.primary.primary100,
        marginTop: '16px',
        // Commented out hyperlink for now as we dont have
        // a link to the campaign yet
        // hyperlink: (
        //   <StyledTertiary
        //     size="large"
        //     background="transparent"
        //     disabled={false}
        //     label="Baseline Revolver Campaign"
        //     rightIcon="/feather-sprite.svg#arrow-right"
        //     onClick={() => {
        //       window.open('https://www.google.com', '_blank');
        //     }}
        //   />
        // ),
        tooltip: (
          <Tooltip
            header=""
            side="bottom"
            alignment="center"
            size="large"
            content={
              <BodyText size="medium">
                Competitor card payers who leave a balance on one or more cards from month to month.
              </BodyText>
            }
          />
        ),
      },
      {
        title: 'Likely Transactors',
        text: `${percentageOfTransactors}% of competitor card payers`,
        icon: greenDot,
        background: baseColors.accent.accentGreen5,
        borderColor: baseColors.accent.accentGreen100,
        marginTop: '16px',
        // Commented out hyperlink for now as we dont have
        // a link to the campaign yet
        // hyperlink: (
        //   <StyledTertiary
        //     size="large"
        //     background="transparent"
        //     disabled={false}
        //     label="Baseline Transactor Campaign"
        //     rightIcon="/feather-sprite.svg#arrow-right"
        //     onClick={() => {
        //       window.open('https://www.google.com', '_blank');
        //     }}
        //   />
        // ),
        tooltip: (
          <Tooltip
            header=""
            side="bottom"
            alignment="center"
            size="large"
            content={
              <BodyText size="medium">
                Competitor card payers who are likely to pay their balance in full all of the time,
                as identified by the analysis methodology.
              </BodyText>
            }
          />
        ),
      },
      {
        title: "Can't Categorize",
        text: `${percentageNotIdentified}% of competitor card payers`,
        icon: greyDot,
        background: baseColors.text.textBlue5,
        borderColor: baseColors.text.textBlue40,
        marginTop: '16px',
        tooltip: (
          <Tooltip
            header=""
            side="bottom"
            alignment="center"
            size="large"
            content={
              <BodyText size="medium">
                Competitor card payers with atypical or infrequent credit card payment behavior that
                can not be categorized by the analysis methodology.
              </BodyText>
            }
          />
        ),
      },
    ];
  }

  return (
    <PageLayout>
      {!clientMonthlyStats && <PageLoader />}
      {clientMonthlyStats && (
        <>
          <BackButtonContainer>
            <BackButton />
          </BackButtonContainer>
          <TitleButtonContainer>
            {month && year && (
              <>
                <StyledTitle>
                  Checking Account Analysis: {getFullMonthNameFromString(month)} {year}
                </StyledTitle>
                <PrimaryMultiSelectDropdown
                  disabled={false}
                  labelIcon={'/feather-sprite.svg#help-circle'}
                  options={availableOptions}
                  placeholder={'Explore Export Options'}
                  value={exportFileValues}
                  onChange={setExportFileValues}
                  button={
                    <PrimaryButton
                      size="small"
                      label="Export"
                      onClick={() => handleDownload(year, month)}
                    />
                  }
                />
              </>
            )}
          </TitleButtonContainer>
          <StyledCard>
            <HighlightsHeader size="medium">Highlights</HighlightsHeader>
            <HighlightsSubheader size="large">{highlightsText}</HighlightsSubheader>
            <TextBoxRow boxes={boxes ?? []} />
            <SectionDivider />
            <ThirdPartyTitle size="medium">Competitor card payer breakdown</ThirdPartyTitle>
            <ThirdPartySubtitle size="large">{highlightsSubheader}</ThirdPartySubtitle>
            <RoundBoxRow roundboxes={roundboxes ?? []} />
            <TextBoxRow boxes={breakdownBoxes ?? []} />
            <SectionDivider />
            <BarChartHeader>
              <HeaderText size="medium">Payments to top card providers</HeaderText>
              <BodyText size="large">
                Total payment volume to the top card providers, across all{' '}
                {getUserType(institutionType, false, 'plural')} this month.
              </BodyText>
            </BarChartHeader>
            <BodyText size="small">Total payments</BodyText>
            <BarChartContainer>
              <BarGraph data={barData} />
            </BarChartContainer>
          </StyledCard>
          {revolverTabs && (
            <TabsNavigation
              title={`Revolver refinance opportunity: ${abbreviateToInt(refinanceOpportunity)}`}
              parentTabs={revolverTabs}
              changeActiveTab={changeActiveRevolverTab}
              activeColor={baseColors.primary.primary100}
            />
          )}
          {transactorTabs && (
            <TransactorTabsContainer>
              <TabsNavigation
                title={`Transactor card shift opportunity: ${abbreviateToInt(
                  refinanceOpportunity,
                )}`}
                parentTabs={transactorTabs}
                changeActiveTab={changeActiveTransactorTab}
                titleColor={baseColors.accent.accentGreen20}
                containerColor={baseColors.accent.accentGreen5}
                tabColor={baseColors.accent.accentGreen20}
              />
            </TransactorTabsContainer>
          )}
        </>
      )}
    </PageLayout>
  );
};

export default ViewMonthlyAnalysisPage;
