import { useEffect, useState } from 'react';
import styled from 'styled-components';
import fileIcon from '../../images/design system/file-icon.svg';
import { useAuth0 } from '@auth0/auth0-react';
import { getPresignedThumbnailUrl } from '../../services/marketing-resources.service';
import { MarketingResource } from '../../models/MarketingResource';
import { ViewDownload } from './ViewDownload';
import defaultThumbnail from '../../images/cover-page-1.png';
import Card from '../../components/outlines/Card';
import { BodyText } from '../../components/typography/BodyText';
import { HeaderText } from '../../components/typography/HeaderText';

const ResourceBox = styled(Card)`
  box-shadow: 0px 8px 10px rgba(65, 61, 231, 0.03), 0px 2px 5px rgba(85, 85, 88, 0.02);
  border-radius: 16px;

  border: ${(props) => `1px solid ${props.theme.primary.primary20}`};
`;

const ResourceHeader = styled.div`
  display: flex;
  padding-bottom: 8px;
  gap: 8px;
`;

const ResourceContentContainer = styled.div`
  padding-top: 16px;
  padding-left: 16px;
  height: 72px;
`;

const ViewDownloadContainer = styled.div`
  padding-left: 16px;
`;

const ResourceTitleAndInfoSection = styled.div`
  padding-bottom: 8px;
`;

interface MarketingResourceProps {
  resource: MarketingResource;
}

export default function MarketingResourceCard({ resource }: MarketingResourceProps): JSX.Element {
  const { getAccessTokenSilently } = useAuth0();
  const [thumbnailUrl, setThumbnailUrl] = useState<string>();
  const [token, setAccessToken] = useState<string>();

  useEffect(() => {
    const getThumbnail = async () => {
      const accessToken = await getAccessTokenSilently();
      if (accessToken) {
        setAccessToken(accessToken);
      }
      const { data: thumbnailPresignedUrl } = await getPresignedThumbnailUrl(
        accessToken,
        resource.id,
      );
      if (thumbnailPresignedUrl) {
        setThumbnailUrl(thumbnailPresignedUrl);
      } else {
        setThumbnailUrl(defaultThumbnail);
      }
    };
    getThumbnail();
  }, [getAccessTokenSilently, resource.id]);

  return (
    <ResourceBox width="350px" height="310px">
      <ResourceTitleAndInfoSection>
        <img src={thumbnailUrl} alt="marketing playbook thumbnail" height="auto" width="100%" />
        <ResourceContentContainer>
          <ResourceHeader>
            <img src={fileIcon} alt="file icon" />
            <HeaderText size="small"> {resource.name}</HeaderText>
          </ResourceHeader>
          <div>
            <BodyText size="medium">{resource.description}</BodyText>
          </div>
        </ResourceContentContainer>
      </ResourceTitleAndInfoSection>

      <ViewDownloadContainer>
        <ViewDownload accessToken={token} resourceId={resource?.id} resourceName={resource?.name} />
      </ViewDownloadContainer>
    </ResourceBox>
  );
}
