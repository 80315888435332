import styled from 'styled-components';
import adminLogo from '../../images/admingConsoleLogo.svg';
import acLogo from '../../images/design system/ac-logo.svg';
import { UploadIcon } from '../../images/uploadIcon';
import { marketingResourcesRoute } from '../../routes';
import React, { useEffect, useState } from 'react';
import { LogoutIcon } from '../../images/logoutIcon';
import { AnalysisIcon } from '../../images/design system/analysisIcon';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { marketingResourceIcon } from '../../images/design system/marketingResourceIcon';
import { useLocation } from 'react-router-dom';
import MenuToggle from '../icons/MenuToggle';
import { ButtonText } from '../typography/ButtonText';
import { DesignSystemColorsTheme } from '../themeing/themes';
import { useClientStore } from '../../stores/clientStore';

const collapsedWidth = '4rem';
const expandedWidth = '17rem';
const logOutbutton = 'Log out';

const logoContext = require.context('../../images/logos', false, /\.svg$/);

/**
 * The client's specific logo is displayed dynamically in the navigation menu component of this file.
 * The 'clientLogoSrc' state is used to manage the source of the logo image. This logo is only shown when
 * the navigation menu is in an expanded state.
 *
 * The logo is loaded using the 'getLogo' function, which uses webpack's 'require.context' to create a context
 * of all '.svg' files in the '/images/logos' directory. The function takes the 'clientCode' property from the
 * userClient object, attempts to find a matching '.svg' file in the context, and returns the logo.
 * The 'clientCode' must match the file name of the client's logo. If a logo with the given 'clientCode'
 * does not exist, a warning is logged to the console, and the function returns undefined, so no logo is displayed.
 */
export function getLogo(clientCode: string | undefined) {
  try {
    return logoContext(`./${clientCode?.toLowerCase()}.svg`);
  } catch (e) {
    return null;
  }
}

const MenuItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const StyledMenu = styled.div<{ menuCollapsed: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 40px 24px 40px 0px;

  position: fixed;
  width: ${({ menuCollapsed }) => (menuCollapsed ? collapsedWidth : expandedWidth)};
  height: 100vh;
  left: 0;
  top: 0;

  background: #ffffff;
  border-right: ${() => `1px solid ${DesignSystemColorsTheme.primary.primary20}`};
  transition: 0.5s;
`;

const StyledMenuItem = styled.div<{ active: boolean; menuCollapsed: boolean }>`
  display: flex;
  align-items: center;
  box-sizing: border-box;

  background: ${({ active }) =>
    active ? DesignSystemColorsTheme.primary.primary10 : 'transparent'};
  border-radius: ${({ active }) => (active ? '0px 12px 12px 0px' : '0')};
  width: ${({ menuCollapsed }) => (menuCollapsed ? '71px' : '277px')};

  &:hover {
    flex-direction: row;
    align-items: center;
    background: ${({ active }) => (active ? DesignSystemColorsTheme.primary.primary20 : 'white')};
  }

  padding: 12px 20px 12px 20px;
  gap: 8px;

  transition: width 0.3s ease-in-out;
  overflow: hidden;
  white-space: nowrap;
`;

const IconContainer = styled.div<{ menuCollapsed: boolean }>`
  display: flex;
  justify-content: center;
  width: ${({ menuCollapsed }) => (menuCollapsed ? '100%' : 'auto')};
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 24px;
  padding-top: 16px;
  padding-bottom: 35px;

  img {
    height: 21px;
  }
`;

const StyledLogo = styled.img`
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1rem;
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;
`;

const StyledToggle = styled.div<{ menuCollapsed: boolean }>`
  display: flex;
  justify-content: ${({ menuCollapsed }) => (menuCollapsed ? 'flex-end' : 'flex-start')};
  position: absolute;
  top: 20px;
  right: ${({ menuCollapsed }) => (menuCollapsed ? '-26px' : '-26px')};
  align-items: center;
  background: transparent;
  transition: right 0.5s;
  cursor: pointer;
`;

const ClientLogoContainer = styled.div`
  margin-bottom: 40px;
`;

const StyledFooterLogo = styled.img`
  width: 140px;
  height: 48px;
  padding-top: 130%;
  padding-left: 40px;
  padding-bottom: 40px;
  transition: opacity 0.3s ease-in-out;
  opacity: var(--logoOpacity, 1);
`;

interface MenuItemProps {
  link: string;
  name: string;
  icon: React.ComponentType<{ active: boolean; hovered: boolean }>;
  menuCollapsed: boolean;
}

type NavBarTabProps = {
  children?: React.ReactNode;
  path: string;
};

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
`;
function NavBarTab({ path, children }: NavBarTabProps) {
  return <StyledNavLink to={path}>{children}</StyledNavLink>;
}

function MenuItem({ icon: Icon, link, name, menuCollapsed }: MenuItemProps): JSX.Element {
  const { logout } = useAuth0();
  const location = useLocation();
  const [isHovered, setIsHovered] = useState(false);

  const handleLogout = () => {
    logout({
      returnTo: window.location.origin,
    });
  };

  const isActive = location.pathname === link;

  const determineColor = () => {
    if (isActive && isHovered) {
      return DesignSystemColorsTheme.primary.primaryHover;
    } else if (isActive && !isHovered) {
      return DesignSystemColorsTheme.primary.primary100;
    } else if (!isActive && isHovered) {
      return DesignSystemColorsTheme.text.textBlue100;
    } else {
      return DesignSystemColorsTheme.text.textBlue80;
    }
  };

  if (name === logOutbutton) {
    return (
      <NavBarTab path={link}>
        <StyledMenuItem
          active={isActive}
          menuCollapsed={menuCollapsed}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={() => handleLogout()}
          className="menu-link"
        >
          <IconContainer menuCollapsed={menuCollapsed}>
            <Icon active={isActive} hovered={isHovered} />
          </IconContainer>
          {!menuCollapsed && (
            <ButtonText size="medium" color={determineColor()}>
              {name}
            </ButtonText>
          )}
        </StyledMenuItem>
      </NavBarTab>
    );
  }
  return (
    <NavBarTab path={link}>
      <StyledMenuItem
        active={isActive}
        menuCollapsed={menuCollapsed}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className="menu-link"
      >
        <IconContainer menuCollapsed={menuCollapsed}>
          <Icon active={isActive} hovered={isHovered} />
        </IconContainer>
        {!menuCollapsed && (
          <ButtonText size="medium" color={determineColor()}>
            {name}
          </ButtonText>
        )}
      </StyledMenuItem>
    </NavBarTab>
  );
}

type MenuProps = {
  menuCollapsed: boolean;
  setMenuCollapsed: (collapsed: boolean) => void;
};

export const Menu: React.FC<MenuProps> = ({ menuCollapsed, setMenuCollapsed }) => {
  const userClient = useClientStore((state) => state.client);
  const navigate = useNavigate();

  const [logoSrc, setLogoSrc] = useState(menuCollapsed ? acLogo : adminLogo);
  const [clientLogoSrc, setClientLogoSrc] = useState();
  const [logoOpacity, setLogoOpacity] = useState(1);

  useEffect(() => {
    setClientLogoSrc(getLogo(userClient?.clientCode));
  }, [userClient?.clientCode]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLogoSrc(menuCollapsed ? acLogo : adminLogo);
    }, 500);

    return () => clearTimeout(timer);
  }, [menuCollapsed]);

  return (
    <StyledMenu menuCollapsed={menuCollapsed}>
      <MenuItemsContainer>
        <StyledToggle menuCollapsed={menuCollapsed}>
          <MenuToggle
            menuCollapsed={menuCollapsed}
            setMenuCollapsed={() => {
              setMenuCollapsed(!menuCollapsed);
              setLogoOpacity(0);
              setTimeout(() => {
                setLogoOpacity(1);
              }, 500);
            }}
          />
        </StyledToggle>
        <LogoContainer>
          <StyledLogo
            style={{ opacity: logoOpacity }}
            src={logoSrc}
            onClick={() => navigate('/analysis/home')}
          />
        </LogoContainer>
        <MenuItem
          name="Analysis home"
          link={'/analysis/home'}
          icon={AnalysisIcon}
          menuCollapsed={menuCollapsed}
        />
        <MenuItem
          name="Marketing resources"
          link={marketingResourcesRoute}
          icon={marketingResourceIcon}
          menuCollapsed={menuCollapsed}
        />
        <MenuItem
          name="Data upload"
          link={'/data-upload?showHistory=true'}
          icon={UploadIcon}
          menuCollapsed={menuCollapsed}
        />
        <MenuItem
          name={logOutbutton}
          link={'nothome'}
          icon={LogoutIcon}
          menuCollapsed={menuCollapsed}
        />
      </MenuItemsContainer>
      {!menuCollapsed && clientLogoSrc && (
        <ClientLogoContainer>
          <StyledFooterLogo
            style={{ opacity: logoOpacity }}
            src={clientLogoSrc}
            alt="Client Logo"
          />
        </ClientLogoContainer>
      )}
    </StyledMenu>
  );
};
