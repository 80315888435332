import { baseColors } from '../../components/themeing/themes';
import styled from 'styled-components';
import { HeaderText } from '../../components/typography/HeaderText';
import { BodyText } from '../../components/typography/BodyText';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import SankeyChart from '../../components/charts/SankeyChart';
import { useNavigate } from 'react-router-dom';
import { AnyObject } from 'chart.js/dist/types/basic';
import { MonthlyNewRevolverStats } from '../../models/MonthlyNewRevolverStats';
import { getUserType } from '../../utils/account-holder-translation';
import { useClientStore } from '../../stores/clientStore';

interface NewRevolverTabProps {
  newRevolverStats: MonthlyNewRevolverStats;
}

const OverviewContent = styled.div`
  align-items: center;
  margin: 40px;
`;

const OverviewContentHeader = styled(HeaderText)`
  margin-bottom: 16px;
`;

const OverviewContentSubtext = styled(BodyText)`
  margin-bottom: 24px;
`;

const OverviewContentHeaderBottom = styled(HeaderText)`
  margin-top: 24px;
  margin-bottom: 16px;
`;

const OverviewContentSubtextBottom = styled(BodyText)`
  margin-bottom: 16px;
`;

const OverviewContentButtonBottom = styled(PrimaryButton)`
  margin-bottom: 40px;
`;

const LowerCardContainer = styled.div`
  display: flex;
  align-items: center;
`;

const OverviewGraphContainer = styled.div`
  width: 100%;
  height: 280px;
  canvas {
    margin: auto;
    width: 400px;
  }
`;

const LabelContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 17px;
`;

const ThisMonthLabel = styled(HeaderText)``;

const NewRevolverTab = ({ newRevolverStats }: NewRevolverTabProps) => {
  const allRevolvers = newRevolverStats.prevCantCategorize + newRevolverStats.prevTransactors;

  const navigate = useNavigate();
  const userClient = useClientStore((state) => state.client);
  const institutionType = userClient?.institutionType;

  const colors = {
    [`Likely\nTransactors\n${newRevolverStats.prevTransactors.toLocaleString()}`]: '#8DD0C9',
    [`Can't\nCategorize\n${newRevolverStats.prevCantCategorize.toLocaleString()}`]: '#CDD0DB',
  };

  const sankeyData = {
    datasets: [
      {
        data: [
          {
            source: `Likely\nTransactors\n${newRevolverStats.prevTransactors.toLocaleString()}`,
            destination: `New Likely\nRevolvers\n${allRevolvers.toLocaleString()}`,
            value: newRevolverStats.prevTransactors,
          },
          {
            source: `Can't\nCategorize\n${newRevolverStats.prevCantCategorize.toLocaleString()}`,
            destination: `New Likely\nRevolvers\n${allRevolvers.toLocaleString()}`,
            value: newRevolverStats.prevCantCategorize,
          },
        ],
        nodeWidth: 10,
        font: {
          size: 12,
          weight: 'bold',
          family: 'Sora',
        },
        colorFrom: (c: AnyObject) => {
          return colors[c.raw.source];
        },
        colorTo: () => {
          return '#A1ABFF';
        },
        borderWidth: 0,
        colorMode: 'gradient',
        priority: {
          a: 1,
          b: 0,
        },
      },
    ],
  };

  return (
    <OverviewContent>
      <OverviewContentHeader color={baseColors.text.textBlue80} size="medium">
        New Revolvers
      </OverviewContentHeader>
      <OverviewContentSubtext size="large">
        Some of your {getUserType(institutionType, false, 'plural')} are categorized as Likely
        Revolvers for the first time in at least 12 months. Last month, they were either Likely
        Transactors, or Can't Categorize.
      </OverviewContentSubtext>
      <LowerCardContainer>
        <OverviewGraphContainer>
          <LabelContainer>
            <HeaderText color={baseColors.text.textBlue40} size="tiny">
              Previous Month
            </HeaderText>
            <ThisMonthLabel color={baseColors.text.textBlue40} size="tiny">
              This Month
            </ThisMonthLabel>
          </LabelContainer>
          <SankeyChart data={sankeyData} />
        </OverviewGraphContainer>
      </LowerCardContainer>
      <OverviewContentHeaderBottom size="medium">Take Action</OverviewContentHeaderBottom>
      <OverviewContentSubtextBottom size="large">
        Send our Refinance Email for New Revolvers to help them get on track.
      </OverviewContentSubtextBottom>
      <OverviewContentButtonBottom
        label="View New Revolver Refinance Campaign"
        size="large"
        onClick={() => navigate('/marketing-resources')}
      ></OverviewContentButtonBottom>
    </OverviewContent>
  );
};

export default NewRevolverTab;
