import * as React from 'react';
import * as RadixToast from '@radix-ui/react-toast';
import styled, { keyframes } from 'styled-components';
import { HeaderText } from '../typography/HeaderText';
import { BodyText } from '../typography/BodyText';
import { DesignSystemColorsTheme } from '../themeing/themes';
import checkIcon from './../../images/design system/check.svg';
import crossIcon from './../../images/design system/cross.svg';
import attentionIcon from './../../images/design system/attention.svg';
import infoIcon from './../../images/design system/info.svg';
import arrowRightIcon from './../../images/design system/arrow-right.svg';
import CrossIcon from '../icons/CrossIcon';

export type ToastVariant = 'success' | 'error' | 'attention' | 'info' | 'neutral';

const variantStyles: Record<ToastVariant, string> = {
  success: `
      background: ${DesignSystemColorsTheme.system.success5};
      border: 1px solid ${DesignSystemColorsTheme.system.success100};
    `,
  error: `
      background: ${DesignSystemColorsTheme.system.error5};
      border: 1px solid ${DesignSystemColorsTheme.system.error100};
    `,
  attention: `
      background: ${DesignSystemColorsTheme.system.warning5};
      border: 1px solid ${DesignSystemColorsTheme.system.warning100};
    `,
  info: `
      background: ${DesignSystemColorsTheme.system.info5};
      border: 1px solid ${DesignSystemColorsTheme.system.info100};
    `,
  neutral: `
      background: ${DesignSystemColorsTheme.text.textBlue5};
      border: 1px solid ${DesignSystemColorsTheme.text.textBlue100};
    `,
};

const variantIconColors: Record<ToastVariant, string> = {
  success: DesignSystemColorsTheme.system.success100,
  error: DesignSystemColorsTheme.system.error100,
  attention: DesignSystemColorsTheme.system.warning100,
  info: DesignSystemColorsTheme.system.info100,
  neutral: DesignSystemColorsTheme.text.textBlue100,
};

const variantIcons: Record<ToastVariant, string> = {
  success: checkIcon,
  error: crossIcon,
  attention: attentionIcon,
  info: infoIcon,
  neutral: arrowRightIcon,
};

const StyledViewport = styled(RadixToast.Viewport)`
  position: fixed;
  top: 15px;
  right: 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
  gap: 10px;
  height: 124px;
  margin: 0;
  list-style: none;
  z-index: 2147483647;
  outline: none;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
`;

const slideIn = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
`;

const slideOut = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(150%);
  }
`;

const ToastRoot = styled(RadixToast.Root)<{ variant: ToastVariant; width?: string; open: boolean }>`
  position: relative;
  padding: 24px 36px 24px 24px;
  gap: 16px;

  ${({ variant }) => variantStyles[variant]}

  box-shadow: 0px 12px 35px rgba(67, 162, 105, 0.05), 0px 6px 25px rgba(85, 85, 88, 0.03);
  border-radius: 16px;
  width: ${({ width }) => width || 'auto'};
  animation: ${({ open }) => (open ? slideIn : slideOut)}
    ${({ open }) => (open ? '400ms' : '600ms')} forwards;
`;

const StyledContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

const StyledIcon = styled.div<{ variant: ToastVariant }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background: ${({ variant }) => variantIconColors[variant]};
  border-radius: 100px;
  flex: none;
`;

const StyledTitle = styled(RadixToast.Title)`
  margin-left: 12px;
  margin-bottom: 8px;
`;

const StyledDescription = styled(RadixToast.Description)`
  margin-left: 12px;
  padding-right: 36px;
`;

export interface ToastProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  title: string;
  description: string;
  variant: ToastVariant;
  width?: string;
  duration?: number;
}

/**
 * `Toast` component: A customizable and animated toast message UI component.
 *
 * Props:
 * - `open`: A boolean indicating if the toast is currently visible.
 * - `onOpenChange`: A callback function that toggles the visibility of the toast.
 * - `title`: The title of the toast message.
 * - `description`: The description or main content of the toast.
 * - `variant`: Determines the type and styling of the toast. Can be 'success', 'error', 'attention', 'info', or 'neutral'.
 * - `width`: Optional. Sets a custom width for the toast. Default is 'auto'.
 * - `duration`: Optional. Sets a custom duration for the toast to stay open. Default is 5000ms (5 seconds).
 *
 * The toast features slide in and out animations and it will linger for the specified `duration` unless manually closed.
 * The closing animation is slower to provide a smooth transition. The toast uses theme colors for different `variant`s.
 *
 * This component uses the `@radix-ui/react-toast` library for base functionality and styled-components for styling and animations.
 *
 * Example usage:
 * ```jsx
 * <Toast
 *    open={true}
 *    onOpenChange={() => {}}
 *    title="Success"
 *    description="This is a success message."
 *    variant="success"
 *    width="300px"
 *    duration={4000}
 * />
 * ```
 */

const Toast: React.FC<ToastProps> = ({
  open,
  onOpenChange,
  title,
  description,
  variant,
  width,
  duration,
}) => {
  return (
    <>
      <ToastRoot
        open={open}
        onOpenChange={onOpenChange}
        duration={duration || 5000}
        variant={variant}
        width={width}
      >
        <CloseButton onClick={() => onOpenChange(false)}>
          <CrossIcon width={'16'} height={'16'} color={DesignSystemColorsTheme.text.textBlue100} />
        </CloseButton>
        <StyledContainer>
          <StyledIcon variant={variant}>
            <img src={variantIcons[variant]} width={16} height={16} />
          </StyledIcon>

          <div>
            <StyledTitle>
              <HeaderText size="small">{title}</HeaderText>
            </StyledTitle>
            <StyledDescription asChild>
              <BodyText size="large">{description}</BodyText>
            </StyledDescription>
          </div>
        </StyledContainer>
      </ToastRoot>
      <StyledViewport />
    </>
  );
};

export default Toast;
