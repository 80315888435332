import styled from 'styled-components';
import { DesignSystemColorsTheme } from '../components/themeing/themes';

interface PathProps {
  active: boolean;
  hovered: boolean;
}

const StyledPath = styled.path<{ active: boolean; hovered: boolean }>`
  fill: ${({ active, hovered }) => {
    if (active && hovered) {
      return DesignSystemColorsTheme.primary.primaryHover;
    } else if (active && !hovered) {
      return DesignSystemColorsTheme.primary.primary100;
    } else if (!active && hovered) {
      return DesignSystemColorsTheme.text.textBlue100;
    } else {
      return DesignSystemColorsTheme.text.textBlue80;
    }
  }};
`;

export const UploadIcon = ({ active, hovered }: PathProps) => (
  <>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <StyledPath
        hovered={hovered}
        active={active}
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.293 2.29289C11.6836 1.90237 12.3167 1.90237 12.7073 2.29289L18.4144 8L17.0002 9.41421L13 5.41406V16H11V5.41436L7.00015 9.41421L5.58594 8L11.293 2.29289ZM4 14V19C4 19.2652 4.10536 19.5196 4.29289 19.7071C4.48043 19.8946 4.73478 20 5 20H19C19.2652 20 19.5196 19.8946 19.7071 19.7071C19.8946 19.5196 20 19.2652 20 19V14H22V19C22 19.7957 21.6839 20.5587 21.1213 21.1213C20.5587 21.6839 19.7957 22 19 22H5C4.20435 22 3.44129 21.6839 2.87868 21.1213C2.31607 20.5587 2 19.7957 2 19V14H4Z"
        fill="#37446D"
      />
    </svg>
  </>
);
