import { ThemeProvider as StyledComponentThemeProvider } from 'styled-components';
import { baseColors, DesignSystemColorsTheme } from './themes';
import * as React from 'react';
import { Globals } from './GlobalStyles';

type Props = {
  children?: React.ReactNode;
};

export function ThemeProvider({ children }: Props) {
  return (
    <StyledComponentThemeProvider theme={baseColors}>
      <Globals />
      {children}
    </StyledComponentThemeProvider>
  );
}

export function DesignSystemThemeProvider({ children }: Props) {
  return (
    <StyledComponentThemeProvider theme={DesignSystemColorsTheme}>
      <Globals />
      {children}
    </StyledComponentThemeProvider>
  );
}
