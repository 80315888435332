import React from 'react';
import styled from 'styled-components';
import * as Dialog from '@radix-ui/react-dialog';
import { HeaderText } from './typography/HeaderText';
import x from '../images/x.svg';
import { DesignSystemColorsTheme } from './themeing/themes';

interface ModalProps {
  /**
   * Is the modal open or not?
   */
  open: boolean;
  /**
   * Inner content of the modal
   */
  content: React.ReactNode;
  /**
   * Header title of the modal
   */
  header: string;
  /**
   * Buttons to be displayed at the bottom of the modal. Optional array of React nodes
   */
  buttons?: React.ReactNode[];
  /**
   * Close handler function
   */
  closeHandler: (open: boolean) => void;
}

interface ContentProps extends Omit<Dialog.DialogContentProps, 'content'> {
  /**
   * Is the modal open or not?
   */
  open: boolean;
  /**
   * Buttons to be displayed at the bottom of the modal. Optional array of React nodes
   */
  buttons?: React.ReactNode[];
}

const Close = styled.div`
  all: unset;
  width: fit-content;
  margin-left: auto;
  margin-right: 0;
  cursor: pointer;
  padding: 16px 16px 8px 0;
  img {
    height: 16px;
    width: 16px;
  }
`;

const DialogButtons = styled.div`
  width: fill-available;
  hr {
    border-top: 1px solid #dbe2ff;
    margin: 0 0 24px 0;
  }
  .inner-button-container {
    flex-wrap: wrap;
    display: flex;
    padding: 0 40px 0 40px;
  }
  button {
    margin-right: 16px;
  }
`;

const InnerContent = styled.div`
  width: fill-available;
  padding: 0 40px 0 40px;
  margin: 0 0 16px 0;
`;

const Overlay = styled(Dialog.Overlay)`
  background: rgba(55, 68, 109, 0.3);
  position: fixed;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 1000; // Adjust this value as needed to ensure it's above the table
`;

const Content = styled(Dialog.Content)<ContentProps>`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Sora';
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gutter: 0px;
  padding: 0 0 40px 0;
  isolation: isolate;
  background-color: ${DesignSystemColorsTheme.white};
  border: 1px solid #dbe2ff;
  box-shadow: 0px 12px 35px rgba(65, 61, 231, 0.05), 0px 6px 25px rgba(85, 85, 88, 0.03);
  border-radius: 16px;
  width: ${(props) => (props.buttons ? '684px' : '508px')};
  z-index: 2000; // The z-index here should be higher than that of the Overlay
`;

/**
 * `Modal` is a reusable, styled dialog component.
 *
 * Props:
 * - `open`: Boolean that designates whether the Modal is open or not. Defaults to false.
 * - `content`: The actual content in the modal. Should be a ReactNode.
 * - `buttons`: Optional array of React Nodes that should be buttons.
 * - `header`: Title text for the header.
 *
 * A High Risk modal is considered to be one with at least Secondary level buttons and a decent amount of text.
 * A Low Risk modal is considered to be one with only Tertiary level buttons (if any at all) and a small amount of text.
 */
const Modal = ({ closeHandler, content, header, buttons, ...dialogContentProps }: ModalProps) => (
  <Dialog.Root open={dialogContentProps.open}>
    <Dialog.Portal>
      <Overlay />
      <Content
        onEscapeKeyDown={(e: Event) => (buttons ? e.preventDefault() : closeHandler(false))}
        onPointerDownOutside={(e: Event) => (buttons ? e.preventDefault() : closeHandler(false))}
        {...dialogContentProps}
      >
        <Close onClick={() => closeHandler(false)}>
          <img src={x} />
        </Close>
        <InnerContent>
          <HeaderText size="medium">{header}</HeaderText>
          {content}
        </InnerContent>
        {buttons && (
          <DialogButtons>
            <hr />
            <div className="inner-button-container">{buttons}</div>
          </DialogButtons>
        )}
      </Content>
    </Dialog.Portal>
  </Dialog.Root>
);

export default Modal;
