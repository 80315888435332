import * as CheckboxRadix from '@radix-ui/react-checkbox';
import { Check2 } from '@styled-icons/bootstrap/Check2';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

export interface CheckboxProps {
  label: string;
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
  disabled?: boolean;
}

interface BaseCheckboxProps extends CheckboxProps {
  keyboardFocused?: boolean;
}

const Check = styled(Check2)`
  width: 16px;
  height: 16px;
`;

const Indicator = styled(CheckboxRadix.Indicator)``;

const CheckboxRoot = styled(CheckboxRadix.Root)<BaseCheckboxProps>`
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  padding: 0;
  background: ${(props) => props.theme.white};
  border: 1px solid ${(props) => props.theme.primary.primary100};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.primary.primary100};

  &:focus {
    outline: ${(props) =>
      props.keyboardFocused ? `2px solid ${props.theme.system.info100}` : 'none'};
    outline-offset: 2px;
  }
`;

const CheckboxContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 12px;
  min-width: 76px;
  height: 24px;
  border-radius: 4px;

  label {
    color: ${(props) => props.theme.text.textBlue80};
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }

  svg {
    color: ${(props) => props.theme.white};
  }

  button:hover {
    border-color: ${(props) => props.theme.primary.primaryHover};
  }

  button:hover + label {
    color: ${(props) => props.theme.primary.primaryHover};
  }

  button:active {
    border-color: ${(props) => props.theme.primary.primaryActive};
    background-color: #ebefff;
  }

  button:active + label {
    color: ${(props) => props.theme.primary.primaryActive};
  }

  button:disabled {
    border: none;
    background-color: ${(props) => props.theme.text.textBlue10};
  }

  button:disabled + label {
    color: ${(props) => props.theme.text.textBlue40};
  }

  button[data-state='checked'] {
    color: ${(props) => props.theme.white};
    background: ${(props) => props.theme.primary.primary100};
  }

  button:hover[data-state='checked'] {
    border-color: ${(props) => props.theme.primary.primaryHover};
    background-color: ${(props) => props.theme.primary.primaryHover};
  }

  button:hover[data-state='checked'] + label {
    color: ${(props) => props.theme.primary.primaryHover};
  }

  button:active[data-state='checked'] {
    border-color: ${(props) => props.theme.primary.primaryActive};
    background-color: ${(props) => props.theme.primary.primaryActive};
  }

  button:active[data-state='checked'] + label {
    color: ${(props) => props.theme.primary.primaryActive};
  }

  button:disabled[data-state='checked'] {
    background-color: ${(props) => props.theme.text.textBlue10};
  }
`;

const Checkbox = ({
  disabled = false,
  checked,
  onCheckedChange,
  label,
  ...props
}: CheckboxProps) => {
  const [keyboardFocused, setKeyboardFocused] = useState(false);

  useEffect(() => {
    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key === 'Tab') {
        setKeyboardFocused(true);
      }
    };

    const handleMousedown = () => {
      setKeyboardFocused(false);
    };

    window.addEventListener('keydown', handleKeydown);
    window.addEventListener('mousedown', handleMousedown);

    return () => {
      window.removeEventListener('keydown', handleKeydown);
      window.removeEventListener('mousedown', handleMousedown);
    };
  }, []);

  return (
    <CheckboxContainer>
      <CheckboxRoot
        keyboardFocused={keyboardFocused}
        disabled={disabled}
        checked={checked}
        onCheckedChange={onCheckedChange}
        label={label}
        {...props}
        onClick={(e) => e.stopPropagation()}
      >
        <Indicator>
          <Check />
        </Indicator>
      </CheckboxRoot>
      <label>{label}</label>
    </CheckboxContainer>
  );
};

export default Checkbox;
