import { FC } from 'react';
import styled from 'styled-components';
import { getUserType } from '../../utils/account-holder-translation';
import { useClientStore } from '../../stores/clientStore';
import { Link } from 'react-router-dom';
import { TitleText } from '../typography/TitleText';
import { BodyText } from '../typography/BodyText';
import { baseColors } from '../themeing/themes';
import PrimaryButton from '../buttons/PrimaryButton';
import { IconBoxRow } from './IconBoxRow';

import Card from '../outlines/Card';
import handsTypingImage from '../../images/handsTyping.png';
import recentInsightsGradient from '../../images/recent-insights-gradient.png';

const RecentInsightsHeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 1rem;
  margin-left: 40px;
`;

const TextContainer = styled.div`
  max-width: 53%;
  padding-bottom: 24px;
`;

const StyledTitle = styled(TitleText)`
  padding-top: 42px;
  padding-bottom: 14px;
`;

const StyledCard = styled(Card)`
  position: relative;
  overflow: hidden;
  background-image: url(${recentInsightsGradient}), url(${handsTypingImage});
  background-position: right;
  background-repeat: no-repeat;
  background-color: ${({ theme }) => theme.primary.primary20};
`;

const NoStyleLink = styled(Link)`
  all: unset;
  height: 0px;
`;

const InsightBoxesContainer = styled.div``;

const ButtonContainer = styled.div`
  margin-bottom: 55px;
`;

interface BoxProps {
  icon: string;
  title: string;
  text: string;
  tooltip?: string;
}

interface RecentInsightsHeaderProps {
  mostRecentAnalyzedDay: string;
  mostRecentAnalysislink: string;
  image: string;
  insightBoxesData: BoxProps[];
}

export const RecentInsightsHeader: FC<RecentInsightsHeaderProps> = ({
  mostRecentAnalyzedDay,
  mostRecentAnalysislink,
  insightBoxesData,
}) => {
  const userClient = useClientStore((state) => state.client);
  const institutionType = userClient?.institutionType;

  return (
    <StyledCard borderStyle={'none'}>
      <RecentInsightsHeaderContent>
        <TextContainer>
          <StyledTitle>Most Recent Insights and Actions</StyledTitle>
          <BodyText size="large" color={baseColors.text.textBlue60}>
            Your data has been analyzed through <b>{mostRecentAnalyzedDay}</b>. We found the
            following ways to help keep your {getUserType(institutionType, false, 'plural')} in good
            financial health.
          </BodyText>
        </TextContainer>
        <InsightBoxesContainer>
          <IconBoxRow boxes={insightBoxesData} />
        </InsightBoxesContainer>
        <ButtonContainer>
          <NoStyleLink to={mostRecentAnalysislink}>
            <PrimaryButton
              label={'View full most recent analysis'}
              rightIcon="/feather-sprite.svg#arrow-right"
              size="large"
            />
          </NoStyleLink>
        </ButtonContainer>
      </RecentInsightsHeaderContent>
    </StyledCard>
  );
};
