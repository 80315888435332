import { baseColors } from '../../components/themeing/themes';
import styled from 'styled-components';
import { HeaderText } from '../../components/typography/HeaderText';
import { BodyText } from '../../components/typography/BodyText';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import Card from '../../components/outlines/Card';
import BarGraph from '../../components/charts/BarGraph';
import { DesignSystemColorsTheme } from '../../components/themeing/themes';
import Tooltip from '../../components/Tooltip';
import primary50Dot from '../../images/primary50Dot.svg';
import textBlue80Dot from '../../images/textBlue80Dot.svg';
import { ClientMonthlyStats } from '../../models/ClientMonthlyStats';
import { useNavigate } from 'react-router-dom';
import { getUserType } from '../../utils/account-holder-translation';
import { useClientStore } from '../../stores/clientStore';

interface OverviewTabProps {
  totalNumberOfMembers: number;
  clientMonthlyStats: ClientMonthlyStats;
  percentageOfLightRevolvers: string;
  percentageOfHeavyRevolvers: string;
}

const OverviewContent = styled.div`
  align-items: center;
  margin: 40px;
`;

const OverviewContentHeader = styled(HeaderText)`
  margin-bottom: 16px;
`;

const OverviewContentSubtext = styled(BodyText)`
  margin-bottom: 24px;
`;

const OverviewContentHeaderBottom = styled(HeaderText)`
  margin-top: 24px;
  margin-bottom: 16px;
`;

const OverviewContentSubtextBottom = styled(BodyText)`
  margin-bottom: 16px;
`;

const OverviewContentButtonBottom = styled(PrimaryButton)`
  margin-bottom: 40px;
`;

const OverviewGraphContainer = styled.div`
  display: inline-block;
  width: 302px;
  height: 280px;
`;

const OverviewCardContainer = styled.div`
  display: inline-flex;
  margin-left: 34px;

  div:first-child {
    display: flex;
  }

  img {
    width: 24px;
    height: 24px;
  }
`;

const OverviewCardInner = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 16px 24px 16px;
  justify-content: space-between;
`;

const LowerCardContainer = styled.div`
  display: flex;
  align-items: center;
`;

const InfoLine = styled.div`
  display: flex;
`;

const InfoLineHeader = styled(HeaderText)`
  margin-left: 15px;
`;

const InfoLineText = styled(BodyText)`
  margin-left: 10px;
  margin-right: 10px;
`;

const OverviewTab = ({
  totalNumberOfMembers,
  clientMonthlyStats,
  percentageOfLightRevolvers,
  percentageOfHeavyRevolvers,
}: OverviewTabProps) => {
  const overviewChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      colors: {
        forceOverride: true,
      },
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: 'Heavy vs. Light Revolver breakdown',
      },
      tooltip: {
        enabled: false,
      },
      datalabels: {
        display: true,
        color: 'black',
        align: 'end' as const,
        anchor: 'end' as const,
        formatter: function (value: number) {
          return value.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        },
        font: {
          size: 12,
          family: 'Sora',
          weight: 600,
        },
      },
    },
    scales: {
      y: {
        title: {
          display: true,
          text: '# of Likely Revolvers',
          color: baseColors.text.textBlue40,
          font: {
            size: 14,
            family: 'Sora',
            weight: '600',
          },
        },
        min: 0,
        max: totalNumberOfMembers,
        ticks: {
          padding: 10,
          display: true,
          precision: -1,
          count: 10,
          font: {
            size: 12,
            family: 'Nunito Sans',
            weight: '400',
          },
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 12,
            family: 'Nunito Sans',
            weight: '400',
          },
        },
      },
    },
  };

  const revolverInsightsData = {
    labels: ['Light Revolvers', 'Heavy Revolvers'],
    datasets: [
      {
        label: 'Percent of Likely Revolvers',
        data: [clientMonthlyStats?.lightRevolvers ?? 0, clientMonthlyStats?.heavyRevolvers ?? 0],
        backgroundColor: [baseColors.primary.primary50, baseColors.text.textBlue80],
        borderRadius: { topLeft: 8, topRight: 8 },
        barThickness: 40,
        hoverBackgroundColor: DesignSystemColorsTheme.primary.primary100,
      },
    ],
  };

  const navigate = useNavigate();
  const userClient = useClientStore((state) => state.client);
  const institutionType = userClient?.institutionType;

  const lightRevolversTooltipContent = `${getUserType(
    institutionType,
    true,
    'plural',
  )} who are likely to revolve their card balance some of the time, as identified by the analysis methodology.`;
  const heavyRevolversTooltipContent = `${getUserType(
    institutionType,
    true,
    'plural',
  )} who are likely to revolve their card balance most of the time, as identified by the analysis methodology.`;

  return (
    <OverviewContent>
      <OverviewContentHeader color={baseColors.text.textBlue80} size="medium">
        Revolver insights
      </OverviewContentHeader>
      <OverviewContentSubtext size="large">
        You had{' '}
        {(
          (clientMonthlyStats?.lightRevolvers || 0) + (clientMonthlyStats?.heavyRevolvers || 0)
        ).toLocaleString()}{' '}
        Likely Revolvers this month. Of those, {clientMonthlyStats?.heavyRevolvers.toLocaleString()}{' '}
        are Heavy Revolvers, meaning that they are likely to revolve their card balance most of the
        time.
      </OverviewContentSubtext>
      <LowerCardContainer>
        <OverviewGraphContainer>
          <BarGraph data={revolverInsightsData} options={overviewChartOptions} />
        </OverviewGraphContainer>
        <OverviewCardContainer>
          <Card
            width="353px"
            height="152px"
            background={baseColors.primary.primary5}
            borderStyle="none"
          >
            <OverviewCardInner>
              <InfoLine>
                <img src={primary50Dot} alt="info icon" />
                <InfoLineHeader size="small">{percentageOfLightRevolvers}%</InfoLineHeader>
                <InfoLineText size="large">
                  {' '}
                  of your Likely Revolvers are Light Revolvers
                </InfoLineText>
                <Tooltip
                  alignment="center"
                  side="right"
                  size="large"
                  header="Light Revolvers"
                  content={lightRevolversTooltipContent}
                ></Tooltip>
              </InfoLine>
              <InfoLine>
                <img src={textBlue80Dot} alt="info icon" />
                <InfoLineHeader size="small">{percentageOfHeavyRevolvers}%</InfoLineHeader>
                <InfoLineText size="large">
                  {' '}
                  of your Likely Revolvers are Heavy Revolvers
                </InfoLineText>
                <Tooltip
                  alignment="center"
                  side="right"
                  size="large"
                  header="Heavy Revolvers"
                  content={heavyRevolversTooltipContent}
                ></Tooltip>
              </InfoLine>
            </OverviewCardInner>
          </Card>
        </OverviewCardContainer>
      </LowerCardContainer>
      <OverviewContentHeaderBottom size="medium">Take Action</OverviewContentHeaderBottom>
      <OverviewContentSubtextBottom size="large">
        Learn how to message your Likely Revolver audience with our Baseline Revolver Campaign.
      </OverviewContentSubtextBottom>
      <OverviewContentButtonBottom
        label="View the Baseline Refinance Campaign"
        size="large"
        onClick={() => navigate('/marketing-resources')}
      ></OverviewContentButtonBottom>
    </OverviewContent>
  );
};

export default OverviewTab;
