export const getUserType = (
  institutionType: string | undefined,
  capitalize = false,
  form: 'singular' | 'plural' | 'possessive' = 'singular',
) => {
  let userType = '';
  if (institutionType === 'Credit Union') {
    userType = 'member';
  } else if (institutionType === 'Bank') {
    userType = 'customer';
  } else {
    userType = 'account holder';
  }

  switch (form) {
    case 'plural':
      userType += 's';
      break;
    case 'possessive':
      userType += "'s";
      break;
    default:
      break;
  }

  if (capitalize) {
    userType = userType.charAt(0).toUpperCase() + userType.slice(1);
  }

  return userType;
};
