import { BodyText } from '../typography/BodyText';
import styled from 'styled-components';
import { HeaderText } from '../typography/HeaderText';

const StyledContentContainer = styled.div`
  margin-top: 32px;
`;

export function ConfirmUploadModalContent() {
  return (
    <StyledContentContainer>
      <HeaderText size="small">
        Please confirm that your dataset meets the following criteria:
      </HeaderText>
      <ul>
        <li>
          <BodyText size="medium">
            The UUIDs used in these files are the same as the ones used in the previous file(s) - so
            we can join the data across the analyses accurately for the same individuals.
          </BodyText>
        </li>
        <li>
          <BodyText size="medium">
            Your file(s) should includes all checking account ACH withdrawal transactions from the
            months you are including for analysis.
          </BodyText>
        </li>
      </ul>
    </StyledContentContainer>
  );
}
