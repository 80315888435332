import { baseColors } from '../../components/themeing/themes';
import styled from 'styled-components';
import { HeaderText } from '../../components/typography/HeaderText';
import { BodyText } from '../../components/typography/BodyText';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import Card from '../../components/outlines/Card';
import DoughnutChart from '../../components/charts/DoughnutChart';
import Tooltip from '../../components/Tooltip';
import primary50Dot from '../../images/primary50Dot.svg';
import textBlue80Dot from '../../images/textBlue80Dot.svg';
import { ClientMonthlyStats } from '../../models/ClientMonthlyStats';
import { TooltipItem } from 'chart.js';
import { getUserType } from '../../utils/account-holder-translation';
import { useNavigate } from 'react-router-dom';

interface HeavyRevolverTabProps {
  recentCount: number;
  clientMonthlyStats: ClientMonthlyStats;
  institutionType: string | undefined;
}

const OverviewContent = styled.div`
  align-items: center;
  margin: 40px;
`;

const OverviewContentHeader = styled(HeaderText)`
  margin-bottom: 16px;
`;

const OverviewContentSubtext = styled(BodyText)`
  margin-bottom: 24px;
`;

const OverviewContentHeaderBottom = styled(HeaderText)`
  margin-top: 24px;
  margin-bottom: 16px;
`;

const OverviewContentSubtextBottom = styled(BodyText)`
  margin-bottom: 16px;
`;

const OverviewContentButtonBottom = styled(PrimaryButton)`
  margin-bottom: 40px;
`;

const OverviewGraphContainer = styled.div`
  display: inline-block;
  width: 302px;
  height: 280px;
`;

const OverviewCardContainer = styled.div`
  display: inline-flex;
  margin-left: 34px;

  div:first-child {
    display: flex;
  }

  img {
    width: 24px;
    height: 24px;
  }
`;

const OverviewCardInner = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 16px 24px 16px;
  justify-content: space-between;
`;

const LowerCardContainer = styled.div`
  display: flex;
  align-items: center;
`;

const InfoLine = styled.div`
  display: flex;
`;

const InfoLineHeader = styled(HeaderText)`
  margin-left: 15px;
`;

const InfoLineText = styled(BodyText)`
  margin-left: 10px;
  margin-right: 10px;
`;

const HeavyRevolversTab = ({
  recentCount,
  clientMonthlyStats,
  institutionType,
}: HeavyRevolverTabProps) => {
  const doughnutOptions = {
    centerText: {
      display: true,
      text: '280',
    },
    cutout: '60%',
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          title: function (context: TooltipItem<'doughnut'>[]) {
            return context[0].label[3];
          },
          label: function (context: TooltipItem<'doughnut'>) {
            return context.label[1] + ' ' + getUserType(institutionType, false, 'plural');
          },
        },
        backgroundColor: '#FFF',
        borderColor: baseColors.primary.primary20,
        borderWidth: 1,
        titleFontSize: 16,
        titleFont: {
          family: 'Sora',
          weight: '600',
        },
        titleColor: 'black',
        bodyFont: {
          family: 'Nunito Sans',
          weight: '400',
        },
        bodyColor: baseColors.text.textBlue80,
        bodyFontSize: 14,
        displayColors: false,
        cornerRadius: 12,
        padding: {
          top: 12,
          bottom: 12,
          left: 16,
          right: 16,
        },
      },
    },
  };
  const { heavyRevolvers } = clientMonthlyStats;
  const percentageOfRecentRevolvers = ((recentCount / heavyRevolvers) * 100).toFixed(0);
  const percentageOfNonRecentRevolvers = (
    ((heavyRevolvers - recentCount) / heavyRevolvers) *
    100
  ).toFixed(0);

  const pieData = {
    labels: [
      [
        baseColors.text.textBlue80,
        (heavyRevolvers - recentCount).toLocaleString(),
        `${percentageOfNonRecentRevolvers}%`,
        'Non-Recent Revolvers',
      ],
      [
        baseColors.primary.primary50,
        recentCount.toLocaleString(),
        `${percentageOfRecentRevolvers}%`,
        'Recent Revolvers',
      ],
    ],
    datasets: [
      {
        label: `# of ${getUserType(institutionType, true, 'plural')}`,
        data: [heavyRevolvers, recentCount],
        hoverOffset: 4,
        backgroundColor: [baseColors.text.textBlue80, baseColors.primary.primary50],
        borderColor: 'white',
        borderWidth: 3,
        borderRadius: {
          outerStart: 10,
          outerEnd: 10,
          innerStart: 10,
          innerEnd: 10,
        },
      },
    ],
    centerText: {
      display: true,
      text: '280',
    },
  };

  const textCenter = {
    id: 'textCenter',
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    beforeDatasetsDraw(chart: any) {
      const { ctx } = chart;

      ctx.save();
      ctx.font = 'bolder 16px Sora';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(
        `Heavy Revolvers`,
        chart.getDatasetMeta(0).data[0].x,
        chart.getDatasetMeta(0).data[0].y - 20,
      );
      ctx.font = 'bolder 24px Sora';
      ctx.fillText(
        `${heavyRevolvers.toLocaleString()}`,
        chart.getDatasetMeta(0).data[0].x,
        chart.getDatasetMeta(0).data[0].y + 20,
      );
    },
  };

  const navigate = useNavigate();

  const recentRevolversTooltipContent = `${getUserType(
    institutionType,
    true,
    'plural',
  )} who showed revolving behavior in the most recent month.`;
  const nonRecentRevolversTooltipContent = `${getUserType(
    institutionType,
    true,
    'plural',
  )} who have shown revolving behavior in the past year, but who did not show revolving behavior in the most recent month.`;

  return (
    <OverviewContent>
      <OverviewContentHeader color={baseColors.text.textBlue80} size="medium">
        Heavy Revolvers
      </OverviewContentHeader>
      <OverviewContentSubtext size="large">
        You had {(clientMonthlyStats?.heavyRevolvers || 0).toLocaleString()} Heavy Revolvers this
        month. Of those Heavy Revolvers {recentCount.toLocaleString()} are Recent Revolvers --
        meaning that they showed revolving behavior this month.
      </OverviewContentSubtext>
      <LowerCardContainer>
        <OverviewGraphContainer>
          <DoughnutChart data={pieData} options={doughnutOptions} plugins={[textCenter]} />
        </OverviewGraphContainer>
        <OverviewCardContainer>
          <Card
            width="353px"
            height="152px"
            background={baseColors.primary.primary5}
            borderStyle="none"
          >
            <OverviewCardInner>
              <InfoLine>
                <img src={primary50Dot} alt="info icon" />
                <InfoLineHeader size="small">{percentageOfRecentRevolvers}%</InfoLineHeader>
                <InfoLineText size="large">
                  {' '}
                  of your Heavy Revolvers are Recent Revolvers
                </InfoLineText>
                <Tooltip
                  alignment="center"
                  side="right"
                  size="large"
                  header="Recent Revolvers"
                  content={recentRevolversTooltipContent}
                ></Tooltip>
              </InfoLine>
              <InfoLine>
                <img src={textBlue80Dot} alt="info icon" />
                <InfoLineHeader size="small">{percentageOfNonRecentRevolvers}%</InfoLineHeader>
                <InfoLineText size="large"> of your Heavy Revolvers are Non-Recent</InfoLineText>
                <Tooltip
                  alignment="center"
                  side="right"
                  size="large"
                  header="Non-recent Revolvers"
                  content={nonRecentRevolversTooltipContent}
                ></Tooltip>
              </InfoLine>
            </OverviewCardInner>
          </Card>
        </OverviewCardContainer>
      </LowerCardContainer>
      <OverviewContentHeaderBottom size="medium">Take Action</OverviewContentHeaderBottom>
      <OverviewContentSubtextBottom size="large">
        We recommend especially proactive messaging to your Heavy and Recent Revolvers. See
        suggestions especially for this group in our Baseline Revolver Campaign.
      </OverviewContentSubtextBottom>
      <OverviewContentButtonBottom
        label="View the Baseline Refinance Campaign"
        size="large"
        onClick={() => navigate('/marketing-resources')}
      ></OverviewContentButtonBottom>
    </OverviewContent>
  );
};

export default HeavyRevolversTab;
