const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const clientMonthlyStatsEndpointUrlBase = `${apiServerUrl}/api/analysis/client-monthly-stats`;
export const analysisOutputDownloadEndpointUrlBase = `${apiServerUrl}/api/analysis/output`;
export const downloadsUrlBase = `${apiServerUrl}/api/downloads`;
export const revolvingAccountsEndpointUrlBase = `${apiServerUrl}/api/analysis/revolving-accounts`;
export const recentRevolverCountEndpointUrlBase = `${apiServerUrl}/api/analysis/member-categorization-recent`;
export const monthlyNewRevolverStatsEndpointUrlBase = `${apiServerUrl}/api/monthly-new-revolver-stats`;
export const transactorTopBankPaymentsEndpointUrlBase = `${apiServerUrl}/api/transactor-top-bank-payments`;
export const transactorAccountsCountEndpointUrlBase = `${apiServerUrl}/api/transactor-user-accounts`;
export const revolverTopBankPaymentsEndpointUrlBase = `${apiServerUrl}/api/revolver-top-bank-payments`;
