import { baseColors } from '../../components/themeing/themes';
import styled from 'styled-components';
import { HeaderText } from '../../components/typography/HeaderText';
import { BodyText } from '../../components/typography/BodyText';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import BarGraph from '../../components/charts/BarGraph';
import { ClientMonthlyStats } from '../../models/ClientMonthlyStats';
import { useNavigate } from 'react-router-dom';
import { ButtonText } from '../../components/typography/ButtonText';
import { RevolvingAccounts } from '../../models/RevolvingAccounts';
import { abbreviateNumber } from '../../utils/common';

interface AccountStatsTabProps {
  revolvingAccounts: RevolvingAccounts;
  clientMonthlyStats: ClientMonthlyStats;
}

const OverviewContent = styled.div`
  align-items: center;
  margin: 40px;
`;

const OverviewContentHeader = styled(HeaderText)`
  margin-bottom: 16px;
`;

const OverviewContentSubtext = styled(BodyText)`
  margin-bottom: 24px;
`;

const OverviewContentHeaderBottom = styled(HeaderText)`
  margin-top: 24px;
  margin-bottom: 16px;
`;

const OverviewContentSubtextBottom = styled(BodyText)`
  margin-bottom: 16px;
`;

const OverviewContentButtonBottom = styled(PrimaryButton)`
  margin-bottom: 40px;
`;

const OverviewGraphContainer = styled.div`
  display: inline-block;
  width: 693px;
  height: 300px;
  canvas {
    margin: 20px;
  }
`;

const LowerCardContainer = styled.div`
  display: flex;
  height: 300px;
  align-items: center;
`;

const CardProvidersTextContainer = styled.div`
  margin-top: 10px;
  display: flex;
  text-align: center;
`;

const CardProvidersText = styled(ButtonText)`
  margin: auto;
  color: ${baseColors.text.textBlue40};
`;

const AccountStatsTab = ({ revolvingAccounts, clientMonthlyStats }: AccountStatsTabProps) => {
  const overviewChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      colors: {
        forceOverride: true,
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        displayColors: false,
        backgroundColor: baseColors.text.textBlue80,
        bodyColor: baseColors.white,
        titleFont: {
          size: 0,
        },
        bodyFont: {
          size: 12,
          family: 'Sora',
        },
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      y: {
        offset: true,
        title: {
          display: true,
          text: 'Number of revolvers',
          color: baseColors.text.textBlue40,
          font: {
            size: 14,
            family: 'Sora',
            weight: '600',
          },
        },
        min: 0,
        max: 0,
        ticks: {
          callback: function (value: string | number) {
            const numericValue = typeof value === 'string' ? parseFloat(value) : value;
            return abbreviateNumber(numericValue);
          },
          padding: 10,
          display: true,
          precision: -1,
          count: 10,
          font: {
            size: 12,
            family: 'Nunito Sans',
            weight: '400',
          },
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 12,
            family: 'Nunito Sans',
            weight: '400',
          },
        },
      },
    },
  };
  let max = 0;
  let moreThan5 = 0;
  const labels = ['0', '1', '2', '3', '4', '5+'];
  const filtered = revolvingAccounts.revolvingAccountsCountsPayments.filter(
    (payment) => Number(payment.number_revolving_accounts) < 5,
  );
  const data = filtered.map((payment) => {
    if (Number(payment.number_of_members) > max) {
      max = Number(payment.number_of_members);
    }
    return Number(payment.number_of_members);
  });
  revolvingAccounts.revolvingAccountsCountsPayments.forEach((payment) => {
    if (Number(payment.number_revolving_accounts) >= 5) {
      moreThan5 += Number(payment.number_of_members);
    }
    return payment;
  });
  data.push(moreThan5);
  overviewChartOptions.scales.y.max = max;
  const accountStatsData = {
    labels,
    datasets: [
      {
        data,
        backgroundColor: baseColors.text.textBlue80,
        borderRadius: { topLeft: 8, topRight: 8 },
        barThickness: 30,

        hoverBackgroundColor: baseColors.primary.primary100,
      },
    ],
  };

  const navigate = useNavigate();
  return (
    <OverviewContent>
      <OverviewContentHeader color={baseColors.text.textBlue80} size="medium">
        Number of Revolving Accounts
      </OverviewContentHeader>
      <OverviewContentSubtext size="large">
        Likely Revolvers can carry a balance on their cards with more than one competitor card
        provider. The following graph shows the distribution of your{' '}
        {(
          (clientMonthlyStats?.lightRevolvers || 0) + (clientMonthlyStats?.heavyRevolvers || 0)
        ).toLocaleString()}{' '}
        Likely Revolvers across the number of external revolving accounts that they hold.
      </OverviewContentSubtext>
      <CardProvidersTextContainer>
        <CardProvidersText size="medium">
          Distribution of Likely Revolvers across number of external accounts
        </CardProvidersText>
      </CardProvidersTextContainer>
      <LowerCardContainer>
        <OverviewGraphContainer>
          <BarGraph data={accountStatsData} options={overviewChartOptions} />
        </OverviewGraphContainer>
      </LowerCardContainer>
      <CardProvidersTextContainer>
        <CardProvidersText size="medium">Number of accounts</CardProvidersText>
      </CardProvidersTextContainer>
      <OverviewContentHeaderBottom size="medium">Take action</OverviewContentHeaderBottom>
      <OverviewContentSubtextBottom size="large">
        Message your members with multiple Likely Revolving accounts about the benefits of
        consolidation with our Multiple Account Revolver Campaign.
      </OverviewContentSubtextBottom>
      <OverviewContentButtonBottom
        label="View the Multiple Account Revolver Campaign"
        size="large"
        onClick={() => navigate('/marketing-resources')}
      ></OverviewContentButtonBottom>
    </OverviewContent>
  );
};

export default AccountStatsTab;
