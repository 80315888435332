interface BankNameMappingProps {
  [bankName: string]: string;
}

export const bankNameMapping: BankNameMappingProps = {
  chase: 'JPMorgan Chase',
  cap_one: 'Capital One',
  amex: 'American Express',
  citi: 'Citigroup',
  citizens: 'Citizens Bank',
  disc: 'Discover',
  boa: 'Bank of America',
  costco_anywhere_citicard: 'Costco Anywhere Visa Card by Citi',
  sams: "Sam's Club",
  Barclays: 'Barclays',
  meijer: 'Meijer',
  sync: 'Synchrony',
  usbank: 'US Bank',
  comenity: 'Comenity',
  home_dep: 'Home Depot',
  apple: 'Apple',
  PNC: 'PNC Bank',
  wells: 'Wells Fargo',
  amazon: 'Amazon',
  kohls: "Kohl's",
  lowes: "Lowe's",
  credit_one: 'Credit One',
  fifth_third: 'Fifth Third Bank',
  usaa: 'USAA Creedit Card',
  target: 'Target',
  old_navy: 'Old Navy',
  ll_bean: 'L.L. Bean',
  gap: 'Gap',
  banana_rep: 'Banana Republic',
  union_bank: 'Union Bank',
  navy_fed: 'Navy Federal Credit Union',
  bank_of_west: 'Bank of the West',
  td_bank: 'TD Bank',
  becu: 'BECU Credit Card',
  commerce: 'Commerce Bank',
  truist: 'Truist Bank',
  radius_bank: 'Radius Bank',
  fpb: 'First Premier Bank',
  jcpenny: 'JCPenny',
  bankcard: 'Bankcard',
  genesis: 'GenesisFS Card',
  dicks: "Dick's Sporting Goods",
  aspire: 'Aspire',
  american_eagle: 'American Eagle',
  other: 'Credit Card',
};
