import React, { useCallback, useEffect, useState } from 'react';
import {
  Column,
  Row,
  useGlobalFilter,
  useTable,
  useSortBy,
  useRowSelect,
  HeaderGroup,
  TableInstance,
} from 'react-table';
import styled from 'styled-components';
import { BodyText } from '../typography/BodyText';
import { ButtonText } from '../typography/ButtonText';
import SingleSelectDropdown from '../dropdowns/SingleSelectDropdown';
import Input from '../inputs/Input';
import TertiaryButton from '../buttons/TertiaryButton';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';
import { DesignSystemColorsTheme } from '../themeing/themes';

const Styles = styled.div``;

const TableContainer = styled.div`
  max-height: 400px;
  min-height: 400px;
  overflow-y: auto;

  /* Width */
  ::-webkit-scrollbar {
    width: 12px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.primary.primary10};
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.primary.primary100};
    border-radius: 100px;
  }
`;

const PaddingContainer = styled.div`
  padding-right: 32px;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
`;

const Tbody = styled.tbody``;

const Tr = styled.tr`
  height: 48px;
  border-bottom: 1px solid ${(props) => props.theme.primary.primary20};
`;

const Thead = styled.thead`
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 100;
`;

const Th = styled.th<{ align?: AlignOptions; isCheckbox?: boolean; width?: string }>`
  position: sticky;
  top: 0;
  text-align: ${(props) => props.align || 'left'};
  background-color: white;
  width: ${(props) => (props.isCheckbox ? '30px' : props.width || '100%')};
  min-width: ${(props) => (props.isCheckbox ? '30px' : '150px')};
`;

const Td = styled.td<{ align?: AlignOptions; isCheckbox?: boolean; width?: string }>`
  padding-right: 16px;
  text-align: ${(props) => props.align || 'left'};
  width: ${(props) => (props.isCheckbox ? '30px' : props.width || '100%')};
  min-width: ${(props) => (props.isCheckbox ? '30px' : '150px')};
`;

const DropdownContainer = styled.div`
  z-index: 1000;
`;

const HeaderIconContainer = styled.div<{ align?: AlignOptions }>`
  display: flex;
  justify-content: ${(props) => props.align || 'left'};
`;

const SortIcons = styled.div`
  margin-left: 2px;
  display: flex;
  align-items: center;
`;

const TableControlContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 32px;
`;

const TableControlGroupLeft = styled.div`
  display: flex;
  gap: 36px;
  align-items: center;
`;

const TableControlGroupRight = styled.div`
  display: flex;
  gap: 16px;
`;

export type AlignOptions = 'left' | 'center' | 'right';

export interface DataRow {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  [key: string]: any;
}

export type CustomColumn = Column<DataRow> & { align?: AlignOptions; width?: string };

export interface FilterOption {
  id: string;
  label: string;
  options: Array<{ label: string; value: string }>;
  filterFn: (row: DataRow, value: string) => boolean | void;
}

export enum ButtonType {
  Primary = 'primary',
  Secondary = 'secondary',
}

export interface TableButton {
  label: string;
  onClick: () => void;
  buttonType: ButtonType;
}

interface TableProps {
  columns: CustomColumn[];
  data: DataRow[];
  selectableRows?: boolean;
  enableSorting?: boolean;
  enableSearch?: boolean;
  filterOptions?: FilterOption[];
  tableButtons?: TableButton[];
}

interface GlobalFilterProps {
  preGlobalFilteredRows: Row<Record<string, unknown>>[];
  globalFilter: string;
  setGlobalFilter: (filterValue: string | undefined) => void;
}

const GlobalFilter: React.FC<GlobalFilterProps> = ({ globalFilter, setGlobalFilter }) => {
  return (
    <Input
      value={globalFilter || ''}
      onChange={(e) => {
        setGlobalFilter(e?.target.value || undefined);
      }}
      placeholder={`Search`}
      icon={'/feather-sprite.svg#search'}
      width={210}
      height={42}
    />
  );
};

/**
 * This is a custom table component built with react-table.
 *
 * @component
 *
 * @prop {CustomColumn[]} columns - An array of objects defining the columns for the table.
 * @prop {DataRow[]} data - An array of objects representing the table data.
 * @prop {boolean} [selectableRows=false] - Optional. Determines whether rows are selectable.
 * @prop {boolean} [enableSorting=false] - Optional. Determines whether table sorting is enabled.
 * @prop {boolean} [enableSearch=false] - Optional. Determines whether a global search function is available.
 * @prop {FilterOption[]} [filterOptions=[]] - Optional. An array of filter options for custom filtering.
 * @prop {TableButton[]} [tableButtons=[]] - Optional. An array of button configurations that determine the buttons displayed in the table header. Each button has a label, a callback function triggered on click, and a button type (Primary or Secondary).
 *
 * @see CustomTables.stories.tsx Storybook for more examples of usage
 *
 * @see [react-table documentation]{@link https://github.com/TanStack/table/tree/v7/docs/src/pages/docs}
 *
 * Usage:
 * ```jsx
 * const columns = [
 *   { Header: "Name", accessor: "name" },
 *   { Header: "Age", accessor: "age" },
 * ];
 *
 * const data = [
 *   { name: "Alice", age: 25 },
 *   { name: "Bob", age: 30 },
 * ];
 *
 * const tableButtons = [
 *   { label: "Add", onClick: () => console.log('add clicked'), buttonType: ButtonType.Primary },
 *   { label: "Delete", onClick: () => console.log('delete clicked'), buttonType: ButtonType.Secondary },
 * ];
 *
 * <CustomTable columns={columns} data={data} selectableRows={true} enableSorting={true} enableSearch={true} tableButtons={tableButtons} />
 * ```
 */
export const CustomTable: React.FC<TableProps> = ({
  columns,
  data,
  selectableRows,
  enableSorting,
  enableSearch,
  filterOptions,
  tableButtons,
}) => {
  const [filters, setFilters] = useState(() => {
    const initialFilters: { [key: string]: string } = {};
    filterOptions?.forEach((option) => {
      initialFilters[option.id] = '';
    });
    return initialFilters;
  });

  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    let tempFilteredData = data;

    filterOptions?.forEach((option) => {
      const filterValue = filters[option.id];
      tempFilteredData = tempFilteredData.filter((row) => option.filterFn(row, filterValue));
    });

    setFilteredData(tempFilteredData);
  }, [filters, filterOptions, data]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    toggleAllRowsSelected,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { globalFilter },
  }: TableInstance<DataRow> = useTable(
    {
      columns,
      data: filteredData,
      manualFilters: true,
      filterTypes: {
        custom: (records, ids, filterValue) => {
          const filteredRows = records.filter((record) =>
            ids.some((id) => {
              const filterOption = filterOptions?.find((option) => option.id === id);
              if (filterOption) {
                return filterOption.filterFn(record, filterValue);
              }
              return false;
            }),
          );
          return filteredRows;
        },
      },
    },
    useGlobalFilter,
    useSortBy,
    useRowSelect,
  );

  const setFilter = useCallback(
    (id: string, value: string) => {
      setFilters((oldFilters) => ({
        ...oldFilters,
        [id]: value,
      }));
    },
    [setFilters],
  );

  const handleClearFilters = () => {
    // Reset global filter (search)
    setGlobalFilter(undefined);

    // Reset local filters (dropdown)
    const initialFilters: { [key: string]: string } = {};
    filterOptions?.forEach((option) => {
      initialFilters[option.id] = '';
    });
    setFilters(initialFilters);
  };

  return (
    <Styles>
      <TableControlContainer>
        <TableControlGroupLeft>
          {enableSearch && (
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          )}
          {filterOptions?.map((option, i) => (
            <DropdownContainer key={i}>
              <SingleSelectDropdown
                options={option.options}
                value={filters[option.id]}
                onChange={(value) => setFilter(option.id, value)}
                placeholder="Filter"
                width={'210px'}
              />
            </DropdownContainer>
          ))}
          {(enableSearch || filterOptions) && (
            <div>
              <TertiaryButton size="small" onClick={handleClearFilters} label={'X Clear Filters'} />
            </div>
          )}
        </TableControlGroupLeft>
        <TableControlGroupRight>
          {tableButtons?.map((action, index) => (
            <div key={index}>
              {action.buttonType === ButtonType.Primary ? (
                <PrimaryButton size="medium" onClick={action.onClick} label={action.label} />
              ) : (
                <SecondaryButton size="medium" onClick={action.onClick} label={action.label} />
              )}
            </div>
          ))}
        </TableControlGroupRight>
      </TableControlContainer>

      <TableContainer>
        <PaddingContainer>
          <Table {...getTableProps()}>
            <Thead>
              {headerGroups.map((headerGroup: HeaderGroup<DataRow>, idx) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={idx}>
                  {selectableRows && (
                    <Th isCheckbox={true}>
                      <input type="checkbox" onChange={() => toggleAllRowsSelected()} />
                    </Th>
                  )}
                  {headerGroup.headers.map((column, i) => {
                    // We need to cast our column to use the CustomColumn type to access the align property
                    const customColumn = column as unknown as CustomColumn;
                    return (
                      <Th
                        {...column.getHeaderProps(
                          enableSorting ? column.getSortByToggleProps() : {},
                        )} // Conditionally apply getSortByToggleProps
                        align={customColumn.align}
                        width={customColumn.width}
                        key={i}
                      >
                        <HeaderIconContainer align={customColumn.align}>
                          <ButtonText size="large">{column.render('Header')}</ButtonText>
                          {enableSorting &&
                            !customColumn.disableSortBy && ( // Conditionally render sort icons
                              <SortIcons>
                                <svg
                                  style={{
                                    opacity: column.isSorted && !column.isSortedDesc ? 1 : 0.3,
                                  }}
                                  width="13"
                                  height="14"
                                  fill="none"
                                  stroke={DesignSystemColorsTheme.text.textBlue80}
                                  strokeWidth="2.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <use href="/feather-sprite.svg#arrow-up" />
                                </svg>
                                <svg
                                  style={{
                                    opacity: column.isSorted && column.isSortedDesc ? 1 : 0.3,
                                  }}
                                  width="13"
                                  height="14"
                                  fill="none"
                                  stroke={DesignSystemColorsTheme.text.textBlue80}
                                  strokeWidth="2.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <use href="/feather-sprite.svg#arrow-down" />
                                </svg>
                              </SortIcons>
                            )}
                        </HeaderIconContainer>
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {rows.map((row: Row<DataRow>, i) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()} key={i}>
                    {selectableRows && (
                      <Td isCheckbox={true}>
                        <input type="checkbox" {...row.getToggleRowSelectedProps()} />
                      </Td>
                    )}
                    {row.cells.map((cell, index) => {
                      // Cast the column to CustomColumn to access align property
                      const customColumn = cell.column as unknown as CustomColumn;
                      return (
                        <Td
                          {...cell.getCellProps()}
                          align={customColumn.align}
                          width={customColumn.width}
                          title={cell.value.props?.children}
                          key={index}
                        >
                          <BodyText size="large">{cell.render('Cell')}</BodyText>
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </PaddingContainer>
      </TableContainer>
    </Styles>
  );
};
