import styled from 'styled-components';

export interface TitleTextProps {
  color?: string;
}

/**
 * TitleText is a styled-component that renders an <h1> element with custom styles.
 * It uses the Sora font family, normal font style, 600 font weight, 32px font size,
 * and 44px line height. The text color can be customized through the optional `color`
 * prop, or it will default to the value specified in the theme.
 *
 * @component
 * @example
 * // Render the TitleText with the default color
 * <TitleText>This is a title with the default color</TitleText>
 *
 * @example
 * // Render the TitleText with a custom color
 * <TitleText color="red">This is a title with a red color</TitleText>
 */
export const TitleText = styled.h1<TitleTextProps>`
  font-family: 'Sora';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 44px;
  color: ${(props) => props.color || props.theme.text.textBlue100};
  margin-top: 0;
  margin-bottom: 0;
`;
