import React, { FC } from 'react';
import styled from 'styled-components';
import { HeaderText } from './typography/HeaderText';
import { numberWithCommas } from '../utils/common';

interface RoundBoxProps {
  numberOfPayers: number;
  index?: number;
  width?: number;
  totalMembers: number;
  zIndex: number;
  color: string;
}

const BoxText = styled(HeaderText)`
  top: 25%;
  margin-top: auto;
  margin-bottom: auto;
  color: ${({ theme }) => theme.colorBaseWhite};
  padding-right: 22px;
`;

const Box = styled.div<{ width?: number; zIndex?: number; color: string }>`
  display: flex;
  justify-content: right;
  border-radius: 209.907px;
  border: 3px solid var(--white, #fff);
  background: ${(props) => props.color};
  height: 58px;
  width: ${(props) => props.width}%;
  z-index: ${(props) => props.zIndex};
`;

const RoundBox: FC<RoundBoxProps> = ({ numberOfPayers, totalMembers, zIndex, color }) => {
  const width = (numberOfPayers / totalMembers) * 100;
  return (
    <Box width={width} zIndex={zIndex} color={color}>
      <BoxText size="medium">{numberWithCommas(numberOfPayers.toString())}</BoxText>
    </Box>
  );
};

const RoundBoxRowContainer = styled.div`
  padding-left: 8px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
  margin-bottom: 24px;
  gap: 24px;
  width: 100%;
  position: relative;
  > div:not(:last-child) {
    margin-right: -50px;
  }
`;

interface RoundBoxRowProps {
  roundboxes: RoundBoxProps[];
}

const RoundBoxRow: FC<RoundBoxRowProps> = ({ roundboxes }) => (
  <RoundBoxRowContainer>
    {roundboxes.map((roundbox, index) => (
      <RoundBox
        key={index}
        index={index}
        numberOfPayers={roundbox.numberOfPayers}
        totalMembers={roundbox.totalMembers}
        zIndex={roundbox.zIndex}
        color={roundbox.color}
      />
    ))}
  </RoundBoxRowContainer>
);

export default RoundBoxRow;
