import { PageLayout } from '../../components/PageLayout';
import { useCallback, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { createUser, getUser } from '../../services/user.service';
import { getClientMonthlyStats } from '../../services/client-monthly-stats.service';
import { ClientMonthlyStats } from '../../models/ClientMonthlyStats';
import styled from 'styled-components';
import creditCardIcon from '../../images/design system/card-icon.svg';
import userPlusIcon from '../../images/design system/user-plus-icon.svg';
import usersIcon from '../../images/design system/users-icon.svg';
import dollarSignIcon from '../../images/design system/dollar-sign-icon.svg';
import uploadIcon from '../../images/uploadIcon.svg';
import smilingWoman from '../../images/smilingWoman.png';
import emptyBox from '../../images/emptyBox.png';
import { RecentInsightsHeader } from '../../components/RecentInsightsHeader/RecentInsightsHeader';
import MonthlyAnalysisHistory from '../../components/MonthlyAnalysisHistory';
import { abbreviateNumber, numberWithCommas } from '../../utils/common';
import { getLastDayOfMonth } from '../../utils/date';
import { Link, useNavigate } from 'react-router-dom';
import { PageLoader } from '../../components/PageLoader';
import { HeaderText } from '../../components/typography/HeaderText';
import Card from '../../components/outlines/Card';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import { getAllMetadata } from '../../services/transaction-upload.service';
import { getUserType } from '../../utils/account-holder-translation';
import { useClientStore } from '../../stores/clientStore';

const NoStyleLink = styled(Link)`
  all: unset;
`;

const StyledCard = styled(Card)`
  display: flex;
  margin-top: 32px;
  margin-bottom: 32px;
  padding: 32px 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: 24px;
  border: 1px solid var(--stroke, #dbe2ff);
  background: var(--white, #fff);

  box-shadow: 0px 2px 5px 0px rgba(85, 85, 88, 0.02), 0px 8px 10px 0px rgba(65, 61, 231, 0.03);
`;

const CommonContainer = styled.div`
  max-width: 100rem;
  margin: 0 auto;
`;

const NoDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 64px;

  width: 1088px;
  height: 589px;

  background: #ffffff;
  /* Neutral/Gray 100 */

  border: 1px solid #e0e0e6;
  border-radius: 8px;
`;

const TitleButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 24px;
  margin-bottom: 40px;
`;

const StyledNoDataImageContainer = styled.div`
  padding-top: 47px;
`;

const StyledNoDataTitleContainer = styled.div`
  padding-top: 40px;
`;

const StyledNoDataTitle = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  font: Sora;
  text-align: center;
  color: #051549;
`;

const StyledNoDataTextContainer = styled.div`
  width: 568px;
  height: auto;
`;

const StyledNoDataText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font: Inter;

  text-align: center;

  color: #1e1e1e;
`;

const StartUploadingDataButton = styled.button`
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  width: 298px;
  height: 40px;

  background: #2f3d96;
  border-radius: 8px;

  cursor: pointer;
`;

const StartUploadingDataButtonText = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 24px;

  color: #fafbfc;
`;

export function CheckingAnalysisPage() {
  const userClient = useClientStore((state) => state.client);
  const institutionType = userClient?.institutionType;

  const { getAccessTokenSilently, user } = useAuth0();
  const [token, setToken] = useState<string>('');
  const [clientMonthlyStats, setClientMonthlyStats] = useState<ClientMonthlyStats[]>([]);
  const [mostRecentClientMonthlyStats, setMostRecentClientMonthlyStats] =
    useState<ClientMonthlyStats>();
  const [mostRecentLikelyRevolverCount, setMostRecentLikelyRevolverCount] = useState<number>(0);
  const [mostRecentRefinanceOpportunity, setMostRecentRefinanceOpportunity] = useState<number>(0);
  const [totalMemberCount, setTotalMemberCount] = useState<number>(0);
  const [transactorCount, setTransactorCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isDataFetched, setIsDataFetched] = useState<boolean>(false);
  const [isInProgress, setIsInProgress] = useState<boolean>(false);

  const fetchPreviousFiles = useCallback(async () => {
    if (!token) {
      return;
    }

    const response = await getAllMetadata(token);
    const previousUploads = response.data;
    if (previousUploads != null && previousUploads[0].analysisStatus == 'PENDING') {
      setIsInProgress(true);
    }
  }, [token]);

  useEffect(() => {
    fetchPreviousFiles();
  }, [fetchPreviousFiles]);

  const navigate = useNavigate();

  useEffect(() => {
    const getUserInfo = async () => {
      setIsLoading(true);
      const accessToken = await getAccessTokenSilently();
      setToken(accessToken);

      if (user && user.email) {
        const { status, error } = await getUser(accessToken, user.email);

        if (error && status) {
          if (status === 404) {
            await createUser(accessToken, user.email, user.clientInfo.client_id);
          }
        }

        const { data: monthlyStats } = await getClientMonthlyStats(accessToken);
        if (monthlyStats) {
          setClientMonthlyStats(monthlyStats as ClientMonthlyStats[]);
        }
      }
    };
    getUserInfo().finally(() => setIsLoading(false));
  }, [getAccessTokenSilently, user]);

  useEffect(() => {
    const fetchRevolvingAccountsData = async () => {
      setIsDataFetched(false);
      if (clientMonthlyStats.length > 0) {
        const completedClientMonthlyStats = clientMonthlyStats.filter(
          (stat) => stat.completedOn !== null && stat.completedOn !== undefined,
        );
        // Sort the array by Year and Month
        completedClientMonthlyStats.sort((a, b) => {
          const yearDiff = parseInt(a.year, 10) - parseInt(b.year, 10);
          if (yearDiff !== 0) {
            return yearDiff;
          } else {
            return parseInt(a.month, 10) - parseInt(b.month, 10);
          }
        });

        // Get the most recent completed ClientMonthlyStats object (last element after sorting)
        const mostRecentStats = completedClientMonthlyStats[completedClientMonthlyStats.length - 1];

        const totalMembers =
          mostRecentStats.heavyRevolvers +
          mostRecentStats.lightRevolvers +
          mostRecentStats.transactors +
          mostRecentStats.cantCategorize +
          mostRecentStats.nonCardPayers;

        const likelyRevolverCount = mostRecentStats.heavyRevolvers + mostRecentStats.lightRevolvers;
        const refinanceOpportunity = likelyRevolverCount * 6000;

        // Set the state variables with the calculated values
        setTotalMemberCount(totalMembers);
        setTransactorCount(mostRecentStats.transactors);

        setMostRecentClientMonthlyStats(mostRecentStats);
        setMostRecentLikelyRevolverCount(likelyRevolverCount);
        setMostRecentRefinanceOpportunity(refinanceOpportunity);
      }
    };

    fetchRevolvingAccountsData().finally(() => setIsDataFetched(true));
  }, [clientMonthlyStats, token]);

  const boxesData = [
    {
      icon: usersIcon,
      title: `${numberWithCommas(totalMemberCount.toString())}`,
      text: `Members with checking accounts`,
    },
    {
      icon: creditCardIcon,
      title: `${numberWithCommas(mostRecentLikelyRevolverCount.toString())}`,
      text: 'Likely Revolvers identified',
    },
    {
      icon: userPlusIcon,
      title: `${numberWithCommas(transactorCount.toString())}`,
      text: 'Likely Transactors identified',
    },
    {
      icon: dollarSignIcon,
      title: `$${abbreviateNumber(mostRecentRefinanceOpportunity)}`,
      text: 'total Likely Revolver opportunity',
    },
  ];

  if (isLoading) {
    return <PageLoader />;
  } else if (mostRecentClientMonthlyStats && isDataFetched) {
    return (
      <>
        <PageLayout>
          <CommonContainer>
            <TitleButtonContainer>
              <HeaderText size="large">Analysis Home</HeaderText>
              <PrimaryButton
                size="medium"
                label="Start new analysis"
                rightIcon="/feather-sprite.svg#plus"
                onClick={() => navigate('/data-upload?showHistory=false')}
              />
            </TitleButtonContainer>

            <RecentInsightsHeader
              mostRecentAnalyzedDay={getLastDayOfMonth(
                mostRecentClientMonthlyStats?.month,
                mostRecentClientMonthlyStats?.year,
              )}
              image={smilingWoman}
              mostRecentAnalysislink={`/analysis/${mostRecentClientMonthlyStats?.year}/${mostRecentClientMonthlyStats?.month}`}
              insightBoxesData={boxesData}
            />
            <StyledCard>
              <HeaderText size="medium">Previous Months</HeaderText>
              <MonthlyAnalysisHistory accessToken={token} clientMonthlyStats={clientMonthlyStats} />
            </StyledCard>
          </CommonContainer>
        </PageLayout>
      </>
    );
  } else if (isInProgress) {
    return (
      <>
        <PageLayout>
          <CommonContainer>
            <TitleButtonContainer>
              <HeaderText size="large">Analysis Home</HeaderText>
            </TitleButtonContainer>
            <div style={{ width: '1088px' }}></div>
            <NoDataContainer>
              <StyledNoDataImageContainer>
                <img src={emptyBox} />
              </StyledNoDataImageContainer>
              <StyledNoDataTitleContainer>
                <StyledNoDataTitle>Analyzing</StyledNoDataTitle>
              </StyledNoDataTitleContainer>
              <StyledNoDataTextContainer>
                <StyledNoDataText>
                  We’re still analyzing your data. We’ll contact
                  <br />
                  you when your results are ready.
                  <br />
                </StyledNoDataText>
              </StyledNoDataTextContainer>
            </NoDataContainer>
          </CommonContainer>
        </PageLayout>
      </>
    );
  } else if (isDataFetched && !isLoading && !mostRecentClientMonthlyStats) {
    return (
      <>
        <PageLayout>
          <CommonContainer>
            <TitleButtonContainer>
              <HeaderText size="large">Analysis Home</HeaderText>
            </TitleButtonContainer>
            <div style={{ width: '1088px' }}></div>
            <NoDataContainer>
              <StyledNoDataImageContainer>
                <img src={emptyBox} />
              </StyledNoDataImageContainer>
              <StyledNoDataTitleContainer>
                <StyledNoDataTitle>No Data Yet</StyledNoDataTitle>
              </StyledNoDataTitleContainer>
              <StyledNoDataTextContainer>
                <StyledNoDataText>
                  It looks like you haven’t upload any data yet.
                  <br />
                  Upload data, start analyzing and improve
                  <br />
                  the financial health of your {getUserType(institutionType, false, 'plural')}.
                  <br />
                </StyledNoDataText>
              </StyledNoDataTextContainer>
              <NoStyleLink to="/data-upload">
                <StartUploadingDataButton>
                  <StartUploadingDataButtonText>Start Data Upload</StartUploadingDataButtonText>
                  <img src={uploadIcon} />
                </StartUploadingDataButton>
              </NoStyleLink>
            </NoDataContainer>
          </CommonContainer>
        </PageLayout>
      </>
    );
  } else {
    return null;
  }
}
