import { baseColors } from '../../components/themeing/themes';
import styled from 'styled-components';
import { HeaderText } from '../../components/typography/HeaderText';
import { BodyText } from '../../components/typography/BodyText';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import BarGraph from '../../components/charts/BarGraph';
import { DesignSystemColorsTheme } from '../../components/themeing/themes';
import { ClientMonthlyStats } from '../../models/ClientMonthlyStats';
import { useNavigate } from 'react-router-dom';
import { TransactorTopBankPayments } from '../../models/TransactorTopBankPayments';

interface AmountsPaidTabProps {
  transactorTopBankPayments: TransactorTopBankPayments[];
  clientMonthlyStats: ClientMonthlyStats;
}

const AmountsPaidContent = styled.div`
  align-items: center;
  margin: 40px;
`;

const AmountsPaidContentHeader = styled(HeaderText)`
  margin-bottom: 16px;
`;

const AmountsPaidContentSubtext = styled(BodyText)`
  margin-bottom: 24px;
`;

const AmountsPaidContentHeaderBottom = styled(HeaderText)`
  margin-top: 24px;
  margin-bottom: 16px;
`;

const AmountsPaidContentSubtextBottom = styled(BodyText)`
  margin-bottom: 16px;
`;

const AmountsPaidContentButtonBottom = styled(PrimaryButton)`
  margin-bottom: 40px;
`;

const AmountsPaidGraphContainer = styled.div`
  display: inline-block;
  width: 693px;
  height: 280px;
`;

const LowerCardContainer = styled.div`
  display: flex;
  align-items: center;
`;

const AmountsPaidTab = ({ transactorTopBankPayments, clientMonthlyStats }: AmountsPaidTabProps) => {
  const labels = ['$0 to $500', '$501 to $1000', '$1001 to $2000', '$2001+'];
  const data: number[] = [];
  // if (currentBank !== 'All') {
  transactorTopBankPayments.map((payment: TransactorTopBankPayments, idx: number) => {
    if (idx === 0) {
      data.push(payment.paymentsToAmountMemberCounts['500'] || 0);
      data.push(payment.paymentsToAmountMemberCounts['1000'] || 0);
      data.push(payment.paymentsToAmountMemberCounts['2000'] || 0);
      data.push(payment.paymentsToAmountMemberCounts['2000+'] || 0);
    } else {
      data[0] += payment.paymentsToAmountMemberCounts['500'] || 0;
      data[1] += payment.paymentsToAmountMemberCounts['1000'] || 0;
      data[2] += payment.paymentsToAmountMemberCounts['2000'] || 0;
      data[3] += payment.paymentsToAmountMemberCounts['2000+'] || 0;
    }
  });
  // }

  const AmountsPaidChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      colors: {
        forceOverride: true,
      },
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: 'Amounts paid across transactors per month to All Providers',
      },
      tooltip: {
        enabled: false,
      },
      datalabels: {
        display: true,
        color: 'black',
        align: 'end' as const,
        anchor: 'end' as const,
        font: {
          size: 12,
          family: 'Sora',
          weight: 600,
        },
      },
    },
    scales: {
      y: {
        title: {
          display: true,
          text: 'Number of members',
          color: baseColors.text.textBlue40,
          font: {
            size: 14,
            family: 'Sora',
            weight: '600',
          },
        },
        min: 0,
        ticks: {
          padding: 10,
          display: true,
          precision: -1,
          count: 10,
          font: {
            size: 12,
            family: 'Nunito Sans',
            weight: '400',
          },
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 12,
            family: 'Nunito Sans',
            weight: '400',
          },
        },
      },
    },
  };

  const revolverInsightsData = {
    labels,
    datasets: [
      {
        label: 'Number of members',
        data,
        backgroundColor: [
          baseColors.accent.accentGreen50,
          baseColors.accent.accentGreen50,
          baseColors.accent.accentGreen50,
          baseColors.accent.accentGreen100,
        ],
        borderRadius: { topLeft: 8, topRight: 8 },
        barThickness: 40,
        hoverBackgroundColor: DesignSystemColorsTheme.primary.primary100,
      },
    ],
  };

  const navigate = useNavigate();

  return (
    <AmountsPaidContent>
      <AmountsPaidContentHeader color={baseColors.text.textBlue80} size="medium">
        Amounts paid by transactors to Competitor providers
      </AmountsPaidContentHeader>
      <AmountsPaidContentSubtext size="large">
        The following graph shows a breakdown of how many of your{' '}
        {(clientMonthlyStats.lightRevolvers + clientMonthlyStats.heavyRevolvers).toLocaleString()}{' '}
        Likely Revolvers have made payments to the top card providers. Note that some Likely
        Revolvers pay multiple card providers.
      </AmountsPaidContentSubtext>
      <LowerCardContainer>
        <AmountsPaidGraphContainer>
          <BarGraph data={revolverInsightsData} options={AmountsPaidChartOptions} />
        </AmountsPaidGraphContainer>
      </LowerCardContainer>
      <AmountsPaidContentHeaderBottom size="medium">Take Action</AmountsPaidContentHeaderBottom>
      <AmountsPaidContentSubtextBottom size="large">
        Target high spend transactors with the High Spend Transactor Campaign to increase new card
        balances.
      </AmountsPaidContentSubtextBottom>
      <AmountsPaidContentButtonBottom
        label="View the High Spend Transactor Campaign"
        size="large"
        onClick={() => navigate('/marketing-resources')}
      ></AmountsPaidContentButtonBottom>
    </AmountsPaidContent>
  );
};

export default AmountsPaidTab;
