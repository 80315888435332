/* eslint-disable @typescript-eslint/no-explicit-any */

import { AxiosHeaders, AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { ApiResponse } from '../models/api-response';
import { callExternalApi } from './external-api.service';
import { TransactionDataUpload } from '../models/TransactionDataUpload';

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const createTransactionMetadataUpload = async (
  accessToken: string,
  clientId: string,
  createdByUserId: string,
  fileName: string | null,
  uploadStatus: string | null,
): Promise<ApiResponse<TransactionDataUpload>> => {
  const requestHeaders = new AxiosHeaders();
  requestHeaders.set('Authorization', `Bearer ${accessToken}`);

  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/transaction-data/metadata`,
    method: 'POST',
    headers: requestHeaders as AxiosRequestHeaders,
    data: {
      clientId,
      createdByUserId,
      fileName,
      uploadStatus,
    },
  };

  const { data, status, headers, error } = (await callExternalApi({
    config,
  })) as ApiResponse<TransactionDataUpload>;

  return {
    data,
    status,
    headers,
    error,
  };
};

export async function getAllMetadata(
  accessToken: string,
): Promise<ApiResponse<TransactionDataUpload[]>> {
  const requestHeaders = new AxiosHeaders();
  requestHeaders.set('Authorization', `Bearer ${accessToken}`);

  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/transaction-data/metadata`,
    method: 'GET',
    headers: requestHeaders as AxiosRequestHeaders,
  };

  const { data, status, headers, error } = (await callExternalApi({
    config,
  })) as ApiResponse<TransactionDataUpload[]>;

  return {
    data,
    status,
    headers,
    error,
  };
}

export async function getFileMetadata(
  accessToken: string,
  clientId: string,
  fileName: string,
): Promise<ApiResponse<TransactionDataUpload[]>> {
  const requestHeaders = new AxiosHeaders();
  requestHeaders.set('Authorization', `Bearer ${accessToken}`);

  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/transaction-data/metadata/${fileName}?clientId=${clientId}`,
    method: 'GET',
    headers: requestHeaders as AxiosRequestHeaders,
  };

  const { data, status, headers, error } = (await callExternalApi({
    config,
  })) as ApiResponse<TransactionDataUpload[]>;

  return {
    data,
    status,
    headers,
    error,
  };
}

export async function doesFileMetadataExist(
  accessToken: string,
  clientId: string,
  fileName: string,
): Promise<ApiResponse<boolean>> {
  const requestHeaders = new AxiosHeaders();
  requestHeaders.set('Authorization', `Bearer ${accessToken}`);

  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/transaction-data/metadata/${fileName}?clientId=${clientId}&checkExists=true`,
    method: 'GET',
    headers: requestHeaders as AxiosRequestHeaders,
  };

  const { data, status, headers, error } = (await callExternalApi({
    config,
  })) as ApiResponse<boolean>;

  return {
    data,
    status,
    headers,
    error,
  };
}

export async function cancelPendingAnalysis(
  accessToken: string,
  clientCode: string,
  fileName: string,
): Promise<ApiResponse<boolean>> {
  const requestHeaders = new AxiosHeaders();
  requestHeaders.set('Authorization', `Bearer ${accessToken}`);

  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/transaction-data/metadata/${fileName}`,
    method: 'PATCH',
    headers: requestHeaders as AxiosRequestHeaders,
    data: {
      clientCode: clientCode,
      analysisStatus: 'CANCELLED',
    },
  };

  const { data, status, headers, error } = (await callExternalApi({
    config,
  })) as ApiResponse<boolean>;

  return {
    data,
    status,
    headers,
    error,
  };
}
