const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const monthAbbreviations = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

function getFullMonthName(month: number) {
  return monthNames[month - 1];
}

export function getFullMonthNameFromString(month: string) {
  const monthNumber = parseInt(month, 10);
  return monthNames[monthNumber - 1];
}

export function getLastDayOfMonth(month: string, year: string) {
  const monthNumber = parseInt(month, 10);
  const yearNumber = parseInt(year, 10);
  const lastDate = new Date(yearNumber, monthNumber, 0); // The 0th day of the next month is the last day of the current month

  const monthName = getFullMonthName(monthNumber);
  const day = lastDate.getDate();
  let suffix = 'th';

  if (day % 10 === 1 && day !== 11) {
    suffix = 'st';
  } else if (day % 10 === 2 && day !== 12) {
    suffix = 'nd';
  } else if (day % 10 === 3 && day !== 13) {
    suffix = 'rd';
  }

  return `${monthName} ${day}${suffix}`;
}

/**
 * Takes a createdAt timestamp and returns a formatted date string.
 * The output format is "MMM DD, YYYY".
 *
 * @param createdAt - The timestamp (in milliseconds) of the created date.
 * @returns A formatted date string in the "MMM DD, YYYY" format.
 */
export function uploadedDate(createdAt: number) {
  const d = new Date(createdAt);
  const monthIndex = d.getMonth();
  const monthName = monthAbbreviations[monthIndex];
  const date = d.getDate();
  const year = d.getFullYear();

  return `${monthName} ${date}, ${year}`;
}

export function formatAnalysisDate(month: string, year: string) {
  const monthIndex = parseInt(month, 10) - 1;
  const monthName = monthAbbreviations[monthIndex];

  return `${monthName}, ${year}`;
}

/**
 * Takes a date object and a number of business days, then calculates and returns the new date
 * after adding the specified number of business days.
 *
 * @param date - The initial date object.
 * @param days - The number of business days to be added to the initial date.
 * @returns A new date object after adding the specified number of business days.
 */
function addBusinessDays(date: Date, days: number) {
  let currentDay = date.getDay();
  let remainingDays = days;

  while (remainingDays > 0) {
    currentDay = (currentDay + 1) % 7; // Increment the day
    date.setDate(date.getUTCDate() + 1); // Increment the date

    // If currentDay is not a weekend, decrement remainingDays
    if (currentDay !== 0 && currentDay !== 6) {
      remainingDays--;
    }
  }

  return date;
}

/**
 * Takes a createdAt timestamp and a number of business days, calculates the pending analysis date,
 * and returns a formatted date string. The output format is "MMM DD, YYYY".
 *
 * @param createdAt - The timestamp (in milliseconds) of the created date.
 * @param days - The number of business days to be added to the createdAt date.
 * @returns A formatted pending analysis date string in the "MMM DD, YYYY" format.
 */
export function pendingAnalysisDate(createdAt: number, days: number) {
  const d = new Date(createdAt);
  const newDate = addBusinessDays(d, days);

  const monthIndex = newDate.getUTCMonth();
  const monthName = monthAbbreviations[monthIndex];
  const date = newDate.getUTCDate();
  const year = newDate.getUTCFullYear();

  return `${monthName} ${date}, ${year}`;
}

/**
 * Takes an input date (either as a Date object or a string) and returns a formatted date string.
 * The output format is "MM/DD/YYYY".
 *
 * @param inputDate - The date to be formatted, either as a Date object or a string.
 * @returns A formatted date string in the "MM/DD/YYYY" format.
 */
export function formatDate(inputDate: Date | string) {
  const date = typeof inputDate === 'string' ? new Date(inputDate) : inputDate;
  const month = date.getUTCMonth() + 1; // Months are zero-based
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();
  return `${month}/${day}/${year}`;
}
