import styled from 'styled-components';
import { ButtonText } from '../typography/ButtonText';
import { DesignSystemColorsTheme } from '../themeing/themes';
import { useEffect, useState } from 'react';

const sizeStyles = {
  small: {
    borderRadius: '8px',
    height: '12px',
    minWidth: '83px',
    padding: '8px 16px',
    iconSize: '16',
    iconMargin: '4px',
  },
  medium: {
    borderRadius: '10px',
    height: '14px',
    minWidth: '98px',
    padding: '12px 24px',
    iconSize: '18',
    iconMargin: '6px',
  },
  large: {
    borderRadius: '12px',
    height: '16px',
    minWidth: '113px',
    padding: '16px 28px',
    iconSize: '20',
    iconMargin: '8px',
  },
};

interface SecondaryButtonProps {
  label: string;
  size?: 'small' | 'medium' | 'large';
  onClick?: () => void;
  disabled?: boolean;
  children?: React.ReactNode;
  leftIcon?: string;
  rightIcon?: string;
  iconColor?: string;
}

interface BaseButtonProps extends SecondaryButtonProps {
  keyboardFocused?: boolean;
}

const BaseButton = styled.button<BaseButtonProps>`
  /* Basic styling */
  all: unset;
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;

  /* Styling based on theme properties */
  border: 2px solid ${(props) => props.theme.primary.primary100};
  background-color: ${(props) => props.theme.white};

  /* Typography styling for different states */
  &:hover > p {
    color: ${(props) => props.theme.primary.primaryHover};
  }
  &:active > p {
    color: ${(props) => props.theme.primary.primaryActive};
  }
  &:focus > p,
  &:visited > p,
  &:link > p {
    color: ${(props) => props.theme.primary.primary100};
  }
  &:disabled > p {
    color: ${(props) => props.theme.primary.primary20};
  }

  /* Border color styling for different states */
  &:hover {
    border-color: ${(props) => props.theme.primary.primaryHover};
  }
  &:active {
    border-color: ${(props) => props.theme.primary.primaryActive};
  }
  &:disabled {
    border-color: ${(props) => props.theme.primary.primary20};
  }

  /* Focus styling based on keyboard interaction */
  &:focus {
    ${(props) =>
      props.keyboardFocused &&
      `
      outline: 2px solid ${props.theme.system.info100};
      outline-offset: 2px;
    `}
  }

  /* Sizing based on passed size prop */
  height: ${(props) => (props.size ? sizeStyles[props.size].height : sizeStyles.medium.height)};
  min-width: ${(props) =>
    props.size ? sizeStyles[props.size].minWidth : sizeStyles.medium.minWidth};
  padding: ${(props) => (props.size ? sizeStyles[props.size].padding : sizeStyles.medium.padding)};
  border-radius: ${(props) =>
    props.size ? sizeStyles[props.size].borderRadius : sizeStyles.medium.borderRadius};

  /* Styling for icons and text color */
  --icon-stroke-color: ${(props) =>
    props.iconColor ? props.iconColor : DesignSystemColorsTheme.primary.primary100};
  --text-color: ${(props) => props.theme.primary.primary100};

  &:hover {
    --text-color: ${(props) => props.theme.primary.primaryHover};
    --icon-stroke-color: ${(props) => props.theme.primary.primaryHover};
  }
  &:active {
    --text-color: ${(props) => props.theme.primary.primaryActive};
    --icon-stroke-color: ${(props) => props.theme.primary.primaryActive};
  }

  /* SVG icon styling */
  svg.icon {
    width: ${(props) =>
      props.size ? sizeStyles[props.size].iconSize : sizeStyles.medium.iconSize};
    height: ${(props) =>
      props.size ? sizeStyles[props.size].iconSize : sizeStyles.medium.iconSize};
    fill: none;
    stroke-width: 2.5;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: var(--icon-stroke-color);
  }
`;

const StyledButtonText = styled(ButtonText)<{ size: 'small' | 'medium' | 'large' }>`
  margin: 0 ${(props) => sizeStyles[props.size].iconMargin};
  color: var(--text-color);
`;

const SecondaryButton = ({
  size = 'medium',
  disabled = false,
  leftIcon,
  rightIcon,
  iconColor = DesignSystemColorsTheme.primary.primary100,
  ...props
}: SecondaryButtonProps) => {
  const [keyboardFocused, setKeyboardFocused] = useState(false);

  useEffect(() => {
    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key === 'Tab') {
        setKeyboardFocused(true);
      }
    };

    const handleMousedown = () => {
      setKeyboardFocused(false);
    };

    window.addEventListener('keydown', handleKeydown);
    window.addEventListener('mousedown', handleMousedown);

    return () => {
      window.removeEventListener('keydown', handleKeydown);
      window.removeEventListener('mousedown', handleMousedown);
    };
  }, []);

  const iconSize = sizeStyles[size].iconSize;

  return (
    <BaseButton
      keyboardFocused={keyboardFocused}
      label={props.label}
      size={size}
      onClick={props.onClick}
      disabled={disabled}
    >
      {leftIcon && (
        <svg
          className="icon"
          width={iconSize}
          height={iconSize}
          fill={iconColor ?? 'none'}
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <use href={leftIcon} />
        </svg>
      )}
      <StyledButtonText size={size}>{props.label}</StyledButtonText>
      {rightIcon && (
        <svg
          className="icon"
          width={iconSize}
          height={iconSize}
          fill="none"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <use href={rightIcon} />
        </svg>
      )}
    </BaseButton>
  );
};

export default SecondaryButton;
