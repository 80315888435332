import { ApiResponse } from '../models/api-response';
import { MarketingResource } from '../models/MarketingResource';
import { AxiosHeaders, AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { callExternalApi } from './external-api.service';

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const getMarketingResources = async (
  accessToken: string,
  institutionType: string,
): Promise<ApiResponse<MarketingResource[]>> => {
  const requestHeaders = new AxiosHeaders();
  requestHeaders.set('Authorization', `Bearer ${accessToken}`);

  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/marketing/resources?institutionType=${institutionType}`,
    method: 'GET',
    headers: requestHeaders as AxiosRequestHeaders,
  };
  const { data, status, headers, error } = (await callExternalApi({
    config,
  })) as ApiResponse<MarketingResource[]>;

  return {
    data,
    status,
    headers,
    error,
  };
};

export const getPresignedDownloadUrl = async (
  accessToken: string,
  resourceId: string | undefined,
): Promise<ApiResponse<string>> => {
  const requestHeaders = new AxiosHeaders();
  requestHeaders.set('Authorization', `Bearer ${accessToken}`);

  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/marketing/resources/${resourceId}/download`,
    method: 'GET',
    headers: requestHeaders as AxiosRequestHeaders,
  };

  const { data, status, headers, error } = (await callExternalApi({
    config,
  })) as ApiResponse<string>;

  return {
    data,
    status,
    headers,
    error,
  };
};

export const getPresignedThumbnailUrl = async (
  accessToken: string,
  resourceId: string | undefined,
): Promise<ApiResponse<string>> => {
  const requestHeaders = new AxiosHeaders();
  requestHeaders.set('Authorization', `Bearer ${accessToken}`);

  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/marketing/resources/${resourceId}/thumbnail`,
    method: 'GET',
    headers: requestHeaders as AxiosRequestHeaders,
  };

  const { data, status, headers, error } = (await callExternalApi({
    config,
  })) as ApiResponse<string>;

  return {
    data,
    status,
    headers,
    error,
  };
};
