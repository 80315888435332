export const baseColors = {
  colorBase: '#1E1E1E',
  colorBaseMid: '#7E8286',
  colorBaseTint: '#E6E8EA',
  colorBaseWhite: '#FFFFFF',
  colorBaseInfo: '#75AFE6',
  colorBaseInfoTint: '#E9F3FB',
  colorBasePositive: '#8DD95A',
  colorBasePositiveTint: '#E6F9D7',
  colorBaseNegative: '#FFE45C',
  colorBaseNegativeTint: '#FFFBE6',
  //  Main Theme Colors and Gradients
  colorThemeAccentActive: '#B20262',
  colorThemeAccent: '#5E6ED1',
  themeGradient: {
    gradientColor5: '#FFF2F9',
    gradientColor10: '#FCDCED',
    gradientColor20: '#FAB9DC',
    gradientColor30: '#E577B4',
    gradientColor40: '#D94C99',
    gradientColor50: '#B20262',
    gradientColor60: '#800046',
    gradientColor70: '#4D002A',
  },
  secondaryGradient: {
    gradientColor5: '#F2F4FF',
    gradientColor10: '#C7CDF2',
    gradientColor20: '#A0AAE5',
    gradientColor30: '#5E6ED1',
    gradientColor40: '#283EC9',
    gradientColor50: '#0E25B9',
    gradientColor60: '#061B9F',
    gradientColor70: '#001180',
    gradientColor80: '#000A4D',
  },
  // greys to use
  greyGradient: {
    gradientColor5: '#FAFBFC',
    gradientColor10: '#F0F2F5',
    gradientColor20: '#D8DDE5',
    gradientColor30: '#A9AFB8',
    gradientColor40: '#8994A3',
    gradientColor50: '#626C7A',
    gradientColor60: '#3E4652',
    gradientColor70: '#1E1E1E',
  },
  primary: {
    primary100: '#413DE7',
    primary50: '#A1ABFF',
    primary20: '#DBE2FF',
    primary10: '#EBEFFF',
    primary5: '#F3F6FF',
    primaryHover: '#130FB9',
    primaryActive: '#030086',
  },
  white: '#FFFFFF',
  text: {
    textBlue100: '#051549',
    textBlue80: '#37446D',
    textBlue60: '#697392',
    textBlue40: '#9BA1B6',
    textBlue20: '#CDD0DB',
    textBlue10: '#E6E8ED',
    textBlue5: '#F3F3F6',
  },
  accent: {
    accentGreen100: '#1CA293',
    accentGreen50: '#8DD0C9',
    accentGreen20: '#D2ECE9',
    accentGreen5: '#EBF7F6',
    accentBlue100: '#009EBE',
    accentBlue50: '#80CADF',
    accentBlue20: '#CCE9F2',
    accentBlue5: '#F2F9FC',
    accentRed100: '#E93108',
    accentRed50: '#F49883',
    accentRed20: '#FBD6CE',
    accentRed5: '#FEF5F3',
    accentPink100: '#CA086B',
    accentPink50: '#E483B5',
    accentPink20: '#F4CEE1',
    accentPink5: '#FCF3F8',
    accentPurple100: '#9952E0',
    accentPurple50: '#CCA9F0',
    accentPurple20: '#EBDCF9',
    accentPurple5: '#FAF4FF',
  },
  system: {
    info100: '#148EFF',
    info50: '#8AC7FF',
    info20: '#D8F1FF',
    info5: '#F3F9FF',
    infoHover: '#0068C8',
    infoActive: '#004E94',
    error100: '#E31837',
    error50: '#F18898',
    error20: '#F9D1D7',
    error5: '#FEF8F9',
    errorHover: '#BD001D',
    errorActive: '#8A0015',
    warning100: '#FB5E2A',
    warning50: '#FBAE95',
    warning20: '#FEDFD4',
    warning5: '#FFF7F4',
    warningHover: '#E53A00',
    warningActive: '#C72800',
    success100: '#43A269',
    success50: '#A1D0B4',
    success20: '#D9ECE1',
    success5: '#F7FCF9',
    successHover: '#00953B',
    successActive: '#00702D',
  },
};

export const DesignSystemColorsTheme = {
  primary: {
    primary100: '#413DE7',
    primary50: '#A1ABFF',
    primary20: '#DBE2FF',
    primary10: '#EBEFFF',
    primary5: '#F3F6FF',
    primaryHover: '#130FB9',
    primaryActive: '#030086',
  },
  white: '#FFFFFF',
  text: {
    textBlue100: '#051549',
    textBlue80: '#37446D',
    textBlue60: '#697392',
    textBlue40: '#9BA1B6',
    textBlue20: '#CDD0DB',
    textBlue10: '#E6E8ED',
    textBlue5: '#F3F3F6',
  },
  accent: {
    accentGreen100: '#1CA293',
    accentGreen50: '#8DD0C9',
    accentGreen20: '#D2ECE9',
    accentGreen5: '#EBF7F6',
    accentBlue100: '#009EBE',
    accentBlue50: '#80CADF',
    accentBlue20: '#CCE9F2',
    accentBlue5: '#F2F9FC',
    accentRed100: '#E93108',
    accentRed50: '#F49883',
    accentRed20: '#FBD6CE',
    accentRed5: '#FEF5F3',
    accentPink100: '#CA086B',
    accentPink50: '#E483B5',
    accentPink20: '#F4CEE1',
    accentPink5: '#FCF3F8',
    accentPurple100: '#9952E0',
    accentPurple50: '#CCA9F0',
    accentPurple20: '#EBDCF9',
    accentPurple5: '#FAF4FF',
  },
  system: {
    info100: '#148EFF',
    info50: '#8AC7FF',
    info20: '#D8F1FF',
    info5: '#F3F9FF',
    infoHover: '#0068C8',
    infoActive: '#004E94',
    error100: '#E31837',
    error50: '#F18898',
    error20: '#F9D1D7',
    error5: '#FEF8F9',
    errorHover: '#BD001D',
    errorActive: '#8A0015',
    warning100: '#FB5E2A',
    warning50: '#FBAE95',
    warning20: '#FEDFD4',
    warning5: '#FFF7F4',
    warningHover: '#E53A00',
    warningActive: '#C72800',
    success100: '#43A269',
    success50: '#A1D0B4',
    success20: '#D9ECE1',
    success5: '#F7FCF9',
    successHover: '#00953B',
    successActive: '#00702D',
  },
};
