import { BodyText } from '../typography/BodyText';
import styled from 'styled-components';

interface CancelAnalysisModalProps {
  fileName: string;
}

const StyledContentContainer = styled.div`
  margin-top: 32px;
`;

export function CancelAnalysisModalContent({ fileName }: CancelAnalysisModalProps) {
  return (
    <StyledContentContainer>
      <BodyText size="large">
        Cancelling your analysis will stop any of the data analysis that the Nickels team is
        currently conducting on <b>{fileName}.</b>
        <br />
        <br />
        Are you sure you want to cancel?
      </BodyText>
    </StyledContentContainer>
  );
}
