import styled from 'styled-components';
import { DesignSystemColorsTheme } from '../../components/themeing/themes';

interface PathProps {
  active: boolean;
  hovered: boolean;
}

const StyledPath = styled.path<{ active: boolean; hovered: boolean }>`
  fill: ${({ active, hovered }) => {
    if (active && hovered) {
      return DesignSystemColorsTheme.primary.primaryHover;
    } else if (active && !hovered) {
      return DesignSystemColorsTheme.primary.primary100;
    } else if (!active && hovered) {
      return DesignSystemColorsTheme.text.textBlue100;
    } else {
      return DesignSystemColorsTheme.text.textBlue80;
    }
  }};
`;

export const marketingResourceIcon = ({ active, hovered }: PathProps) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <StyledPath
        hovered={hovered}
        active={active}
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.99999 5C6.46956 5 5.96085 5.21071 5.58578 5.58578C5.21071 5.96085 5 6.46956 5 6.99999V17.5858L7.29289 15.2929C7.48042 15.1053 7.73478 15 7.99999 15H17C17.5304 15 18.0391 14.7893 18.4142 14.4142C18.7893 14.0391 19 13.5304 19 13V6.99999C19 6.46956 18.7893 5.96085 18.4142 5.58578C18.0391 5.21071 17.5304 5 17 5H6.99999ZM4.17157 4.17157C4.92172 3.42143 5.93913 3 6.99999 3H17C18.0608 3 19.0783 3.42143 19.8284 4.17157C20.5785 4.92172 21 5.93913 21 6.99999V13C21 14.0608 20.5785 15.0783 19.8284 15.8284C19.0783 16.5786 18.0608 17 17 17H8.4142L4.7071 20.7071C4.42111 20.9931 3.99099 21.0786 3.61732 20.9239C3.24364 20.7691 3 20.4044 3 20V6.99999C3 5.93913 3.42143 4.92172 4.17157 4.17157Z"
        fill="#37446D"
      />
    </svg>
  );
};
