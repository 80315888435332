import React from 'react';
import * as Select from '@radix-ui/react-select';
import styled from 'styled-components';
import { BodyText } from '../typography/BodyText';
import { DesignSystemColorsTheme } from '../themeing/themes';

export type Option = {
  value: string;
  label: string;
};

interface SingleSelectDropdownProps {
  labelLeft?: string;
  labelRight?: string;
  labelIcon?: string;
  options: Option[];
  disabled?: boolean;
  filter?: (option: Option) => boolean;
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  width?: string;
  tooltip?: React.ReactNode;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const LabelsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const RightLabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledTrigger = styled(Select.Trigger)<{ disabled?: boolean; width?: string }>(
  ({ disabled }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px 12px',
    gap: '8px',
    width: '244px',
    height: '42px',
    background: `${
      disabled ? DesignSystemColorsTheme.text.textBlue5 : DesignSystemColorsTheme.white
    }`,
    border: `1px solid ${
      disabled
        ? DesignSystemColorsTheme.primary.primary20
        : DesignSystemColorsTheme.primary.primary50
    }`,
    borderRadius: '12px',
    cursor: disabled ? 'not-allowed' : 'pointer',
    textAlign: 'start',
  }),
);

const StyledContent = styled(Select.Content)<{ width?: string }>(() => ({
  marginTop: '4px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '244px',
  overflow: 'hidden',
  background: 'white',
  border: `1px solid ${DesignSystemColorsTheme.primary.primary20}`,
  boxShadow: '0px 10px 20px rgba(65, 61, 231, 0.04), 0px 4px 15px rgba(85, 85, 88, 0.02)',
  borderRadius: '12px',
  zIndex: 2,
}));

const StyledOption = styled(({ ...props }) => <Select.Item {...props} />)<{
  disabled?: boolean;
}>(({ disabled }) => ({
  width: '244px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  paddingLeft: '16px',
  paddingRight: '16px',
  paddingTop: '8px',
  paddingBottom: '8px',

  ':focus': {
    outline: 'none',
  },

  ':hover': {
    //border: `1px solid red`,
    backgroundColor: DesignSystemColorsTheme.primary.primary5,
  },
  ...(disabled && {
    opacity: 0.5,
    pointerEvents: 'none',
    cursor: 'not-allowed',
  }),
}));

const SingleSelectDropdown: React.FC<SingleSelectDropdownProps> = ({
  labelLeft,
  labelRight,
  labelIcon,
  options,
  disabled = false,
  filter,
  placeholder,
  value,
  onChange,
  width,
  tooltip,
}) => {
  const selectedOption = options.find((option) => option.value === value);

  return (
    <Container>
      {(labelLeft || labelRight || labelIcon) && (
        <LabelsRow>
          {labelLeft && <BodyText size="medium">{labelLeft}</BodyText>}
          <RightLabelContainer>
            {labelRight && (
              <BodyText size="medium" color={DesignSystemColorsTheme.text.textBlue60}>
                {labelRight}
              </BodyText>
            )}
            {tooltip && <div>{tooltip}</div>}
          </RightLabelContainer>
        </LabelsRow>
      )}

      <Select.Root
        value={value}
        onValueChange={(newValue) => {
          onChange(newValue);
        }}
      >
        <StyledTrigger disabled={disabled} width={width}>
          <div>
            {selectedOption ? (
              selectedOption.label
            ) : (
              <BodyText size="large" color={DesignSystemColorsTheme.text.textBlue60}>
                {placeholder}
              </BodyText>
            )}
          </div>
          <svg
            width="16px"
            height="16px"
            fill="none"
            stroke={DesignSystemColorsTheme.text.textBlue80}
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <use href="/feather-sprite.svg#chevron-down" />
          </svg>
        </StyledTrigger>
        <StyledContent position="popper" width={width}>
          {options.map((option) => {
            const isOptionDisabled = filter && filter(option);

            return (
              <StyledOption
                key={option.value}
                value={option.value}
                disabled={isOptionDisabled}
                style={{ cursor: disabled || isOptionDisabled ? 'not-allowed' : 'default' }}
                title={option.label}
              >
                <BodyText size="medium">
                  {option.label.length > 28 ? option.label.slice(0, 28 - 1) + '...' : option.label}
                </BodyText>
              </StyledOption>
            );
          })}
        </StyledContent>
      </Select.Root>
    </Container>
  );
};

export default SingleSelectDropdown;
