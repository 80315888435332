import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Menu } from './navigation/Menu';

const collapsedWidth = '8rem';
const expandedWidth = '21rem';

const StyledPage = styled.div`
  display: flex;
  padding: 0;
  box-sizing: border-box;
`;

const ContentContainer = styled.div<{ menuCollapsed: boolean }>`
  margin-left: ${({ menuCollapsed }) => (menuCollapsed ? collapsedWidth : expandedWidth)};
  padding-right: 40px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background: white;
  transition: 0.5s;
`;

type Props = {
  children?: React.ReactNode;
};

export const PageLayout: React.FC<Props> = ({ children }) => {
  const [menuCollapsed, setMenuCollapsed] = useState(() =>
    JSON.parse(localStorage.getItem('menuCollapsed') || 'true'),
  );

  useEffect(() => {
    localStorage.setItem('menuCollapsed', JSON.stringify(menuCollapsed));
  }, [menuCollapsed]);

  return (
    <StyledPage>
      <Menu menuCollapsed={menuCollapsed} setMenuCollapsed={setMenuCollapsed} />
      <ContentContainer menuCollapsed={menuCollapsed}>{children}</ContentContainer>
    </StyledPage>
  );
};
