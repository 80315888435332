import styled from 'styled-components';
import { DesignSystemColorsTheme } from '../themeing/themes';
import { BodyText } from '../typography/BodyText';

interface DuplicateFileModalProps {
  duplicateFiles: string[];
}

const StyledContentContainer = styled.div`
  margin-top: 32px;
`;

export function DuplicateFileModalContent({ duplicateFiles }: DuplicateFileModalProps) {
  const duplicateFileItems = duplicateFiles.map((d) => (
    <li key={d}>
      <BodyText size="large" color={DesignSystemColorsTheme.system.error100}>
        {d}
      </BodyText>
    </li>
  ));

  return (
    <StyledContentContainer>
      <BodyText size="large">
        The files listed below have been previously uploaded. Please remove the duplicate files, or
        rename the files if you believe they should be uploaded.
      </BodyText>
      <ul>{duplicateFileItems}</ul>
    </StyledContentContainer>
  );
}
