import styled from 'styled-components';

export const Title = styled.div`
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  padding-top: 56px;
  padding-bottom: 4px;
`;

export const Subtitle = styled.div`
  font-size: 16px;
  line-height: 24px;
`;
