import { useAuth0 } from '@auth0/auth0-react';
import { FC } from 'react';
import styled from 'styled-components';
import { PageLayout } from '../components/PageLayout';

const StyledPageLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100%;
  width: 100%;
`;

const StyledPageLayoutContent = styled.div`
  flex: 1;
  flex-basis: auto;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;

  margin-top: 8rem;
  max-width: 120rem;
  width: 100%;
`;

// The callback-page.js component will only render the navigation bar and an empty content container to help
// you create a smooth transition between a route with no content, /callback, to a route with content, such as the /protected page.
// It's just an illusion
// TODO: add nav bar here (or something that is consistent with the whole app)

const CallbackPage: FC = () => {
  const { error } = useAuth0();

  if (error) {
    return (
      <PageLayout>
        <StyledPageLayout>
          <h1 id="page-title" className="content__title">
            Error
          </h1>
          <div className="content__body">
            <p id="page-description">
              <span>{error.message}</span>
            </p>
          </div>
        </StyledPageLayout>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <StyledPageLayoutContent />
    </PageLayout>
  );
};

export { CallbackPage };
