import styled from 'styled-components';
import { DesignSystemColorsTheme } from '../components/themeing/themes';

interface PathProps {
  active: boolean;
  hovered: boolean;
}

const StyledPath = styled.path<{ active: boolean; hovered: boolean }>`
  fill: ${({ active, hovered }) => {
    if (active && hovered) {
      return DesignSystemColorsTheme.primary.primaryHover;
    } else if (active && !hovered) {
      return DesignSystemColorsTheme.primary.primary100;
    } else if (!active && hovered) {
      return DesignSystemColorsTheme.text.textBlue100;
    } else {
      return DesignSystemColorsTheme.text.textBlue80;
    }
  }};
`;

export const LogoutIcon = ({ active, hovered }: PathProps) => (
  <>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <StyledPath
        hovered={hovered}
        active={active}
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.23529 21V3H3V21H5.23529ZM16.0846 8.29554L13.522 10.875H21.9997V13.125H13.5219L16.0846 15.7045L14.504 17.2955L10.0383 12.8004L10.0334 12.7955C9.88422 12.6454 9.78603 12.4633 9.73883 12.2712C9.69972 12.112 9.69561 11.9458 9.72651 11.7852C9.76743 11.5724 9.86972 11.3693 10.0334 11.2045L10.0384 11.1996L14.504 6.70455L16.0846 8.29554Z"
        fill="#37446D"
      />
    </svg>
  </>
);
