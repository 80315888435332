import { createTransactionMetadataUpload } from '../../services/transaction-upload.service';
import { useState } from 'react';
import { User } from '@auth0/auth0-react';
import { MinimumDataSection } from '../MinimumDataSection';
import { FileUploader } from 'react-drag-drop-files';
import { DropZone } from '../DropZone';
import styled from 'styled-components';
import axios from 'axios';
import * as TransactionDataUploadService from '../../services/transaction-upload.service';
import { AutoUploadModalContent } from '../modals/AutoUploadModalContent';
import { DuplicateFileModalContent } from '../modals/DuplicateFileModalContent';
import { HeaderText } from '../typography/HeaderText';
import { BodyText } from '../typography/BodyText';
import AdditionalButton from '../buttons/AdditionalButton';
import Card from '../outlines/Card';
import { DesignSystemColorsTheme } from '../themeing/themes';
import FileUploadProgressBar from './FileUploadProgressBar';
import { ButtonText } from '../typography/ButtonText';
import Modal from '../../components/Modal';
import PrimaryButton from '../buttons/PrimaryButton';
import TertiaryButton from '../buttons/TertiaryButton';
import { ConfirmUploadModalContent } from '../modals/ConfirmUploadModalConent';
import redAlert from '../../images/redAlert.svg';
import blueAlert from '../../images/blueAlert.svg';

const StyledCard = styled(Card)`
  padding: 40px;
  box-shadow: 0px 8px 10px rgba(65, 61, 231, 0.03), 0px 2px 5px rgba(85, 85, 88, 0.02);
  border-radius: 24px;
  margin-bottom: 36px;

  border: ${(props) => `1px solid ${props.theme.primary.primary20}`};
`;

const FileUploadContainer = styled.div`
  width: 100%;
  // this comes from this neat website: https://kovart.github.io/dashed-border-generator/
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23A1ABFFFF' stroke-width='2' stroke-dasharray='1%2c2%2c10' stroke-dashoffset='24' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 16px;
  margin-top: 32px;
`;

const StagedFilesContainer = styled.div`
  margin-top: 32px;
`;

const StagedFileWrapper = styled.div`
  display: flex;
  padding: 4px 12px;
  align-items: center;
  gap: 40px;
  align-self: stretch;
  border-bottom: 1px solid #dbe2ff;
`;

const StagedFileNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
`;

const StagedFileRemoveButtonContainer = styled.div`
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

const SubmitAnalysisButton = styled.div`
  margin-top: 40px;
  margin-bottom: 32px;
  float: right;
`;

const AutoUploadTitle = styled.div`
  padding-top: 32px;
  margin-bottom: 12px;
`;

const AutoUploadContainer = styled.div``;

const AutoUploadDescriptionContainer = styled.div`
  display: flex;
  align-items: center;
`;

const DescriptionContainer = styled.div`
  padding-top: 16px;
`;

const MarketingCard = styled(Card)`
  padding: 15px;
  box-shadow: 0px 12px 35px rgba(20, 142, 255, 0.05), 0px 6px 25px rgba(85, 85, 88, 0.03);
  display: flex;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  font: Nunito Sans;
  max-width: 100%;
  height: auto;
`;

const Icon = styled.div`
  display: flex;
  align-items: start;
  padding-right: 12px;
`;

const CardContainer = styled.div`
  h1 {
    margin-bottom: 8px;
  }
  p {
    display: inline;
  }
  a {
    display: inline;
  }
`;

async function getPresignedUrl(
  apiUrl: string,
  accessToken: string,
  uploadId: string,
  partNumber: number,
  key: string,
) {
  const response = await axios.get(
    `${apiUrl}/api/uploads/s3/multipart-upload/${uploadId}/${partNumber}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        key: key,
      },
    },
  );
  return response.data.url;
}

type UploadFile = {
  file: File;
  uploadProgress: number;
  uploadComplete: boolean;
  uploadSuccessful: boolean | undefined;
};

interface UploadDataProps {
  accessToken: string;
  nickelsUser: User | null;
  clientCode: string | undefined;
  onUploadSuccess: () => void;
}

export function UploadAnalysisData({
  accessToken,
  nickelsUser,
  clientCode,
  onUploadSuccess,
}: UploadDataProps) {
  const [files, setFiles] = useState<UploadFile[]>([]);
  const [isUploadComplete, setIsUploadComplete] = useState<boolean>(false); // does not determine succes, only if upload operation is done or not
  const [isUploadInitiated, setIsUploadInitiated] = useState<boolean>(false);
  const [showAutoUploadDetailsModal, setShowAutoUploadDetailsModal] = useState<boolean>(false);
  const [showConfirmUploadModal, setShowConfirmUploadModal] = useState<boolean>(false);
  const [showDuplicateFileModal, setShowDuplicateFileModal] = useState<boolean>(false);
  const [duplicateFileList, setDuplicateFileList] = useState<string[]>([]);
  const [estimatedUploadTime, setEstimatedUploadTime] = useState<number>(0);

  const allowedFileTypes = ['CSV', 'ZIP', 'GPG'];

  // The partsCount determines the estimated upload time
  // After testing we found that:
  // 35 parts takes under 1 minute to upload
  // 115 parts takes over 5 minutes to upload
  const underOneMinute = 35;
  const overFiveMinutes = 115;

  const addFiles = (filesToAdd: FileList) => {
    const newFiles: UploadFile[] = [...files];

    Array.from(filesToAdd).forEach((newFile) => {
      newFiles.push({
        file: newFile,
        uploadProgress: 0,
        uploadComplete: false,
        uploadSuccessful: undefined,
      });
    });

    setFiles(newFiles);
  };

  const removeFile = (fileNameToRemove: string) => {
    const newFiles: UploadFile[] = [...files];

    newFiles.splice(
      newFiles.findIndex((upload) => upload.file.name === fileNameToRemove),
      1,
    );

    setFiles(newFiles);
  };

  const validateFileNames = async (filesToUpload: UploadFile[]) => {
    const existingFiles: string[] = [];
    for (const upload of filesToUpload) {
      const doesFileAlreadyExist = (
        await TransactionDataUploadService.doesFileMetadataExist(
          accessToken,
          nickelsUser?.clientId,
          upload.file.name,
        )
      ).data;

      if (doesFileAlreadyExist) {
        existingFiles.push(upload.file.name);
      }
    }
    setDuplicateFileList(existingFiles);
    return existingFiles;
  };

  const uploadFiles = async (filesToUpload: UploadFile[]) => {
    // call validateFileNames() here to verify that user is not attempting to upload duplicate filenames
    const existingFiles = await validateFileNames(filesToUpload);

    if (existingFiles.length !== 0) {
      setShowDuplicateFileModal(true);
      return;
    }

    setIsUploadInitiated(true);
    let successfulUploads = 0;

    filesToUpload.forEach(async (upload, index) => {
      if (upload.uploadComplete && upload.uploadSuccessful) {
        // We have already successfully uploaded these files.
        // This could happen if some fail, and some succeed.
        return;
      }

      const s3FilePath = `${clientCode}/${upload.file.name.replace(/\.[^/.]+$/, '')}/${
        upload.file.name
      }`;

      try {
        // Initialize the multipart upload
        const initResponse = await axios.post(
          `${process.env.REACT_APP_API_SERVER_URL}/api/uploads/s3/multipart-upload`,
          { filename: s3FilePath },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        const { uploadId, key } = initResponse.data;

        // Split the file into parts
        const chunkSize = 5 * 1024 * 1024; // 5MB
        const partsCount = Math.ceil(upload.file.size / chunkSize);

        setEstimatedUploadTime(partsCount);
        const uploadedParts = [];

        for (let partNumber = 1; partNumber <= partsCount; partNumber++) {
          const start = (partNumber - 1) * chunkSize;
          const end = partNumber * chunkSize;
          const filePart = upload.file.slice(start, end);

          const presignedUrl = await getPresignedUrl(
            `${process.env.REACT_APP_API_SERVER_URL}`,
            accessToken,
            uploadId,
            partNumber,
            s3FilePath,
          );

          const uploadResponse = await axios.put(presignedUrl, filePart);

          uploadedParts.push({
            ETag: uploadResponse.headers.etag,
            PartNumber: partNumber,
          });

          const progress = Math.round((uploadedParts.length / partsCount) * 100);
          const updatedFile = [...files];
          updatedFile[index].uploadProgress = progress;
          setFiles(updatedFile);
        }

        // Complete the multipart upload
        await axios.post(
          `${process.env.REACT_APP_API_SERVER_URL}/api/uploads/s3/multipart-upload/complete`,
          {
            key: key,
            uploadId: uploadId,
            parts: uploadedParts,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        // Update the file upload state
        const updatedFile = [...files];
        updatedFile[index].uploadComplete = true;
        updatedFile[index].uploadSuccessful = true;
        setFiles(updatedFile);

        createTransactionMetadataUpload(
          accessToken,
          nickelsUser?.clientId,
          nickelsUser?.id,
          upload.file.name,
          'SUCCESS',
        );

        setIsUploadComplete(files.every((uploadedFile) => uploadedFile.uploadComplete));

        successfulUploads++;
        if (successfulUploads === files.length) {
          onUploadSuccess();
        }
      } catch (error) {
        // Update the file upload state
        const updatedFile = [...files];
        updatedFile[index].uploadComplete = true;
        updatedFile[index].uploadSuccessful = false;
        setFiles(updatedFile);

        setIsUploadComplete(files.every((uploadedFile) => uploadedFile.uploadComplete));
      }
    });
  };

  // Number of minutes to upload = estimatedUploadTime / underOneMinute * a constant factor of 1.5
  const minutesToUpload = Math.ceil((estimatedUploadTime / underOneMinute) * 1.5);

  return (
    <>
      <Modal
        open={showAutoUploadDetailsModal}
        content={<AutoUploadModalContent />}
        header={'Auto upload details'}
        closeHandler={setShowAutoUploadDetailsModal}
      />
      <Modal
        open={showConfirmUploadModal}
        content={<ConfirmUploadModalContent />}
        header={'Please confirm'}
        closeHandler={setShowConfirmUploadModal}
        buttons={[
          <PrimaryButton
            onClick={() => {
              setShowConfirmUploadModal(false);
              uploadFiles(files);
            }}
            size="large"
            key={1}
            label="Continue"
          />,
          <TertiaryButton
            onClick={() => setShowConfirmUploadModal(false)}
            size="large"
            key={3}
            label="Cancel"
          />,
        ]}
      />
      <Modal
        open={showDuplicateFileModal}
        content={<DuplicateFileModalContent duplicateFiles={duplicateFileList} />}
        header={'Duplicate files detected'}
        closeHandler={setShowDuplicateFileModal}
      />
      <StyledCard>
        <HeaderText size="medium">Upload your data for analysis</HeaderText>
        <DescriptionContainer>
          <BodyText size="large">
            If this is your first analysis, include 12 months of data. For subsequent analyses,
            include data for the months that have transpired since the last data file concluded.
          </BodyText>
        </DescriptionContainer>

        <MinimumDataSection />
        <FileUploadContainer>
          <FileUploader
            children={<DropZone />}
            dropMessageStyle={{
              backgroundColor: DesignSystemColorsTheme.primary.primary5,
              border: 'none',
            }}
            hoverTitle=" "
            multiple={true}
            handleChange={(e: FileList) => addFiles(e)}
            name="file"
            types={allowedFileTypes}
          />
        </FileUploadContainer>
        {files && <StagedFilesContainer />}
        {files &&
          files.map((upload) => {
            return isUploadInitiated ? (
              <FileUploadProgressBar
                uploadProgress={upload.uploadProgress}
                fileName={upload.file.name}
              ></FileUploadProgressBar>
            ) : (
              <StagedFileWrapper>
                <StagedFileNameContainer>
                  <ButtonText size="large">{upload.file.name}</ButtonText>
                </StagedFileNameContainer>
                <StagedFileRemoveButtonContainer>
                  <TertiaryButton
                    size="small"
                    label="Remove"
                    leftIcon="/feather-sprite.svg#trash-2"
                    onClick={() => removeFile(upload.file.name)}
                  />
                </StagedFileRemoveButtonContainer>
              </StagedFileWrapper>
            );
          })}
        {estimatedUploadTime > underOneMinute && estimatedUploadTime <= overFiveMinutes && (
          <MarketingCard
            width={'100%'}
            height={'102px'}
            borderColor={DesignSystemColorsTheme.system.info100}
            background={DesignSystemColorsTheme.system.info5}
            dismissable={true}
          >
            <Icon>
              <img src={blueAlert} />
            </Icon>
            <div>
              <CardContainer>
                <HeaderText size="small">
                  Estimated upload time: {minutesToUpload} minutes
                </HeaderText>
                Based on this file’s size, we estimate this file upload could take {minutesToUpload}{' '}
                minutes to complete. Please do not close this page, or put your computer to sleep
                while the upload is in process.
              </CardContainer>
            </div>
          </MarketingCard>
        )}
        {estimatedUploadTime > overFiveMinutes && (
          <MarketingCard
            width={'100%'}
            height={'102px'}
            borderColor={DesignSystemColorsTheme.system.warning100}
            background={DesignSystemColorsTheme.system.warning5}
            dismissable={true}
          >
            <Icon>
              <img src={redAlert} />
            </Icon>
            <div>
              <CardContainer>
                <HeaderText size="small">
                  Estimated upload time: {minutesToUpload} minutes
                </HeaderText>
                Based on this file’s size, we estimate this file upload could take {minutesToUpload}{' '}
                minutes to complete. Please do not close this page, or put your computer to sleep
                while the upload is in process.
              </CardContainer>
            </div>
          </MarketingCard>
        )}
        {files.length > 0 && (
          <SubmitAnalysisButton>
            <PrimaryButton
              label="Submit for analysis"
              leftIcon="/feather-sprite.svg#send"
              disabled={isUploadInitiated && !isUploadComplete}
              onClick={() => setShowConfirmUploadModal(true)}
            />
          </SubmitAnalysisButton>
        )}

        <AutoUploadContainer>
          <AutoUploadTitle>
            <HeaderText size="small">Enable auto upload</HeaderText>
          </AutoUploadTitle>
          <AutoUploadDescriptionContainer>
            <BodyText size="medium">
              You can also automatically send your checking account data to Nickels every month.{' '}
            </BodyText>
            <AdditionalButton
              size="small"
              label="Learn more."
              onClick={() => {
                setShowAutoUploadDetailsModal(true);
              }}
            />
          </AutoUploadDescriptionContainer>
        </AutoUploadContainer>
      </StyledCard>
    </>
  );
}
