import styled from 'styled-components';

export const ModalContainer = styled.div`
  max-width: 664px;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 32px;
  padding-right: 32px;
`;

export const ModalText = styled.div`
  padding-top: 32px;
  padding-bottom: 32px;
`;

export const StyledEmail = styled.p`
  all: unset;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-decoration: underline;
  color: ${(props) => props.theme.primary.primary100};
`;

export const StyledCloseButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledCloseButton = styled.img`
  background: none;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colorBaseGray};
`;
