import { useEffect, useState } from 'react';
import { TransactionDataUpload } from '../../models/TransactionDataUpload';
import { formatDate, pendingAnalysisDate } from '../../utils/date';
import { cancelPendingAnalysis } from '../../services/transaction-upload.service';
import { useClientStore } from '../../stores/clientStore';
import Modal from '../../components/Modal';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import TertiaryButton from '../../components/buttons/TertiaryButton';
import { CancelAnalysisModalContent } from '../../components/modals/CancelAnalysisModalContent';
import { RemoveFileModalContent } from '../../components/modals/RemoveFileModalContent';
import { CustomColumn, CustomTable } from '../../components/tables/CustomTable';
import styled from 'styled-components';
import Card from '../../components/outlines/Card';

const StyledCard = styled(Card)`
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  box-shadow: 0px 8px 10px rgba(65, 61, 231, 0.03), 0px 2px 5px rgba(85, 85, 88, 0.02);
  border-radius: 24px;
  margin-top: 48px;

  border: ${(props) => `1px solid ${props.theme.primary.primary20}`};
`;

const StyledCancelButton = styled.div`
  display: flex;
  margin-left: -50px;
`;

const StyledRemoveButton = styled.div`
  display: flex;
  margin-left: -46px;
`;

const TruncateText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 360px;
`;

interface DataUploadHistoryProps {
  accessToken: string;
  previousUploads: TransactionDataUpload[];
}

export default function DataUploadHistory({
  accessToken,
  previousUploads,
}: DataUploadHistoryProps): JSX.Element {
  const [showConfirmCancelModal, setShowConfirmModal] = useState<boolean>(false);
  const [showRemoveUploadModal, setShowRemoveUploadModal] = useState<boolean>(false);
  const [fileNameToCancel, setFileNameToCancel] = useState<string>('');

  useEffect(() => {
    const getUserInfo = async () => {
      previousUploads.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
    };

    getUserInfo();
  }, [accessToken, previousUploads]);

  const userClient = useClientStore((state) => state.client);

  const columns: CustomColumn[] = [
    {
      Header: 'File name',
      accessor: 'fileName',
      align: 'left',
      width: '360px',
    },
    {
      Header: 'Date range',
      accessor: 'dateRange',
      align: 'left',
      width: '240px',
    },
    {
      Header: 'Status',
      accessor: 'analysisStatusDisplay',
      align: 'left',
      width: '240px',
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      align: 'left',
      width: '116px',
    },
  ];

  const data = previousUploads.map((upload) => {
    const { id, fileName, analysisStatus, startDate, endDate, createdAt } = upload;

    let analysisStatusDisplay = `Pending (~${pendingAnalysisDate(createdAt, 5)})`;
    let isCompleted = false;
    if (analysisStatus === 'COMPLETED') {
      isCompleted = true;
      analysisStatusDisplay = 'Complete';
    }

    let isCancelled = false;
    if (analysisStatus === 'CANCELLED') {
      isCancelled = true;
      analysisStatusDisplay = 'Cancelled';
    }

    let dateRange;
    if (startDate && endDate) {
      dateRange = `${formatDate(startDate)} - ${formatDate(endDate)}`;
    } else if (isCancelled) {
      dateRange = '-';
    } else {
      dateRange = `Verifying`;
    }

    const actions = isCancelled ? (
      <div></div>
    ) : (
      <>
        {isCompleted ? (
          <StyledRemoveButton>
            <TertiaryButton
              size="medium"
              label="Remove"
              onClick={() => {
                setShowRemoveUploadModal(true);
              }}
            />
          </StyledRemoveButton>
        ) : (
          <StyledCancelButton>
            <TertiaryButton
              size="medium"
              label="Cancel"
              onClick={() => {
                setFileNameToCancel(fileName || '');
                setShowConfirmModal(true);
              }}
            />
          </StyledCancelButton>
        )}
      </>
    );

    return {
      key: id,
      fileName: <TruncateText>{fileName}</TruncateText>,
      dateRange,
      analysisStatusDisplay,
      actions,
    };
  });

  return (
    <>
      <Modal
        open={showRemoveUploadModal}
        content={<RemoveFileModalContent />}
        header={'Remove file'}
        closeHandler={setShowRemoveUploadModal}
      />
      <Modal
        open={showConfirmCancelModal}
        content={<CancelAnalysisModalContent fileName={fileNameToCancel} />}
        header={'Please confirm'}
        closeHandler={setShowConfirmModal}
        buttons={[
          <PrimaryButton
            onClick={() => setShowConfirmModal(false)}
            size="large"
            key={1}
            label="Don't cancel"
          />,
          <TertiaryButton
            onClick={() => {
              setShowConfirmModal(false);
              if (userClient) {
                cancelPendingAnalysis(accessToken, userClient.clientCode, fileNameToCancel).then(
                  () => {
                    window.location.reload(); // should ideally refetch jobs instead of page reload
                  },
                );
              }
            }}
            size="large"
            key={3}
            label="Cancel analysis"
          />,
        ]}
      />
      <StyledCard>
        <CustomTable columns={columns} data={data} />
      </StyledCard>
    </>
  );
}
