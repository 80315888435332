import React from 'react';
import styled from 'styled-components';
import { BodyText } from '../typography/BodyText';
import { ButtonText } from '../typography/ButtonText';
import { DesignSystemColorsTheme } from '../themeing/themes';

type UploadProps = {
  fileName: string;
  uploadProgress: number;
};

const Wrapper = styled.div`
  display: flex;
  padding: 4px 12px;
  align-items: center;
  align-self: stretch;
  width: 100%;
  justify-content: space-between;
`;

const FileName = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 50%;
`;

const ProgressBarContainer = styled.div`
  display: flex;
  width: 45%;
  height: 18px;
  border-radius: 100px;
  border: 1px solid #dbe2ff;
  background: #ffffff;
  overflow: hidden;
`;

const ProgressBar = styled.div<{ progress: number }>`
  width: ${(props) => props.progress}%;
  height: 100%;
  flex-shrink: 0;
  border-radius: 100px;
  background: #413de7;
`;

const ProgressNumber = styled.div`
  display: flex;
  padding: 8px 1px;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  width: 5%;
`;

const FileUploadProgressBar: React.FC<UploadProps> = ({ fileName, uploadProgress }) => (
  <Wrapper>
    <FileName>
      <ButtonText size="large">{fileName}</ButtonText>
    </FileName>

    <ProgressBarContainer>
      <ProgressBar progress={uploadProgress} />
    </ProgressBarContainer>

    <ProgressNumber>
      {uploadProgress < 100 ? (
        <BodyText size="small" color={DesignSystemColorsTheme.primary.primary100}>
          {uploadProgress}%
        </BodyText>
      ) : (
        <svg
          style={{
            opacity: uploadProgress === 100 ? 1 : 0.3,
          }}
          width="32"
          height="32"
          fill="none"
          stroke={DesignSystemColorsTheme.primary.primary100}
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <use href="/feather-sprite.svg#check" />
        </svg>
      )}
    </ProgressNumber>
  </Wrapper>
);

export default FileUploadProgressBar;
