import styled from 'styled-components';
import { useEffect, useState } from 'react';

const sizeStyles = {
  small: {
    borderRadius: '8px',
    width: '14px',
    height: '14px',
    padding: '8px',
    iconSize: '16',
  },
  medium: {
    borderRadius: '10px',
    width: '16px',
    height: '16px',
    padding: '12px',
    iconSize: '18',
  },
  large: {
    borderRadius: '12px',
    width: '18px',
    height: '18px',
    padding: '16px',
    iconSize: '20',
  },
};

interface ButtonIconTertiaryProps {
  icon?: string;
  size?: 'small' | 'medium' | 'large';
  onClick?: () => void;
  disabled?: boolean;
}

interface BaseButtonProps extends ButtonIconTertiaryProps {
  keyboardFocused?: boolean;
}

const ButtonIconTertiaryStyles = styled.button<BaseButtonProps>`
  /* Reset styles */
  all: unset;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  /* Set border and background colors */
  background-color: ${(props) => props.theme.white};
  color: ${(props) => props.theme.primary.primary100};

  /* Styling for icons color */
  --icon-stroke-color: ${(props) =>
    props.disabled ? props.theme.primary.primary20 : props.theme.primary.primary100};

  /* Link and focus styles */
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }

  /* Hover styles */
  &:hover {
    border-color: ${(props) => props.theme.primary.primaryHover};
    --icon-stroke-color: ${(props) => props.theme.primary.primaryHover};
  }

  /* Active styles */
  &:active {
    border-color: ${(props) => props.theme.primary.primaryActive};
    --icon-stroke-color: ${(props) => props.theme.primary.primaryActive};
  }

  /* Focus styles */
  &:focus {
    ${(props) =>
      props.keyboardFocused &&
      `
      outline: 2px solid ${props.theme.system.info100};
      outline-offset: 2px;
    `}
  }

  /* Disabled styles */
  &:disabled {
    border-color: ${(props) => props.theme.primary.primary20};
    --icon-stroke-color: ${(props) => props.theme.primary.primary20};
  }

  /* Set dimensions and padding based on size */
  height: ${(props) => (props.size ? sizeStyles[props.size].width : sizeStyles.medium.height)};
  width: ${(props) => (props.size ? sizeStyles[props.size].height : sizeStyles.medium.width)};
  padding: ${(props) => (props.size ? sizeStyles[props.size].padding : sizeStyles.medium.padding)};
  border-radius: ${(props) =>
    props.size ? sizeStyles[props.size].borderRadius : sizeStyles.medium.borderRadius};

  /* SVG icon styling */
  svg.icon {
    width: ${(props) =>
      props.size ? sizeStyles[props.size].iconSize : sizeStyles.medium.iconSize};
    height: ${(props) =>
      props.size ? sizeStyles[props.size].iconSize : sizeStyles.medium.iconSize};
    fill: none;
    stroke-width: 2.5;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: var(--icon-stroke-color);
  }
`;

const ButtonIconTertiary = ({
  size = 'medium',
  disabled = false,
  icon,
  onClick,
}: ButtonIconTertiaryProps) => {
  const [keyboardFocused, setKeyboardFocused] = useState(false);

  useEffect(() => {
    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key === 'Tab') {
        setKeyboardFocused(true);
      }
    };

    const handleMousedown = () => {
      setKeyboardFocused(false);
    };

    window.addEventListener('keydown', handleKeydown);
    window.addEventListener('mousedown', handleMousedown);

    return () => {
      window.removeEventListener('keydown', handleKeydown);
      window.removeEventListener('mousedown', handleMousedown);
    };
  }, []);

  const iconSize = sizeStyles[size].iconSize;

  return (
    <ButtonIconTertiaryStyles
      keyboardFocused={keyboardFocused}
      size={size}
      disabled={disabled}
      onClick={onClick}
    >
      {icon && (
        <svg
          className="icon"
          width={iconSize}
          height={iconSize}
          fill="none"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <use href={icon} />
        </svg>
      )}
    </ButtonIconTertiaryStyles>
  );
};

export default ButtonIconTertiary;
