import { useAuth0 } from '@auth0/auth0-react';
import { Route, Routes } from 'react-router-dom';
import { CallbackPage } from './pages/CallbackPage';
import { PageLoader } from './components/PageLoader';
import { PageLayout } from './components/PageLayout';
import { ProtectedRoute } from './components/ProtectedRoute';
import { NotFoundPage } from './pages/NotFoundPage';
import { ThemeProvider } from './components/themeing/ThemeProvider';
import { CheckingAnalysisPage } from './pages/CheckingAnalysisPage/CheckingAnalysisPage';
import { Provider } from '@rollbar/react';
import { DataUploadPage } from './pages/DataUploadPage/DataUploadPage';
import { MarketingResourcePage } from './pages/MarketingResourcePage/MarketingResourcePage';
import { useClientStore } from './stores/clientStore';
import { getClient } from './services/clients.service';
import { useEffect } from 'react';
import ViewMonthlyAnalysisPage from './pages/CheckingAnalysisPage/ViewMonthlyAnalysisPage';
import { ToastProvider } from '@radix-ui/react-toast';

export const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  enabled:
    process.env.REACT_APP_NICKELS_ENV !== 'development' &&
    process.env.REACT_APP_NICKELS_ENV !== 'test',
  captureUnhandledRejections: true,
  payload: {
    client: {
      javascript: {
        code_version: '1.0.0',
        source_map_enabled: true,
      },
    },
  },
};

const App = () => {
  const { isLoading, getAccessTokenSilently, user } = useAuth0();

  const updateClient = useClientStore((state) => state.updateClient);

  useEffect(() => {
    const getClientInfo = async () => {
      const accessToken = await getAccessTokenSilently();
      const userClient = await getClient(accessToken, user?.clientInfo.client_id);

      if (userClient.data) {
        updateClient(userClient.data);
      }
    };

    if (user) {
      getClientInfo();
    }
  }, [getAccessTokenSilently, user, updateClient]);

  if (isLoading) {
    return (
      <Provider config={rollbarConfig}>
        <ThemeProvider>
          <ToastProvider>
            <PageLayout>
              <PageLoader />
            </PageLayout>
          </ToastProvider>
        </ThemeProvider>
      </Provider>
    );
  }

  return (
    <Provider config={rollbarConfig}>
      <ThemeProvider>
        <ToastProvider>
          <Routes>
            <Route path="/" element={<ProtectedRoute component={CheckingAnalysisPage} />} />
            <Route
              path="/analysis/home"
              element={<ProtectedRoute component={CheckingAnalysisPage} />}
            />
            <Route
              path="/marketing-resources"
              element={<ProtectedRoute component={MarketingResourcePage} />}
            />
            <Route path="/callback" element={<CallbackPage />} />
            <Route path="/analysis/:year/:month" element={<ViewMonthlyAnalysisPage />} />
            <Route path="/data-upload" element={<ProtectedRoute component={DataUploadPage} />} />
            {/* <Route path="/protected" element={<ProtectedRoute component={ProtectedPage} />} /> */}
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </ToastProvider>
      </ThemeProvider>
    </Provider>
  );
};

export { App };
