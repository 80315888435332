import React from 'react';
import styled, { keyframes } from 'styled-components';

const appLogoSpin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }  
`;

const StyledPageLoader = styled.div`
  height: 10rem;
  width: 10rem;

  margin: auto;
  margin-top: 2rem;

  animation: ${appLogoSpin} infinite 20s linear;
  filter: invert(45%) sepia(41%) saturate(982%) hue-rotate(195deg) brightness(89%) contrast(81%);
`;

export const PageLoader = () => {
  const loadingImg = 'https://cdn.auth0.com/blog/hello-auth0/loader.svg';

  return (
    <StyledPageLoader>
      <img src={loadingImg} alt="Loading..." />
    </StyledPageLoader>
  );
};
