import { baseColors } from '../../components/themeing/themes';
import styled from 'styled-components';
import { HeaderText } from '../../components/typography/HeaderText';
import { BodyText } from '../../components/typography/BodyText';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import BarGraph from '../../components/charts/BarGraph';
import { DesignSystemColorsTheme } from '../../components/themeing/themes';
import { ClientMonthlyStats } from '../../models/ClientMonthlyStats';
import { useNavigate } from 'react-router-dom';
import { bankNameMapping } from '../CheckingAnalysisPage/helpers';
import { ButtonText } from '../../components/typography/ButtonText';
import { RevolverTopBankPayments } from '../../models/RevolverTopBankPayments';
import { abbreviateNumber } from '../../utils/common';

interface ProviderInsightsTabProps {
  revolverTopBankPayments: RevolverTopBankPayments[];
  clientMonthlyStats: ClientMonthlyStats;
}

const OverviewContent = styled.div`
  align-items: center;
  margin: 40px;
`;

const OverviewContentHeader = styled(HeaderText)`
  margin-bottom: 16px;
`;

const OverviewContentSubtext = styled(BodyText)`
  margin-bottom: 24px;
`;

const OverviewContentHeaderBottom = styled(HeaderText)`
  margin-top: 24px;
  margin-bottom: 16px;
`;

const OverviewContentSubtextBottom = styled(BodyText)`
  margin-bottom: 16px;
`;

const OverviewContentButtonBottom = styled(PrimaryButton)`
  margin-bottom: 40px;
`;

const OverviewGraphContainer = styled.div`
  display: inline-block;
  width: 693px;
  height: 300px;
  canvas {
    margin: 20px;
  }
`;

const LowerCardContainer = styled.div`
  display: flex;
  height: 300px;
  align-items: center;
`;

const CardProvidersTextContainer = styled.div`
  margin-top: 10px;
  display: flex;
  text-align: center;
`;

const CardProvidersText = styled(ButtonText)`
  margin: auto;
  color: ${baseColors.text.textBlue40};
`;

const ProviderInsightsTab = ({
  revolverTopBankPayments,
  clientMonthlyStats,
}: ProviderInsightsTabProps) => {
  const overviewChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      colors: {
        forceOverride: true,
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      y: {
        offset: true,
        title: {
          display: true,
          text: 'Number of revolvers',
          color: baseColors.text.textBlue40,
          font: {
            size: 14,
            family: 'Sora',
            weight: '600',
          },
        },
        min: 0,
        max: 0,
        ticks: {
          callback: function (value: string | number) {
            const numericValue = typeof value === 'string' ? parseFloat(value) : value;
            return abbreviateNumber(numericValue);
          },
          padding: 10,
          display: true,
          precision: -1,
          count: 10,
          font: {
            size: 12,
            family: 'Nunito Sans',
            weight: '400',
          },
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 12,
            family: 'Nunito Sans',
            weight: '400',
          },
        },
      },
    },
  };
  let max = 0;
  const labels = revolverTopBankPayments.map((payment) => bankNameMapping[payment.bankName]);
  const data: number[] = [];
  labels.forEach((label) => {
    const payment = revolverTopBankPayments.find((p) => bankNameMapping[p.bankName] === label);
    if (payment) {
      const total: number =
        payment.paymentsToAmountMemberCounts['500'] +
        payment.paymentsToAmountMemberCounts['1000'] +
        payment.paymentsToAmountMemberCounts['2000'] +
        payment.paymentsToAmountMemberCounts['2000+'];
      if (total > max) {
        max = total;
      }
      data.push(total);
    }
  });
  overviewChartOptions.scales.y.max = max;
  const revolverInsightsData = {
    labels,
    datasets: [
      {
        label: 'Number of Likely Revolvers',
        data,
        backgroundColor: baseColors.text.textBlue80,
        borderRadius: { topLeft: 8, topRight: 8 },
        barThickness: 30,
        hoverBackgroundColor: DesignSystemColorsTheme.primary.primary100,
      },
    ],
  };

  const navigate = useNavigate();

  return (
    <OverviewContent>
      <OverviewContentHeader color={baseColors.text.textBlue80} size="medium">
        Top Card Providers for Revolvers
      </OverviewContentHeader>
      <OverviewContentSubtext size="large">
        The following graph shows a breakdown of how many of your{' '}
        {(
          (clientMonthlyStats?.lightRevolvers || 0) + (clientMonthlyStats?.heavyRevolvers || 0)
        ).toLocaleString()}{' '}
        Likely Revolvers have made payments to your top card providers. Note that some Likely
        Revolvers pay multiple card providers.
      </OverviewContentSubtext>
      <CardProvidersTextContainer>
        <CardProvidersText size="medium">
          Revolving payments to top card providers
        </CardProvidersText>
      </CardProvidersTextContainer>
      <LowerCardContainer>
        <OverviewGraphContainer>
          <BarGraph data={revolverInsightsData} options={overviewChartOptions} />
        </OverviewGraphContainer>
      </LowerCardContainer>
      <CardProvidersTextContainer>
        <CardProvidersText size="medium">Card providers</CardProvidersText>
      </CardProvidersTextContainer>
      <OverviewContentHeaderBottom size="medium">Take action</OverviewContentHeaderBottom>
      <OverviewContentSubtextBottom size="large">
        Send our Don’t Overpay email to target revolvers to specific card providers.
      </OverviewContentSubtextBottom>
      <OverviewContentButtonBottom
        label="View the Don't Overpay Campaign"
        size="large"
        onClick={() => navigate('/marketing-resources')}
      ></OverviewContentButtonBottom>
    </OverviewContent>
  );
};

export default ProviderInsightsTab;
