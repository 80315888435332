import { ApiResponse } from '../models/api-response';
import { AxiosHeaders, AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { callExternalApi } from './external-api.service';
import { TopCardProviderPayments } from '../models/TopCardProviderPayments';

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export async function getTopCardProviderPayments(
  accessToken: string,
  year: string,
  month: string,
): Promise<ApiResponse<TopCardProviderPayments[]>> {
  const requestHeaders = new AxiosHeaders();
  requestHeaders.set('Authorization', `Bearer ${accessToken}`);

  const endpointUrl = `${apiServerUrl}/api/analysis/top-card-provider-payments/${year}/${month}`;

  const config: AxiosRequestConfig = {
    url: endpointUrl,
    method: 'GET',
    headers: requestHeaders as AxiosRequestHeaders,
  };

  const { data, status, headers, error } = (await callExternalApi({
    config,
  })) as ApiResponse<TopCardProviderPayments[]>;

  return {
    data,
    status,
    headers,
    error,
  };
}
