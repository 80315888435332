import { PageLayout } from '../../components/PageLayout';
import { useEffect, useState } from 'react';
import { Subtitle } from '../../components/TextComponents/TextComponents';
import styled from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';
import { getMarketingResources } from '../../services/marketing-resources.service';
import { MarketingResource } from '../../models/MarketingResource';
import { getUserType } from '../../utils/account-holder-translation';
import { useClientStore } from '../../stores/clientStore';
import MarketingResourceCard from './MarketingResourceCard';
import { TitleText } from '../../components/typography/TitleText';
import { BodyText } from '../../components/typography/BodyText';

const ResourceContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  padding-bottom: 72px;
`;

const StyledSubtitleContainer = styled.div`
  padding-top: 32px;
  padding-bottom: 80px;
`;

export function MarketingResourcePage() {
  const { getAccessTokenSilently } = useAuth0();
  const [resources, setMarketingResources] = useState<MarketingResource[]>([]);

  const userClient = useClientStore((state) => state.client);
  const institutionType = userClient?.institutionType;

  useEffect(() => {
    const getMarketingResource = async () => {
      const accessToken = await getAccessTokenSilently();
      if (accessToken) {
        const { data: marketingResourceData } = await getMarketingResources(
          accessToken,
          institutionType ?? '',
        );
        if (marketingResourceData) {
          setMarketingResources(marketingResourceData);
        }
      }
    };
    getMarketingResource();
  }, [getAccessTokenSilently, institutionType]);
  return (
    <>
      <PageLayout>
        <StyledSubtitleContainer>
          <TitleText>Marketing resources</TitleText>
          <BodyText size="large">
            Help your {getUserType(institutionType, false, 'plural')} better manage their competitor
            cards with targeted marketing offers.
          </BodyText>
          <BodyText size="large">Download templates for targeted marketing campaigns.</BodyText>
        </StyledSubtitleContainer>
        <ResourceContainer>
          {resources.map((resource) => {
            return <MarketingResourceCard key={resource.id} resource={resource} />;
          })}
        </ResourceContainer>
        <Subtitle></Subtitle>
      </PageLayout>
    </>
  );
}
