import { PageLayout } from '../../components/PageLayout';
import { useCallback, useEffect, useState } from 'react';
import { getUser } from '../../services/user.service';
import { useAuth0, User } from '@auth0/auth0-react';
import { UploadAnalysisData } from '../../components/UploadAnalysisData/UploadAnalysisData';
import styled from 'styled-components';
import { useClientStore } from '../../stores/clientStore';
import { getAllMetadata } from '../../services/transaction-upload.service';
import { TransactionDataUpload } from '../../models/TransactionDataUpload';
import BackButton from '../../components/buttons/BackButton';
import { PageLoader } from '../../components/PageLoader';
import DataUploadHistory from './DataUploadHistory';
import { TitleText } from '../../components/typography/TitleText';
import { BodyText } from '../../components/typography/BodyText';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import Toast from '../../components/toasts/Toast';
import { useLocation } from 'react-router-dom';

const TitleButtonContainer = styled.div`
  padding-top: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleContainer = styled.div`
  padding-top: 24px;
  padding-bottom: 12px;
`;

const ContentContainer = styled.div`
  padding-top: 48px;
`;

const BackButtonContainer = styled.div`
  margin-top: 40px;
`;

export function DataUploadPage() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const showHistoryParam = params.get('showHistory');

  // Note: The 'showHistory' parameter is a string, so we need to convert it to a boolean
  const shouldShowHistory = showHistoryParam === 'true' ? true : false;

  const [showDataUploadHistory, setShowDataUploadHistory] = useState(shouldShowHistory);
  const [nickelsUser, setNickelsUser] = useState<User | null>(null);
  const { getAccessTokenSilently, user } = useAuth0();
  const [token, setToken] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);
  const [previousFiles, setPreviousFiles] = useState<TransactionDataUpload[]>([]);
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [uploadSuccess, setUploadSuccess] = useState(false); // new state

  const userClient = useClientStore((state) => state.client);

  const fetchPreviousFiles = useCallback(async () => {
    if (!token) {
      return;
    }

    const response = await getAllMetadata(token);
    const previousUploads = response.data;

    if (previousUploads && previousUploads.length > 0) {
      setPreviousFiles(previousUploads);
    } else {
      setShowDataUploadHistory(false);
    }

    setIsLoading(false);
    setUploadSuccess(false);
  }, [token]);

  const handleUploadSuccess = async () => {
    setUploadSuccess(true);
    setOpenToast(true);
    setShowDataUploadHistory(true);
  };

  useEffect(() => {
    const getUserInfo = async () => {
      const accessToken = await getAccessTokenSilently();
      setToken(accessToken);

      if (user && user.email) {
        const { data } = await getUser(accessToken, user.email);

        if (data) {
          setNickelsUser(data);
        }
      }
    };

    getUserInfo();
  }, [getAccessTokenSilently, user]);

  useEffect(() => {
    fetchPreviousFiles();
  }, [fetchPreviousFiles, token, uploadSuccess]);

  return (
    <>
      {isLoading ? (
        <PageLoader />
      ) : showDataUploadHistory ? (
        <PageLayout>
          <TitleButtonContainer>
            <TitleContainer>
              <TitleText>Checking account data upload</TitleText>
            </TitleContainer>
            <PrimaryButton
              onClick={() => setShowDataUploadHistory(!showDataUploadHistory)}
              label="Upload new data"
              size="large"
              leftIcon="/feather-sprite.svg#upload"
            />
          </TitleButtonContainer>
          <BodyText size="large">
            Add anonymized data on your checking account transactions for analysis.
          </BodyText>
          <DataUploadHistory accessToken={token} previousUploads={previousFiles} />
          <Toast
            open={openToast}
            onOpenChange={setOpenToast}
            title="Data uploaded successfully"
            description="We’ll notify you via email, within 5 days, when your analysis is complete."
            variant="success"
            width="420px"
          />
        </PageLayout>
      ) : (
        <PageLayout>
          {previousFiles.length > 0 && (
            <BackButtonContainer>
              <BackButton
                name="Cancel uploading new data"
                onClick={() => setShowDataUploadHistory(true)}
              />
            </BackButtonContainer>
          )}
          <TitleContainer>
            <TitleText>Checking account data upload</TitleText>
          </TitleContainer>
          <BodyText size="large">
            Add anonymized data on your checking account transactions for analysis.
          </BodyText>
          <ContentContainer>
            <UploadAnalysisData
              accessToken={token}
              nickelsUser={nickelsUser}
              clientCode={userClient?.clientCode}
              onUploadSuccess={handleUploadSuccess}
            />
          </ContentContainer>
        </PageLayout>
      )}
    </>
  );
}
