import styled from 'styled-components';
import { ButtonText } from '../typography/ButtonText';
import { useEffect, useState } from 'react';

const sizeStyles = {
  small: {
    borderRadius: '8px',
    height: '16px',
    minWidth: '83px',
    padding: '8px 16px',
    iconSize: '16',
    iconMargin: '4px',
  },
  medium: {
    borderRadius: '10px',
    height: '18px',
    minWidth: '98px',
    padding: '12px 24px',
    iconSize: '18',
    iconMargin: '6px',
  },
  large: {
    borderRadius: '12px',
    height: '20px',
    minWidth: '113px',
    padding: '16px 28px',
    iconSize: '20',
    iconMargin: '8px',
  },
};

interface ButtonProps {
  label: string;
  size?: 'small' | 'medium' | 'large';
  onClick?: () => void;
  disabled?: boolean;
  leftIcon?: string;
  rightIcon?: string;
  iconColor?: string;
}

interface BaseButtonProps extends ButtonProps {
  keyboardFocused?: boolean;
}

const BaseButton = styled.button<BaseButtonProps>`
  all: unset;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-color: ${(props) => props.theme.primary.primary100};
  background-color: ${(props) => props.theme.primary.primary100};

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
  &:hover {
    background-color: ${(props) => props.theme.primary.primaryHover};
  }
  &:active {
    background-color: ${(props) => props.theme.primary.primaryActive};
  }
  &:focus {
    ${(props) =>
      props.keyboardFocused &&
      `
      outline: 2px solid ${props.theme.system.info100};
      outline-offset: 2px;
    `}
  }
  &:disabled {
    background-color: ${(props) => props.theme.primary.primary20};
  }

  border-radius: ${(props) =>
    props.size ? sizeStyles[props.size].borderRadius : sizeStyles.medium.borderRadius};
  height: ${(props) => (props.size ? sizeStyles[props.size].height : sizeStyles.medium.height)};
  min-width: ${(props) =>
    props.size ? sizeStyles[props.size].minWidth : sizeStyles.medium.minWidth};
  padding: ${(props) => (props.size ? sizeStyles[props.size].padding : sizeStyles.medium.padding)};
`;

const StyledButtonText = styled(ButtonText)<{ size: 'small' | 'medium' | 'large' }>`
  margin: 0 ${(props) => sizeStyles[props.size].iconMargin};
`;

/**
 * Primary UI component for user interaction
 */
const PrimaryButton = ({
  size = 'medium',
  disabled = false,
  leftIcon,
  rightIcon,
  iconColor = 'white',
  ...props
}: ButtonProps) => {
  const [keyboardFocused, setKeyboardFocused] = useState(false);

  useEffect(() => {
    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key === 'Tab') {
        setKeyboardFocused(true);
      }
    };

    const handleMousedown = () => {
      setKeyboardFocused(false);
    };

    window.addEventListener('keydown', handleKeydown);
    window.addEventListener('mousedown', handleMousedown);

    return () => {
      window.removeEventListener('keydown', handleKeydown);
      window.removeEventListener('mousedown', handleMousedown);
    };
  }, []);

  const iconSize = sizeStyles[size].iconSize;

  return (
    <BaseButton
      keyboardFocused={keyboardFocused}
      label={props.label}
      size={size}
      onClick={props.onClick}
      disabled={disabled}
    >
      {leftIcon && (
        <svg
          width={iconSize}
          height={iconSize}
          fill="none"
          stroke={iconColor}
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <use href={leftIcon} />
        </svg>
      )}
      <StyledButtonText color={'#FFFFFF'} size={size}>
        {props.label}
      </StyledButtonText>
      {rightIcon && (
        <svg
          width={iconSize}
          height={iconSize}
          fill="none"
          stroke={iconColor}
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <use href={rightIcon} />
        </svg>
      )}
    </BaseButton>
  );
};

export default PrimaryButton;
