import { ApiResponse } from '../models/api-response';
import { AxiosHeaders, AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { callExternalApi } from './external-api.service';
import { monthlyNewRevolverStatsEndpointUrlBase } from '../api-routes';
import { MonthlyNewRevolverStats } from '../models/MonthlyNewRevolverStats';

export async function getMonthlyNewRevolverStats(
  accessToken: string,
  clientMonthlyStatsId: string,
): Promise<ApiResponse<MonthlyNewRevolverStats>> {
  const requestHeaders = new AxiosHeaders();
  requestHeaders.set('Authorization', `Bearer ${accessToken}`);

  const endpointUrl = `${monthlyNewRevolverStatsEndpointUrlBase}/${clientMonthlyStatsId}`;

  const config: AxiosRequestConfig = {
    url: endpointUrl,
    method: 'GET',
    headers: requestHeaders as AxiosRequestHeaders,
  };

  const { data, status, headers, error } = (await callExternalApi({
    config,
  })) as ApiResponse<MonthlyNewRevolverStats>;
  return {
    data,
    status,
    headers,
    error,
  };
}
