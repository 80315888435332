import styled from 'styled-components';
import { ButtonText } from '../typography/ButtonText';
import { HeaderText } from '../typography/HeaderText';

export interface TabData {
  name: string;
  active: boolean;
  content: React.ReactNode;
  subtabs?: TabData[]; // subtabs should be in order
}

interface TabProps {
  active: boolean;
  tabColor?: string;
}

interface TitleProps {
  titleColor?: string;
}

interface ContainerProps {
  containerColor?: string;
}

const SectionContainer = styled.div`
  display: flex;
`;

const MainContainer = styled.div`
  width: 100%;
  border: 1px solid ${(props) => props.theme.primary.primary20};
  border-radius: 24px;
  box-shadow: 0px 10px 20px rgba(65, 61, 231, 0.04), 0px 4px 15px rgba(85, 85, 88, 0.02);
`;

const SectionTitle = styled.div<TitleProps>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  border-radius: 24px 24px 0px 0px;
  background-color: ${(props) => props.titleColor || props.theme.primary.primary20};
`;

const TitleText = styled(HeaderText)`
  margin-left: 40px;
`;

const TabsContainer = styled.div<ContainerProps>`
  display: inline-table;
  width: 236px;
  height: 716px;
  border-radius: 0px 0px 0px 24px;
  background-color: ${(props) => props.containerColor || props.theme.primary.primary5};
  padding-top: 24px;
`;

const BigTab = styled.button<TabProps>`
  display: block;
  align-items: center;
  width: 212px;
  height: 42px;
  background-color: ${(props) =>
    props.active ? props.tabColor || props.theme.primary.primary20 : 'transparent'};
  color: ${(props) => (props.active ? props.theme.primary.primary100 : 'black')};
  cursor: ${(props) => (props.active ? 'auto' : 'pointer')};
  border-radius: 0px 8px 8px 0px;
  gap: 10px;
  border: none;
`;

const SmallTab = styled.button<TabProps>`
  display: block;
  align-items: center;
  width: 212px;
  height: 32px;
  background-color: ${(props) =>
    props.active ? props.tabColor || props.theme.primary.primary20 : 'transparent'};
  cursor: ${(props) => (props.active ? 'auto' : 'pointer')};
  border-radius: 0px 8px 8px 0px;
  gap: 10px;
  border: none;
`;

const BigTabText = styled.div`
  display: flex;
  margin-left: 48px;
`;

const SmallTabText = styled.div`
  display: flex;
  margin-left: 64px;
`;

const ContentContainer = styled.div`
  display: inline-block;
  width: 808px;
  height: 716px;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
`;

type Props = {
  parentTabs: TabData[]; // parent tabs should be in order
  title: string; //section title
  changeActiveTab: (tabName: string) => void;
  titleColor?: string;
  containerColor?: string;
  tabColor?: string;
  activeColor?: string;
};

/**
 * `Tabs` is a reusable, tab navigation component.
 *
 * Props:
 * - `parentTabs`: Array of objects that holds parent tab data.
 * - `title`: The title to be displayed at the top of the section.
 *
 * `parentTabs` structure:
 * - `name`: Tab name to be displayed on the left.
 * - `active`: Boolean to discern if this tab is the currently active one.
 * - `content`: The content to be displayed when this tab is active. Should be a React Node.
 * - `subtabs`: Optional array of objects that holds subtab data. Repeats `parentTabs` structure.
 *
 */
export default function TabsNavigation({
  parentTabs,
  title,
  changeActiveTab,
  titleColor,
  containerColor,
  tabColor,
  activeColor,
}: Props) {
  let activeTab = null;
  parentTabs &&
    parentTabs.forEach((tab) => {
      if (tab.active) {
        activeTab = tab.content;
      }
      if (tab.subtabs) {
        tab.subtabs.forEach((subtab) => {
          if (subtab.active) {
            activeTab = subtab.content;
          }
        });
      }
    });
  return (
    <MainContainer>
      <SectionTitle titleColor={titleColor}>
        <TitleText size="medium">{title}</TitleText>
      </SectionTitle>
      <SectionContainer>
        <TabsContainer containerColor={containerColor}>
          {parentTabs.map((tab) => {
            return (
              <div key={tab.name}>
                <BigTab
                  tabColor={tabColor}
                  onClick={() => changeActiveTab(tab.name)}
                  active={tab.active}
                >
                  <BigTabText>
                    <ButtonText color={tab.active ? activeColor : 'black'} size="medium">
                      {tab.name}
                    </ButtonText>
                  </BigTabText>
                </BigTab>
                <div>
                  {tab &&
                    tab.subtabs?.map((subtab) => {
                      return (
                        <SmallTab
                          tabColor={tabColor}
                          onClick={() => changeActiveTab(subtab.name)}
                          key={subtab.name}
                          active={subtab.active}
                        >
                          <SmallTabText>
                            <ButtonText size="small">{subtab.name}</ButtonText>
                          </SmallTabText>
                        </SmallTab>
                      );
                    })}
                </div>
              </div>
            );
          })}
        </TabsContainer>
        <ContentContainer>
          <Content>{activeTab}</Content>
        </ContentContainer>
      </SectionContainer>
    </MainContainer>
  );
}
