import { ApiResponse } from '../models/api-response';
import { AxiosHeaders, AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { callExternalApi } from './external-api.service';
import { RevolvingAccounts } from '../models/RevolvingAccounts';
import { revolvingAccountsEndpointUrlBase } from '../api-routes';

export async function getRevolvingAccounts(
  accessToken: string,
  year: string,
  month: string,
): Promise<ApiResponse<RevolvingAccounts>> {
  const requestHeaders = new AxiosHeaders();
  requestHeaders.set('Authorization', `Bearer ${accessToken}`);

  const endpointUrl = `${revolvingAccountsEndpointUrlBase}/${year}/${month}`;

  const config: AxiosRequestConfig = {
    url: endpointUrl,
    method: 'GET',
    headers: requestHeaders as AxiosRequestHeaders,
  };

  const { data, status, headers, error } = (await callExternalApi({
    config,
  })) as ApiResponse<RevolvingAccounts>;

  return {
    data,
    status,
    headers,
    error,
  };
}
