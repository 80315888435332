import { ChartData, ChartOptions } from 'chart.js';
import React from 'react';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Title as ChartTitle,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
} from 'chart.js';
import { abbreviateNumber } from '../../utils/common';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, ChartTitle);

interface BarGraphProps {
  data: ChartData<'bar'> | null;
  options?: ChartOptions<'bar'>;
}

const defaultOptions = {
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    padding: {
      top: 30,
    },
  },
  plugins: {
    colors: {
      forceOverride: true,
    },
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
    datalabels: {
      display: true,
      color: 'black',
      align: 'end' as const,
      anchor: 'end' as const,
      formatter: function (value: number) {
        return '$' + value.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      },
      font: {
        size: 12,
        family: 'Sora',
        weight: 600,
      },
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        callback: function (value: string | number) {
          const numericValue = typeof value === 'string' ? parseFloat(value) : value;
          return abbreviateNumber(numericValue);
        },
        font: {
          size: 12,
          family: 'Nunito Sans',
          weight: '400',
        },
      },
    },
    x: {
      grid: {
        display: false,
      },
      ticks: {
        font: {
          size: 12,
          family: 'Nunito Sans',
          weight: '400',
        },
      },
    },
  },
};

const BarGraph: React.FC<BarGraphProps> = ({ data, options = defaultOptions }) => {
  return (
    <div data-testid="bar-graph" style={{ height: '100%' }}>
      {data && (
        <Bar data={data} plugins={[ChartDataLabels]} options={options} style={{ height: '100%' }} />
      )}
    </div>
  );
};

export default BarGraph;
