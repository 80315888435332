import { useNavigate } from 'react-router-dom';
import { DesignSystemColorsTheme } from '../themeing/themes';
import AdditionalButton from './AdditionalButton';

interface BackButtonProps {
  name?: string;
  onClick?: () => void;
}

/**
 * The BackButton component.
 *
 * This component renders a styled button which, when clicked, will either:
 * 1. Invoke a function passed in the `onClick` prop, if provided.
 * 2. Navigate to the previous page in the browser history, if no `onClick` prop is provided.
 *
 * The intended use case is to provide a 'Back' navigation mechanism, which can be customized for different situations.
 * For example, in a page that conditionally renders different views, the `onClick` prop can be used to switch views without leaving the page.
 * In other cases, where the button is used for actual navigation between pages, no `onClick` prop needs to be provided.
 *
 * @param {string} [name] - An optional name to give the button. Default is 'Back'.
 * @param {() => void} [onClick] - An optional function to be invoked when the button is clicked.
 */
const BackButton: React.FC<BackButtonProps> = ({ name, onClick }) => {
  const navigate = useNavigate();

  function handleClick(): void {
    if (onClick) {
      onClick();
    } else {
      navigate(-1);
    }
  }

  return (
    <AdditionalButton
      iconColor={DesignSystemColorsTheme.primary.primary100}
      leftIcon="/feather-sprite.svg#arrow-left"
      label={name ? name : 'Back'}
      onClick={handleClick}
    />
  );
};

export default BackButton;
