import styled from 'styled-components';
import { DesignSystemColorsTheme } from '../themeing/themes';

export interface ButtonTextProps {
  color?: string;
  size: 'small' | 'medium' | 'large';
  align?: 'start' | 'end' | 'center';
}

const sizeStyles = {
  small: {
    fontSize: '12px',
    minWidth: '43px',
    minHeight: '16px',
  },
  medium: {
    fontSize: '14px',
    lineHeight: '18px',
    minWidth: '50px',
    minHeight: '18px',
  },
  large: {
    fontSize: '16px',
    minWidth: '57px',
    minHeight: '20px',
  },
};

/**
 * ButtonText is a styled-component that renders a <p> element with custom styles
 * based on the provided size. It uses the Sora font family, normal font style,
 * and 600 font weight. The text color can be customized through the optional `color`
 * prop, or it will default to the value specified in the theme. The `size` prop
 * is mandatory and determines the font size and line height. The `align` prop
 * accepts 'start', 'end', or 'center' to justify the text accordingly.
 *
 * Sizes:
 * - small:  font-size: 12px; line-height: 16px;
 * - medium: font-size: 14px; line-height: 18px;
 * - large:  font-size: 16px; line-height: 20px;
 *
 * @component
 * @example
 * // Render the ButtonText with a small size and custom color
 * <ButtonText size="small" color="red">This is a small ButtonText</ButtonText>
 *
 * @example
 * // Render the ButtonText with a medium size and default color
 * <ButtonText size="medium">This is a medium ButtonText with default color</ButtonText>
 *
 * @example
 * // Render the ButtonText with a large size and custom color
 * <ButtonText size="large" color="green" align="center">This is a large ButtonText with green color and centered text</ButtonText>
 */
export const ButtonText = styled.p<ButtonTextProps>`
  font-family: 'Sora';
  font-style: normal;
  font-weight: 600;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.align || 'start'};
  min-width: ${(props) => sizeStyles[props.size].minWidth};
  min-height: ${(props) => sizeStyles[props.size].minHeight};
  font-size: ${(props) => sizeStyles[props.size].fontSize};
  line-height: ${(props) => sizeStyles[props.size].minHeight};
  color: ${(props) => props.color || DesignSystemColorsTheme.text.textBlue100};
  margin-top: 0;
  margin-bottom: 0;
`;
