import { AxiosHeaders, AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { ApiResponse } from '../models/api-response';
import { callExternalApi } from './external-api.service';
import { User } from '../models/User';

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const getUser = async (accessToken: string, email: string): Promise<ApiResponse<User>> => {
  const requestHeaders = new AxiosHeaders();
  requestHeaders.set('Authorization', `Bearer ${accessToken}`);

  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/users/${email}`,
    method: 'GET',
    headers: requestHeaders as AxiosRequestHeaders,
  };

  const { data, status, headers, error } = (await callExternalApi({ config })) as ApiResponse<User>;

  return {
    data,
    status,
    headers,
    error,
  };
};

export const getUsers = async (accessToken: string): Promise<ApiResponse<User[]>> => {
  const requestHeaders = new AxiosHeaders();
  requestHeaders.set('Authorization', `Bearer ${accessToken}`);

  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/users`,
    method: 'GET',
    headers: requestHeaders as AxiosRequestHeaders,
  };

  const { data, status, headers, error } = (await callExternalApi({ config })) as ApiResponse<
    User[]
  >;

  return {
    data: data || null,
    status,
    headers,
    error,
  };
};

export const createUser = async (
  accessToken: string,
  email: string,
  clientId: string,
): Promise<ApiResponse<User>> => {
  const requestHeaders = new AxiosHeaders();
  requestHeaders.set('Authorization', `Bearer ${accessToken}`);

  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/users`,
    method: 'POST',
    headers: requestHeaders as AxiosRequestHeaders,
    data: {
      email: email,
      clientId: clientId,
    },
  };

  const { data, status, headers, error } = (await callExternalApi({ config })) as ApiResponse<User>;

  return {
    data,
    status,
    headers,
    error,
  };
};
