import React, { useState } from 'react';
import styled from 'styled-components';
import closeIcon from '../../images/closeIcon.svg';

type CardProps = {
  width?: string;
  height?: string;
  background?: string;
  borderStyle?: 'none' | 'dashed' | 'solid';
  borderColor?: string;
  children?: React.ReactNode;
  dismissable?: boolean;
  isDismissed?: boolean;
};

const DismissButton = styled.img`
  all: unset;
  cursor: pointer;
  margin-left: auto;
  width: 20px;
  height: 20px;
`;

const StyledCard = styled.div<CardProps>`
  box-sizing: border-box;
  width: ${({ width }) => `${width}`};
  height: ${({ height }) => `${height}`};
  background: ${({ background = 'white' }) => background};

  border-style: ${({ borderStyle = 'solid' }) => borderStyle};
  border-width: 1px;
  border-color: ${({ borderColor, theme }) => borderColor || theme.primary.primary100};

  border-radius: 16px;
  overflow: hidden;
`;

const Card: React.FC<CardProps> = (props) => {
  const [isDismissed, setIsDismissed] = useState(false);

  const handleDismiss = () => {
    setIsDismissed(true);
  };

  if (isDismissed) {
    return null;
  } else {
    return (
      <StyledCard {...props} isDismissed={isDismissed}>
        {props.children}
        {props.dismissable && <DismissButton src={closeIcon} onClick={handleDismiss} />}
      </StyledCard>
    );
  }
};

export default Card;
