import { FC } from 'react';

interface MenuToggleProps {
  menuCollapsed: boolean;
  setMenuCollapsed: (collapsed: boolean) => void;
}

const MenuToggle: FC<MenuToggleProps> = ({ menuCollapsed, setMenuCollapsed }) => {
  const handleClick = () => {
    setMenuCollapsed(!menuCollapsed);
  };
  if (menuCollapsed) {
    return (
      <svg
        onClick={handleClick}
        width="44"
        height="94"
        viewBox="0 0 44 94"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.5 1V0.5H18H1H0.5V1V93V93.5H1H18H18.5V93V88.4845C18.5 84.0947 20.9992 80.0878 24.9415 78.1568L35.9983 72.7412C40.2835 70.6424 43 66.287 43 61.5155V32.4845C43 27.713 40.2835 23.3576 35.9983 21.2588L24.9415 15.8432C20.9992 13.9122 18.5 9.90531 18.5 5.51546V1Z"
          fill="white"
          stroke="#DBE2FF"
        />
        <path
          d="M35.7784 21.7078L24.7216 16.2922C20.6078 14.2773 18 10.0962 18 5.51546V0H0V94H18V88.4845C18 83.9038 20.6078 79.7227 24.7216 77.7078L35.7784 72.2922C39.8922 70.2773 42.5 66.0962 42.5 61.5155V32.4845C42.5 27.9038 39.8922 23.7227 35.7784 21.7078Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.7405 47.6482L23.9072 53.4815L22.6108 52.1851L27.796 47L22.6108 41.8148L23.9072 40.5185L29.7405 46.3518L30.3887 47L29.7405 47.6482Z"
          fill="#413DE7"
        />
      </svg>
    );
  } else {
    return (
      <svg
        onClick={handleClick}
        width="44"
        height="94"
        viewBox="0 0 44 94"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.5 1V0.5H18H1H0.5V1V93V93.5H1H18H18.5V93V88.4845C18.5 84.0947 20.9992 80.0878 24.9415 78.1568L35.9983 72.7412C40.2835 70.6424 43 66.287 43 61.5155V32.4845C43 27.713 40.2835 23.3576 35.9983 21.2588L24.9415 15.8432C20.9992 13.9122 18.5 9.90531 18.5 5.51546V1Z"
          fill="white"
          stroke="#DBE2FF"
        />
        <path
          d="M35.7784 21.7078L24.7216 16.2922C20.6078 14.2773 18 10.0962 18 5.51546V0H0V94H18V88.4845C18 83.9038 20.6078 79.7227 24.7216 77.7078L35.7784 72.2922C39.8922 70.2773 42.5 66.0962 42.5 61.5155V32.4845C42.5 27.9038 39.8922 23.7227 35.7784 21.7078Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.2595 47.6482L29.0928 53.4815L30.3892 52.1851L25.204 47L30.3892 41.8148L29.0928 40.5185L23.2595 46.3518L22.6113 47L23.2595 47.6482Z"
          fill="#413DE7"
        />
      </svg>
    );
  }
};

export default MenuToggle;
