import React, { useEffect } from 'react';
import styled from 'styled-components';
import { LinkText } from '../typography/LinkText';

type Variation = 'default' | 'info' | 'error' | 'warning' | 'success';
type LinkProps = {
  href: string;
  size: 'small' | 'medium' | 'large';
  variation?: Variation;
  children?: React.ReactNode;
  disabled?: boolean;
};

const StyledLink = styled.a<LinkProps>`
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  cursor: pointer;

  color: ${({ theme, variation = 'default' }) => {
    switch (variation) {
      case 'info':
        return theme.system.info100;
      case 'error':
        return theme.system.error100;
      case 'warning':
        return theme.system.warning100;
      case 'success':
        return theme.system.success100;
      default:
        return theme.primary.primary100;
    }
  }};

  &:hover {
    color: ${({ theme, variation = 'default' }) => {
      switch (variation) {
        case 'info':
          return theme.system.infoHover;
        case 'error':
          return theme.system.errorHover;
        case 'warning':
          return theme.system.warningHover;
        case 'success':
          return theme.system.successHover;
        default:
          return theme.primary.primaryHover;
      }
    }};
  }

  body.keyboard-focus &:focus {
    outline: none;
    border: 4px solid #148eff;
    color: ${({ theme }) => theme.primary.primary100};
  }

  &:active {
    color: ${({ theme, variation = 'default' }) => {
      switch (variation) {
        case 'info':
          return theme.system.infoActive;
        case 'error':
          return theme.system.errorActive;
        case 'warning':
          return theme.system.warningActive;
        case 'success':
          return theme.system.successActive;
        default:
          return theme.primary.primaryActive;
      }
    }};
  }

  ${({ disabled, theme, variation = 'default' }) =>
    disabled &&
    `
  pointer-events: none;
  color: ${(function () {
    switch (variation) {
      case 'info':
        return theme.system.info20;
      case 'error':
        return theme.system.error20;
      case 'warning':
        return theme.system.warning20;
      case 'success':
        return theme.system.success20;
      default:
        return theme.primary.primary20;
    }
  })()};
`}

  & > * {
    color: currentColor;
  }
`;

/**
 * Link component
 *
 * This component renders a link with different visual variations and states.
 * It supports five color variations: 'default', 'info', 'error', 'warning', 'success'.
 * Each variation changes the color of the link.
 * The component also supports three different sizes: 'small', 'medium', 'large'.
 *
 * @prop {string} href - The URL that the link points to.
 * @prop {'small' | 'medium' | 'large'} size - The size of the link.
 * @prop {'default' | 'info' | 'error' | 'warning' | 'success'} [variation] - The visual variation of the link.
 * @prop {React.ReactNode} [children] - The content of the link.
 * @prop {boolean} [disabled] - If true, the link will be disabled.
 *
 * @example
 * <Link href="http://example.com" size="medium" variation="error">Example Link</Link>
 */
const Link: React.FC<LinkProps> = ({ href, size, children, ...props }) => {
  useEffect(() => {
    const keyboardFocusClass = 'keyboard-focus';

    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key === 'Tab') {
        document.body.classList.add(keyboardFocusClass);
      }
    };

    const handleMousedown = () => {
      document.body.classList.remove(keyboardFocusClass);
    };

    window.addEventListener('keydown', handleKeydown);
    window.addEventListener('mousedown', handleMousedown);

    return () => {
      window.removeEventListener('keydown', handleKeydown);
      window.removeEventListener('mousedown', handleMousedown);
    };
  }, []);

  return (
    <StyledLink {...props} href={href} size={size}>
      <LinkText size={size}>{children}</LinkText>
    </StyledLink>
  );
};

export default Link;
