import React, { useEffect } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import styled from 'styled-components';
import Checkbox from '../FormComponents/Checkbox';
import { BodyText } from '../typography/BodyText';
import { DesignSystemColorsTheme } from '../themeing/themes';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const LabelsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const RightLabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const LabelIcon = styled.div`
  margin-left: 8px;
  width: 17px;
  height: 17px;
  fill: none;
  stroke: ${({ theme }) => theme.text.textBlue60};
  stroke-width=: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 12px;
`;

const StyledDropdown = styled(DropdownMenu.Root)({});

const StyledTrigger = styled(DropdownMenu.Trigger)(({ disabled }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '15px 12px',
  gap: '8px',
  width: '244px',
  height: '52px',
  border: `1px solid ${
    disabled ? DesignSystemColorsTheme.primary.primary20 : DesignSystemColorsTheme.primary.primary50
  }`,
  borderRadius: '12px',
  cursor: disabled ? 'not-allowed' : 'pointer',
  textAlign: 'start',
  background: disabled ? DesignSystemColorsTheme.white : DesignSystemColorsTheme.primary.primary100,
  color: DesignSystemColorsTheme.white,
}));

const StyledContent = styled(DropdownMenu.Content)({
  marginTop: '4px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  width: '244px',
  maxHeight: '80vh',
  overflowY: 'auto',
  background: 'white',
  border: `1px solid ${DesignSystemColorsTheme.primary.primary20}`,
  boxShadow: '0px 10px 20px rgba(65, 61, 231, 0.04), 0px 4px 15px rgba(85, 85, 88, 0.02)',
  borderRadius: '12px',
  zIndex: 2,
});

const StyledItem = styled(DropdownMenu.Item).attrs<{ disabled?: boolean; divider?: boolean }>(
  () => ({
    as: 'div',
  }),
)<{ disabled?: boolean; divider?: boolean }>(({ disabled, divider }) => ({
  width: '244px',
  minHeight: '24px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  paddingLeft: '16px',
  paddingRight: '16px',
  paddingTop: '16px',
  paddingBottom: '16px',
  ':hover': {
    backgroundColor: DesignSystemColorsTheme.primary.primary5,
  },
  borderBottom: divider ? `1px solid ${DesignSystemColorsTheme.primary.primary20}` : 'none',
  marginBottom: divider ? '8px' : '0',
  ...(disabled && {
    opacity: 0.5,
    pointerEvents: 'none',
    cursor: 'not-allowed',
  }),
}));

export type Option = {
  value: string;
  label: string;
};

interface PrimaryMultiSelectDropdownProps {
  labelLeft?: string;
  labelRight?: string;
  labelIcon?: string;
  options: Option[];
  disabled?: boolean;
  filter?: (option: Option) => boolean;
  placeholder?: string;
  value: Option[];
  onChange: (value: Option[]) => void;
  tooltip?: React.ReactNode;
  button?: React.ReactNode;
}

const PrimaryMultiSelectDropdown: React.FC<
  PrimaryMultiSelectDropdownProps & { onChange: (selected: Option[]) => void; value: Option[] }
> = ({
  labelLeft,
  labelRight,
  labelIcon,
  options,
  disabled = false,
  filter,
  placeholder,
  onChange,
  value,
  tooltip,
  button,
}) => {
  const [selectAll, setSelectAll] = React.useState(false);

  useEffect(() => {
    setSelectAll(value.length === options.length);
  }, [value, options]);

  const handleSelect = (option: Option) => {
    if (disabled || (filter && filter(option))) return;

    const newValue = value.find((o) => o.value === option.value)
      ? value.filter((o) => o.value !== option.value)
      : [...value, option];

    onChange(newValue);

    setSelectAll(newValue.length === options.length);
  };

  return (
    <Container>
      <LabelsRow>
        {labelLeft && <BodyText size="medium">{labelLeft}</BodyText>}
        <RightLabelContainer>
          {labelRight && (
            <BodyText size="medium" color={DesignSystemColorsTheme.text.textBlue60}>
              {labelRight}
            </BodyText>
          )}
          {labelIcon && tooltip && <LabelIcon>{tooltip}</LabelIcon>}
        </RightLabelContainer>
      </LabelsRow>
      <StyledDropdown>
        <StyledTrigger disabled={disabled}>
          <div>
            <BodyText size="large" color={DesignSystemColorsTheme.white}>
              {placeholder}
            </BodyText>
          </div>
          <svg
            width="16px"
            height="16px"
            fill="none"
            stroke={DesignSystemColorsTheme.white}
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <use href="/feather-sprite.svg#chevron-down" stroke={DesignSystemColorsTheme.white} />
          </svg>
        </StyledTrigger>
        <StyledContent>
          <StyledItem
            divider
            onClick={() => {
              if (selectAll) {
                onChange([]);
              } else {
                onChange(options);
              }
              setSelectAll(!selectAll);
            }}
          >
            <Checkbox
              checked={selectAll}
              onCheckedChange={() => {
                if (selectAll) {
                  onChange([]);
                } else {
                  onChange(options);
                }
                setSelectAll(!selectAll);
              }}
              label="Select All"
            />
          </StyledItem>
          {options.map((option) => {
            const isOptionDisabled = filter && filter(option);

            return (
              <StyledItem
                key={option.value}
                disabled={isOptionDisabled}
                style={{ cursor: disabled || isOptionDisabled ? 'not-allowed' : 'default' }}
                title={option.label}
              >
                <div
                  onClick={() => {
                    if (!disabled && !isOptionDisabled) handleSelect(option);
                  }}
                >
                  <Checkbox
                    disabled={disabled || isOptionDisabled}
                    checked={!!value.find((o) => o.value === option.value)}
                    onCheckedChange={() => {
                      if (!disabled && !isOptionDisabled) handleSelect(option);
                    }}
                    label={
                      option.label.length > 28
                        ? option.label.slice(0, 28 - 1) + '...'
                        : option.label
                    }
                  />
                </div>
              </StyledItem>
            );
          })}
          <StyledButtonContainer>
            {React.cloneElement(button as React.ReactElement, {
              disabled: value.length === 0,
            })}
          </StyledButtonContainer>
        </StyledContent>
      </StyledDropdown>
    </Container>
  );
};

export default PrimaryMultiSelectDropdown;
