import { baseColors } from '../../components/themeing/themes';
import styled from 'styled-components';
import { HeaderText } from '../../components/typography/HeaderText';
import { BodyText } from '../../components/typography/BodyText';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import { ClientMonthlyStats } from '../../models/ClientMonthlyStats';
import { useNavigate } from 'react-router-dom';
import DoughnutChart from '../../components/charts/DoughnutChart';
import { TooltipItem } from 'chart.js';
import { TransactorAccountsCount } from '../../models/TransactorAccountsCount';
import fiveOrMoreDot from '../../images/5orMoreDot.svg';
import accentGreen5 from '../../images/accentGreen5.svg';
import accentGreen20 from '../../images/accentGreen20.svg';
import accentGreen50 from '../../images/accentGreen50.svg';
import accentGreen100 from '../../images/accentGreen100.svg';

interface TransactorOverviewTabProps {
  numTransactingAccountsData: TransactorAccountsCount[];
  clientMonthlyStats: ClientMonthlyStats;
}

const TransactorOverviewContent = styled.div`
  align-items: center;
  margin: 40px;
`;

const TransactorOverviewContentHeader = styled(HeaderText)`
  margin-bottom: 16px;
`;

const TransactorOverviewContentSubtext = styled(BodyText)`
  margin-bottom: 24px;
`;

const TransactorOverviewContentHeaderBottom = styled(HeaderText)`
  margin-top: 24px;
  margin-bottom: 16px;
`;

const TransactorOverviewContentSubtextBottom = styled(BodyText)`
  margin-bottom: 16px;
`;

const TransactorOverviewContentButtonBottom = styled(PrimaryButton)`
  margin-bottom: 40px;
`;

const TransactorOverviewGraphContainer = styled.div`
  display: inline-block;
  width: 693px;
  height: 280px;
  margin-right: 40px;
`;

const ChartLegendContainer = styled.div`
  display: inline-block;
  width: 100%;
`;

const LegendHeader = styled.div`
  margin-bottom: 27.5px;
`;

const LowerCardContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const LegendLine = styled.div`
  margin-bottom: 16px;
`;

const LegendItem = styled.div``;

const LegendColorCircle = styled.img`
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 15px;
`;

const LegendPercent = styled(HeaderText)`
  display: inline-block;
  margin-right: 40px;
  width: 42px;
`;

const LegendNumber = styled(BodyText)`
  display: inline-block;
  font-weight: 400;
`;

const TotalAccountsText = styled.div`
  h1 {
    display: inline;
    margin-right: 10px;
  }
  p {
    display: inline;
  }
`;

const TransactorOverviewTab = ({
  numTransactingAccountsData,
  clientMonthlyStats,
}: TransactorOverviewTabProps) => {
  const data = [0, 0, 0, 0, 0];
  let totalAccounts = 0;
  numTransactingAccountsData.forEach((count: TransactorAccountsCount) => {
    totalAccounts += count.numberOfMembers * count.numberTransactingAccounts;
    if (count.numberTransactingAccounts > 5) {
      data[4] += count.numberOfMembers;
    } else if (count.numberTransactingAccounts === 0) {
      return;
    } else {
      data[count.numberTransactingAccounts - 1] += count.numberOfMembers;
    }
  });

  const backgroundColors = [
    baseColors.accent.accentGreen5,
    baseColors.accent.accentGreen20,
    baseColors.accent.accentGreen50,
    baseColors.accent.accentGreen100,
    '#0C463F',
  ];

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const backgroundColorChange = (tooltipItem: any) => {
    return tooltipItem && backgroundColors[tooltipItem.tooltip.dataPoints[0].dataIndex];
  };

  const doughnutOptions = {
    centerText: {
      display: true,
      text: '280',
    },
    cutout: '60%',
    plugins: {
      datalabels: {
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        formatter: (value: any, context: any) => {
          const dataIndex = context.dataIndex;
          return context.chart.data.labels[dataIndex];
        },
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          title: function (context: TooltipItem<'doughnut'>[]) {
            return (
              Math.floor(
                (context[0].dataset.data[context[0].dataIndex] / clientMonthlyStats.transactors) *
                  100,
              ) + '%'
            );
          },
        },
        displayColors: false,
        backgroundColor: backgroundColorChange,
        titleColor: 'black',
        bodyColor: 'black',
        titleFont: {
          size: 12,
          family: 'Sora',
          color: 'black',
        },
        bodyFont: {
          size: 0,
          family: 'Sora',
        },
      },
    },
  };
  const legendDots = [accentGreen5, accentGreen20, accentGreen50, accentGreen100, fiveOrMoreDot];
  const labels = [
    [baseColors.text.textBlue80, '1'],
    [baseColors.text.textBlue80, '2'],
    [baseColors.text.textBlue80, '3'],
    [baseColors.text.textBlue80, '4'],
    [baseColors.text.textBlue80, '5 or more'],
  ];
  const pieData = {
    labels,
    datasets: [
      {
        data,
        hoverOffset: 4,
        backgroundColor: backgroundColors,
        borderColor: 'white',
        borderWidth: 3,
        borderRadius: {
          outerStart: 10,
          outerEnd: 10,
          innerStart: 10,
          innerEnd: 10,
        },
      },
    ],
    centerText: {
      display: true,
      text: '280',
    },
  };

  const textCenter = {
    id: 'textCenter',
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    beforeDatasetsDraw(chart: any) {
      const { ctx } = chart;

      ctx.save();
      ctx.font = 'bolder 16px Sora';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(
        `Total Transactors`,
        chart.getDatasetMeta(0).data[0].x,
        chart.getDatasetMeta(0).data[0].y - 20,
      );
      ctx.font = 'bolder 24px Sora';
      ctx.fillText(
        clientMonthlyStats.transactors.toLocaleString(),
        chart.getDatasetMeta(0).data[0].x,
        chart.getDatasetMeta(0).data[0].y + 20,
      );
    },
  };

  const navigate = useNavigate();
  return (
    <TransactorOverviewContent>
      <TransactorOverviewContentHeader color={baseColors.text.textBlue80} size="medium">
        Transactor account analysis
      </TransactorOverviewContentHeader>
      <TransactorOverviewContentSubtext size="large">
        You had {clientMonthlyStats.transactors.toLocaleString()} Likely Transactors this month. Of
        those, {((data[4] / clientMonthlyStats.transactors) * 100).toFixed(1)} were transacting on 5
        or more accounts.
      </TransactorOverviewContentSubtext>
      <LowerCardContainer>
        <TransactorOverviewGraphContainer>
          <DoughnutChart data={pieData} options={doughnutOptions} plugins={[textCenter]} />
        </TransactorOverviewGraphContainer>
        <ChartLegendContainer>
          <LegendHeader>
            <HeaderText color={baseColors.text.textBlue80} size="small">
              Number of Competitor transacting accounts held by transactors.
            </HeaderText>
          </LegendHeader>
          {legendDots.map((dot, index) => (
            <LegendLine key={index}>
              <LegendItem>
                <LegendColorCircle src={dot} />
                <LegendPercent color={baseColors.text.textBlue100} size="small">
                  {((data[index] / clientMonthlyStats.transactors) * 100).toFixed(2) + '%'}
                </LegendPercent>
                <LegendNumber color={baseColors.text.textBlue100} size="large">
                  {index + 1 >= 5 ? '5 or more' : index + 1}
                </LegendNumber>
              </LegendItem>
            </LegendLine>
          ))}
          <hr></hr>
          <TotalAccountsText>
            <HeaderText color={baseColors.text.textBlue100} size="small">
              {totalAccounts.toLocaleString()}
            </HeaderText>
            <BodyText size="large">Total Transacting accounts held by Transactors.</BodyText>
          </TotalAccountsText>
        </ChartLegendContainer>
      </LowerCardContainer>
      <TransactorOverviewContentHeaderBottom size="medium">
        Take Action
      </TransactorOverviewContentHeaderBottom>
      <TransactorOverviewContentSubtextBottom size="large">
        We recommend the baseline Transactor Campaign to take advantage of card shift opportunity
        and grow your credit portfolio.
      </TransactorOverviewContentSubtextBottom>
      <TransactorOverviewContentButtonBottom
        label="View the Baseline Transactor Campaign"
        size="large"
        onClick={() => navigate('/marketing-resources')}
      ></TransactorOverviewContentButtonBottom>
    </TransactorOverviewContent>
  );
};

export default TransactorOverviewTab;
