import { ApiResponse } from '../models/api-response';
import { AxiosHeaders, AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { callExternalApi } from './external-api.service';
import { ClientMonthlyStats } from '../models/ClientMonthlyStats';
import {
  analysisOutputDownloadEndpointUrlBase,
  clientMonthlyStatsEndpointUrlBase,
  downloadsUrlBase,
} from '../api-routes';

export async function getClientMonthlyStats(
  accessToken: string,
  year?: string,
  month?: string,
): Promise<ApiResponse<ClientMonthlyStats[] | ClientMonthlyStats>> {
  const requestHeaders = new AxiosHeaders();
  requestHeaders.set('Authorization', `Bearer ${accessToken}`);

  let endpointUrl = `${clientMonthlyStatsEndpointUrlBase}`;
  if (year) {
    endpointUrl += `/${year}`;
    if (month) {
      endpointUrl += `/${month}`;
    }
  }

  const config: AxiosRequestConfig = {
    url: endpointUrl,
    method: 'GET',
    headers: requestHeaders as AxiosRequestHeaders,
  };

  const { data, status, headers, error } = (await callExternalApi({
    config,
  })) as ApiResponse<ClientMonthlyStats[] | ClientMonthlyStats>;

  return {
    data,
    status,
    headers,
    error,
  };
}

export async function getUniqueClientMonthlyStats(
  accessToken: string,
  year: string,
  month: string,
): Promise<ApiResponse<ClientMonthlyStats>> {
  const requestHeaders = new AxiosHeaders();
  requestHeaders.set('Authorization', `Bearer ${accessToken}`);

  let endpointUrl = `${clientMonthlyStatsEndpointUrlBase}`;
  if (year) {
    endpointUrl += `/${year}`;
    if (month) {
      endpointUrl += `/${month}`;
    }
  }

  const config: AxiosRequestConfig = {
    url: endpointUrl,
    method: 'GET',
    headers: requestHeaders as AxiosRequestHeaders,
  };

  const { data, status, headers, error } = (await callExternalApi({
    config,
  })) as ApiResponse<ClientMonthlyStats>;

  return {
    data,
    status,
    headers,
    error,
  };
}

export const getAnalysisResultOutputFileKeys = async (
  accessToken: string,
  year: string,
  month: string,
): Promise<ApiResponse<string[]>> => {
  const requestHeaders = new AxiosHeaders();
  requestHeaders.set('Authorization', `Bearer ${accessToken}`);

  const config: AxiosRequestConfig = {
    url: `${analysisOutputDownloadEndpointUrlBase}/${year}/${month}`,
    method: 'GET',
    headers: requestHeaders as AxiosRequestHeaders,
  };

  const { data, status, headers, error } = (await callExternalApi({
    config,
  })) as ApiResponse<string[]>;

  return {
    data,
    status,
    headers,
    error,
  };
};

export const getAnalysisResultOutputPresignedUrls = async (
  accessToken: string,
  objectKeys: string[],
): Promise<ApiResponse<string[]>> => {
  const requestHeaders = new AxiosHeaders();
  requestHeaders.set('Authorization', `Bearer ${accessToken}`);

  const config: AxiosRequestConfig = {
    url: `${downloadsUrlBase}`,
    method: 'POST',
    headers: requestHeaders as AxiosRequestHeaders,
    data: { objectKeys: objectKeys },
  };

  const { data, status, headers, error } = (await callExternalApi({
    config,
  })) as ApiResponse<string[]>;

  return {
    data,
    status,
    headers,
    error,
  };
};
