import React from 'react';
import { Chart } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js';
import { SankeyController, Flow } from 'chartjs-chart-sankey';

ChartJS.register(SankeyController, Flow);
/* eslint-disable  @typescript-eslint/no-explicit-any */
interface SankeyChartProps {
  data: any;
}

const SankeyChart: React.FC<SankeyChartProps> = ({ data }) => {
  return (
    <Chart
      type="sankey"
      data={data}
      options={{
        parsing: {
          from: 'source',
          to: 'destination',
          flow: 'value',
        },
        plugins: {
          tooltip: {
            enabled: false,
          },
        },
      }}
    />
  );
};

export default SankeyChart;
