import { FC } from 'react';

interface CrossIconProps {
  width: string;
  height: string;
  color: string;
}

const CrossIcon: FC<CrossIconProps> = ({ color, width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5185 4.51853L13.0371 3.99998L12 2.96289L11.4814 3.48144L7.99998 6.96289L4.51853 3.48144L3.99998 2.96289L2.96289 3.99998L3.48144 4.51853L6.96289 7.99998L3.48144 11.4814L2.96289 12L3.99998 13.0371L4.51853 12.5185L7.99998 9.03707L11.4814 12.5185L12 13.0371L13.0371 12L12.5185 11.4814L9.03707 7.99998L12.5185 4.51853Z"
      fill={color}
    />
  </svg>
);

export default CrossIcon;
