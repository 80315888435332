/* eslint-disable  @typescript-eslint/no-explicit-any */
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, AxiosResponseHeaders } from 'axios';
import { ApiResponse } from '../models/api-response';
import { AppError } from '../models/app-error';

export const callExternalApi = async (options: {
  config: AxiosRequestConfig;
}): Promise<ApiResponse<any>> => {
  try {
    const response: AxiosResponse = await axios(options.config);
    const { data, status, headers } = response;

    return {
      data,
      status,
      headers: headers as AxiosResponseHeaders,
      error: null,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;

      const { response } = axiosError;

      let message = 'http request failed';

      if (response && response.statusText) {
        message = response.statusText;
      }

      if (axiosError.message) {
        message = axiosError.message;
      }

      if (response && response.data && (response.data as AppError).message) {
        message = (response.data as AppError).message;
      }

      return {
        data: null,
        status: response?.status,
        headers: response?.headers as AxiosResponseHeaders,
        error: {
          message,
        },
      };
    }

    return {
      data: null,
      status: undefined,
      headers: undefined,
      error: {
        message: (error as Error).message,
      },
    };
  }
};
