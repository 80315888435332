import { ApiResponse } from '../models/api-response';
import { AxiosHeaders, AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { callExternalApi } from './external-api.service';
import { RevolverTopBankPayments } from '../models/RevolverTopBankPayments';
import { revolverTopBankPaymentsEndpointUrlBase } from '../api-routes';

const topBanks: string[] = ['boa', 'amex', 'cap_one', 'chase', 'citi', 'disc', 'usbank', 'wells'];

const filterTopBanks = (payments: RevolverTopBankPayments[]) => {
  const filteredPayments = payments.filter((payment) => {
    return topBanks.includes(payment.bankName);
  });
  return filteredPayments;
};

export async function getRevolverTopBankPayments(
  accessToken: string,
  year: string,
  month: string,
): Promise<ApiResponse<RevolverTopBankPayments[]>> {
  const requestHeaders = new AxiosHeaders();
  requestHeaders.set('Authorization', `Bearer ${accessToken}`);

  const endpointUrl = `${revolverTopBankPaymentsEndpointUrlBase}/${year}/${month}`;

  const config: AxiosRequestConfig = {
    url: endpointUrl,
    method: 'GET',
    headers: requestHeaders as AxiosRequestHeaders,
  };

  const { data, status, headers, error } = (await callExternalApi({
    config,
  })) as ApiResponse<RevolverTopBankPayments[]>;
  const filteredData = data && filterTopBanks(data);
  return {
    data: filteredData,
    status,
    headers,
    error,
  };
}
