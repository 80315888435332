import { useEffect, useState, useMemo, useCallback } from 'react';
import { ClientMonthlyStats } from '../models/ClientMonthlyStats';
import { formatAnalysisDate } from '../utils/date';
import { numberWithCommas } from '../utils/common';
import { CustomColumn, CustomTable, DataRow, FilterOption } from './tables/CustomTable';
import Link from './links/Link';
import ButtonIconTertiary from './buttons/ButtonIconTertiary';
import {
  getAnalysisResultOutputFileKeys,
  getAnalysisResultOutputPresignedUrls,
} from '../services/client-monthly-stats.service';
import { getUserType } from '../utils/account-holder-translation';
import { useClientStore } from '../stores/clientStore';

interface MonthlyAnalysisHistoryProps {
  accessToken: string;
  clientMonthlyStats: ClientMonthlyStats[];
}

export default function MonthlyAnalysisHistory({
  accessToken,
  clientMonthlyStats,
}: MonthlyAnalysisHistoryProps): JSX.Element {
  const userClient = useClientStore((state) => state.client);
  const institutionType = userClient?.institutionType;

  const [isBusy, setBusy] = useState<boolean>(true);

  const handleDownload = useCallback(
    async (year: string, month: string) => {
      // fetch available downloads from S3 (this just returns a list of S3 object keys)
      const availableFiles = await getAnalysisResultOutputFileKeys(accessToken, year, month);
      if (availableFiles.data) {
        const analysisResultsFile = availableFiles.data.filter((file) =>
          availableFiles.data?.some(() => file.includes('analysis_results.csv')),
        );

        if (analysisResultsFile) {
          const presignedUrls = await getAnalysisResultOutputPresignedUrls(
            accessToken,
            analysisResultsFile,
          );
          if (presignedUrls && presignedUrls.data) {
            const presignedUrl = presignedUrls.data[0]; // there should only ever be 1 result in this list
            if (presignedUrl) {
              // Manually create <a> tag so we can simulate a click
              fetch(presignedUrl).then((response) => {
                response.blob().then((blob) => {
                  // Creating new object of PDF file
                  const fileURL = window.URL.createObjectURL(blob);
                  // Setting various property values
                  const alink = document.createElement('a');
                  alink.href = fileURL;
                  alink.download = `analysis_results-${year}-${month}.csv`;
                  alink.click();
                });
              });
            }
          }
        }
      }
    },
    [accessToken],
  );

  useEffect(() => {
    const loadClientMonthlyStats = async () => {
      const sorter = (a: ClientMonthlyStats, b: ClientMonthlyStats) => {
        if (a.year !== b.year) {
          return parseInt(b.year) - parseInt(a.year);
        } else {
          return parseInt(b.month) - parseInt(a.month);
        }
      };
      clientMonthlyStats.sort(sorter);
      setBusy(false);
    };

    loadClientMonthlyStats();
  }, [accessToken, clientMonthlyStats]);

  const columns: CustomColumn[] = useMemo(
    () => [
      {
        Header: 'Month',
        accessor: 'displayDate',
        sortType: (rowA, rowB) => {
          const dateA = new Date(`01 ${rowA.values.displayDate}`);
          const dateB = new Date(`01 ${rowB.values.displayDate}`);

          return dateA.getTime() - dateB.getTime();
        },
      },
      {
        Header: `Total ${getUserType(institutionType, false, 'plural')}`,
        accessor: 'totalMembers',
      },
      { Header: 'Likely revolvers', accessor: 'likelyRevolvers' },
      { Header: '', accessor: 'actions', disableSortBy: true },
    ],
    [institutionType],
  );

  const uniqueYears = useMemo(() => {
    return Array.from(new Set(clientMonthlyStats.map((item) => item.year)));
  }, [clientMonthlyStats]);

  const filterOptions: FilterOption[] = useMemo(
    () => [
      {
        id: 'year',
        label: 'Year',
        options: uniqueYears.map((year) => ({ value: year, label: year })),
        filterFn: (row, value) => {
          if (value === null || value === '' || value === undefined) {
            return true;
          }
          if (row.year === value) {
            return true;
          }
          return false;
        },
      },
    ],
    [uniqueYears],
  );

  const data: DataRow[] = useMemo(() => {
    const preFilteredData = clientMonthlyStats.map((analysis) => {
      const {
        year,
        month,
        completedOn,
        heavyRevolvers,
        lightRevolvers,
        transactors,
        cantCategorize,
        nonCardPayers,
      } = analysis;

      if (!completedOn) {
        return {};
      }

      const displayDate = formatAnalysisDate(month, year);
      const totalMembers = numberWithCommas(
        (heavyRevolvers + lightRevolvers + transactors + cantCategorize + nonCardPayers).toString(),
      );
      const likelyRevolvers = numberWithCommas((heavyRevolvers + lightRevolvers).toString());
      const actions = (
        <div style={{ display: 'flex' }}>
          <Link href={`/analysis/${year}/${month}`} size="large">
            View analysis
          </Link>
          <ButtonIconTertiary
            size="large"
            icon="/feather-sprite.svg#download-cloud"
            onClick={() => handleDownload(year, month)}
          />
        </div>
      );

      return { displayDate, totalMembers, likelyRevolvers, actions, year };
    });

    // Filter out any '{}' values from the preFilteredData array
    const filteredData = preFilteredData.filter((row) => Object.keys(row).length !== 0);

    const sortedData = filteredData.sort((a: DataRow, b: DataRow) => {
      // Descending sort by year and month
      if (a.displayDate && b.displayDate) {
        // Parse the year and month from displayDate
        const [monthA, yearA] = a.displayDate.split(', ');
        const [monthB, yearB] = b.displayDate.split(', ');

        // Convert month names to numbers for comparison
        const monthNumA = new Date(Date.parse(monthA + ' 1, 2000')).getMonth() + 1;
        const monthNumB = new Date(Date.parse(monthB + ' 1, 2000')).getMonth() + 1;

        // Calculate score for comparison
        const scoreA = parseInt(yearA, 10) * 100 + monthNumA;
        const scoreB = parseInt(yearB, 10) * 100 + monthNumB;

        return scoreB - scoreA;
      }

      return 0; // If either 'displayDate' is undefined, consider the elements equal
    });

    return sortedData;
  }, [clientMonthlyStats, handleDownload]);

  return (
    <>
      {clientMonthlyStats && (
        <div>
          {isBusy ? (
            'loading...'
          ) : (
            <CustomTable
              columns={columns}
              data={data}
              enableSorting={true}
              enableSearch={true}
              filterOptions={filterOptions}
            />
          )}
        </div>
      )}
    </>
  );
}
