import React, { FC } from 'react';
import styled from 'styled-components';
import Card from './outlines/Card';
import { baseColors } from './themeing/themes';
import { BodyText } from './typography/BodyText';
import { HeaderText } from './typography/HeaderText';

const colorsByIndex = [
  baseColors.system.info5,
  baseColors.system.info5,
  baseColors.system.info5,
  baseColors.accent.accentGreen5,
  baseColors.primary.primary100,
];

interface TextBoxProps {
  title: string;
  text: string;
  hyperlink?: React.ReactNode;
  tooltip?: React.ReactNode;
  index?: number;
  background?: string;
  icon?: string;
  borderColor?: string;
  fontSize?: string;
  marginTop?: string;
}

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative; /* Add relative positioning to the parent element */
  bottom: 12px; /* Adjust the bottom value to control the vertical alignment */
  left: 0; /* Align the button to the left */
`;

const TextBoxCard = styled(Card)`
  display: block;
  border: 1px solid ${(props) => props.borderColor || 'transparent'};
  border-radius: 16px;
`;

const TextBoxHeader = styled(HeaderText)<{ fontSize?: string }>`
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  line-height: 1.2;
  font-size: ${(props) => props.fontSize || 'medium'};
`;

const TextBoxText = styled(BodyText)<{ marginTop?: string }>`
  text-align: left;
  margin-left: 44px;
  line-height: 1.2;
  margin-right: 12px;
  font-size: 14px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: ${(props) => props.marginTop || '0px'};
`;

const Icon = styled.img`
  width: '32px';
  height: '32px';
  margin-right: 12px;
`;

const Hyperlink = styled.a`
  color: var(--primary-primary-100, #413de7);
  cursor: pointer;
  text-decoration: none;
  margin-right: 24px;
  margin-bottom: 24px;
  margin-top: 24px;
  font-size: 16px;
  font-family: Sora;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative; /* Add relative positioning */
  font-size: 18x;
  font-family: Sora;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`;

const Tooltip = styled.div`
  position: absolute;
  top: -5px; /* Adjust the top value to position the tooltip vertically */
  right: 0; /* Align the tooltip to the right */
  padding: 8px;
  border-radius: 4px;

  img {
    width: 24px;
    height: 24px;
  }
`;

const TextBox: FC<TextBoxProps> = ({
  title,
  text,
  hyperlink,
  tooltip,
  index,
  background,
  icon,
  borderColor,
  marginTop,
  fontSize,
}) => (
  <TextBoxCard
    width="257px"
    height="131px"
    background={background || (index !== undefined ? colorsByIndex[index] : undefined)}
    borderColor={borderColor}
  >
    <HeaderContainer>
      {icon && <Icon src={icon} alt="Icon" />}
      <TextBoxHeader size="medium" fontSize={fontSize}>
        {title}
      </TextBoxHeader>
      {tooltip && <Tooltip>{tooltip}</Tooltip>}
    </HeaderContainer>
    <ContentContainer>
      <TextBoxText marginTop={marginTop} size="large">
        {text}
      </TextBoxText>
      <ButtonContainer>{hyperlink && <Hyperlink href="#">{hyperlink}</Hyperlink>}</ButtonContainer>
      &nbsp;
    </ContentContainer>
  </TextBoxCard>
);

const TextBoxRowContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  width: 100%;

  > div {
    flex: 1 0 calc(25% - 24px);
    padding: 16px;
  }

  @media (max-width: 800px) {
    > div {
      flex: 1 0 calc(50% - 24px);
    }
  }
`;

interface TextBoxRowProps {
  boxes: TextBoxProps[];
}

const TextBoxRow: FC<TextBoxRowProps> = ({ boxes }) => (
  <TextBoxRowContainer>
    {boxes.map((box, index) => (
      <TextBox
        key={index}
        index={index}
        title={box.title}
        text={box.text}
        marginTop={box.marginTop}
        hyperlink={box.hyperlink}
        tooltip={box.tooltip}
        icon={box.icon}
        background={box.background}
        borderColor={box.borderColor}
        fontSize={box.fontSize}
      />
    ))}
  </TextBoxRowContainer>
);

export default TextBoxRow;
