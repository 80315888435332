import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { BodyText } from '../typography/BodyText';

type InputProps = {
  width?: number;
  height?: number;
  labelLeft?: string;
  labelRight?: string;
  labelIcon?: string;
  placeholder?: string;
  icon?: string;
  validationFn?: (input: string) => boolean;
  validationMessage?: string;
  defaultValue?: string;
  disabled?: boolean;
  value: string;
  onChange?: (change: React.ChangeEvent<HTMLInputElement> | undefined) => void;
  onKeyDown?: (change: React.KeyboardEvent<HTMLInputElement>) => void;
  tooltip?: React.ReactNode;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const LabelsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RightLabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const LabelIcon = styled.div`
  margin-left: 8px;
  width: 17px;
  height: 17px;
  fill: none;
  stroke: ${({ theme }) => theme.text.textBlue60};
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const StyledIcon = styled.svg`
  position: absolute;
  left: 8px; // Adjust as needed
  width: 14px;
  height: 14px;
  fill: none;
  stroke: currentColor;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
`;

const StyledInput = styled.input<{
  disabled: boolean;
  isError: boolean;
  width?: number;
  height?: number;
  icon?: string;
}>`
  box-sizing: border-box;
  width: ${({ width = 244 }) => `${width}px`};
  height: ${({ height = 42 }) => `${height}px`};
  border-style: solid;
  border-width: 1px;
  border-color: ${({ isError, theme, disabled }) =>
    disabled ? theme.primary.primary20 : isError ? theme.system.error100 : theme.primary.primary50};

  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: ${({ icon }) => (icon ? '12px' : '28px')};
  padding-right: 12px;
  border-radius: 12px;
  overflow: hidden;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.text.textBlue80};

  :hover {
    box-shadow: 0px 10px 20px rgba(65, 61, 231, 0.04), 0px 4px 15px rgba(85, 85, 88, 0.02);
  }

  :focus {
    outline: none;
    border-color: ${({ theme, isError }) =>
      isError ? theme.system.error100 : theme.primary.primary50};
  }

  ::placeholder {
    color: ${({ theme }) => theme.text.textBlue60};
    opacity: 1;
  }
`;

const Input: React.FC<InputProps> = ({
  labelLeft,
  labelRight,
  labelIcon,
  placeholder,
  icon,
  validationFn,
  validationMessage = '',
  defaultValue = '', // eslint-disable-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
  disabled = false,
  value,
  onChange,
  onKeyDown,
  tooltip,
  ...props
}) => {
  const [isError, setIsError] = useState(false);
  const [internalValue, setInternalValue] = useState(value); // eslint-disable-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars

  useEffect(() => {
    if (validationFn) {
      setIsError(!validationFn(value));
    }
  }, [validationFn, value]);

  return (
    <Container>
      <LabelsRow>
        {labelLeft && <BodyText size="medium">{labelLeft}</BodyText>}
        <RightLabelContainer>
          {labelRight && (
            <BodyText size="medium" color="#697392">
              {labelRight}
            </BodyText>
          )}
          {labelIcon && tooltip && <LabelIcon>{tooltip}</LabelIcon>}
        </RightLabelContainer>
      </LabelsRow>
      <InputContainer>
        <StyledInput
          placeholder={placeholder}
          value={value}
          onChange={(e) => {
            setInternalValue(e.target.value);
            onChange && onChange(e);
          }}
          onKeyDown={onKeyDown}
          disabled={disabled}
          isError={isError}
          {...props}
        />
        {icon && (
          <StyledIcon>
            <use href={icon} />
          </StyledIcon>
        )}
      </InputContainer>
      {isError && (
        <BodyText size="small" color="red">
          {validationMessage}
        </BodyText>
      )}
    </Container>
  );
};

export default Input;
