import React from 'react';
import styled from 'styled-components';
import * as Tooltip from '@radix-ui/react-tooltip';
import { HeaderText } from './typography/HeaderText';
import { BodyText } from './typography/BodyText';
import helpCircle from '../images/help-circle.svg';
import hoverHelpCircle from '../images/hoverTooltipIcon.svg';

const sizes = {
  small: {
    top: {
      start: {
        bottom: 'calc(100% + 6px)',
        left: '10px',
      },
      center: {
        bottom: 'calc(100% + 6px)',
        left: '50%',
      },
      end: {
        bottom: 'calc(100% + 6px)',
        left: '10px',
      },
      marginSide: 'bottom',
    },
    right: {
      start: {
        bottom: 'calc(100% + 6px)',
        left: '10px',
      },
      center: {
        bottom: 'calc(100% + 6px)',
        left: '50%',
      },
      end: {
        bottom: 'calc(100% + 6px)',
        left: '10px',
      },
      marginSide: 'left',
    },
    bottom: {
      start: {
        bottom: 'calc(100% + 6px)',
        left: '10px',
      },
      center: {
        bottom: 'calc(100% + 6px)',
        left: '50%',
      },
      end: {
        bottom: 'calc(100% + 6px)',
        left: '10px',
      },
      marginSide: 'top',
    },
    left: {
      start: {
        bottom: 'calc(100% + 6px)',
        left: '10px',
      },
      center: {
        bottom: 'calc(100% + 6px)',
        left: '50%',
      },
      end: {
        bottom: 'calc(100% + 6px)',
        left: '10px',
      },
      marginSide: 'right',
    },
  },
  large: {
    top: {
      start: {
        bottom: '15px',
        left: '15px',
      },
      center: {
        bottom: 'calc(100% + 6px)',
        left: '50%',
      },
      end: {
        bottom: 'calc(100% + -25px)',
        left: '250px',
      },
      marginSide: 'bottom',
    },
    right: {
      start: {
        bottom: 'calc(100% + -126px)',
        left: '5px',
      },
      center: {
        bottom: 'calc(100% + -74px)',
        left: '5px',
      },
      end: {
        bottom: 'calc(100% + -20px)',
        left: '5px',
      },
      marginSide: 'left',
    },
    bottom: {
      start: {
        bottom: 'calc(100% + 6px)',
        left: '10px',
      },
      center: {
        bottom: 'calc(100% + 6px)',
        left: '50%',
      },
      end: {
        bottom: 'calc(100% + 12px)',
        left: '10px',
      },
      marginSide: 'top',
    },
    left: {
      start: {
        bottom: 'calc(100% + 6px)',
        left: '0px',
      },
      center: {
        bottom: 'calc(100% + 6px)',
        left: '50%',
      },
      end: {
        bottom: 'calc(100% + 6px)',
        left: '10px',
      },
      marginSide: 'right',
    },
  },
};

export interface TooltipProps {
  /**
   * Inner content of the tooltip
   */
  content?: React.ReactNode;
  /**
   * Header title of the tooltip
   */
  header: string;
  /**
   * Size of the tooltip
   */
  size: 'small' | 'large';
  /**
   * Side of the tooltip
   */
  side: 'top' | 'right' | 'bottom' | 'left';
  /**
   * Alignment of the tooltip
   */
  alignment: 'start' | 'center' | 'end';
  /**
   * ReactNode to trigger the tooltip
   */
  trigger?: React.ReactNode;
  hoverTrigger?: React.ReactNode;
}

interface ArrowProps extends Tooltip.TooltipArrowProps {
  /**
   * Size of the tooltip
   */
  size: 'small' | 'large';
  /**
   * Side of the tooltip
   */
  side: 'top' | 'right' | 'bottom' | 'left';
  /**
   * Alignment of the tooltip
   */
  alignment: 'start' | 'center' | 'end';
}

interface ContentProps extends Tooltip.TooltipContentProps {
  /**
   * Size of the tooltip
   */
  size: 'small' | 'large';
  /**
   * Side of the tooltip
   */
  side: 'top' | 'right' | 'bottom' | 'left';
}

const ContentContainer = styled.div<ArrowProps>`
  div:first-child {
    width: ${({ size }) => (size == 'large' ? '252px' : '101px')};
    max-width: ${({ size }) => (size == 'large' ? '252px' : '101px')};
    text-align: ${({ size }) => (size == 'large' ? 'left' : 'center')};
  }
  span:has(> svg) {
    left: ${({ side, alignment, size }) => sizes[size][side][alignment].left} !important;
    top: ${({ side, alignment, size }) => sizes[size][side][alignment].bottom} !important;
    margin-${({ side, size }) => sizes[size][side].marginSide}: 10px !important;
  }
`;

const Arrow = styled(Tooltip.Arrow)`
  position: absolute;
  fill: white;
  border-right: 1px solid #dbe2ff;
  border-bottom: 1px solid #dbe2ff;
  border-top: none;
  border-left: none;
  box-shadow: inset -5px -5px 5px -2px white;
  transform: rotate(45deg);
`;

const Content = styled(Tooltip.Content)<ContentProps>`
  background-color: #fff;
  border: 1px solid #dbe2ff;
  border-radius: 16px;
  box-shadow: 0px 10px 20px rgba(65, 61, 231, 0.04), 0px 4px 15px rgba(85, 85, 88, 0.02);
  padding: 16px;
  margin-${({ side, size }) => sizes[size][side].marginSide}: 10px;
`;

/**
 * `Tooltip` is a reusable, styled content container component.
 *
 * Props:
 * - `content`: Content of the tooltip. Should be a React node.
 * - `header`: Header of the tooltip. Should be a string.
 * - `size`: Size of the tooltip. Should be either 'small' or 'large'. If small, the width will be 101px and have no body text. If large, the width will be 252px.
 * - `side`: Side of the tooltip. Should be either 'top', 'right', 'bottom', or 'left'.
 * - `alignment`: Alignment of the tooltip. Should be either 'start', 'center', or 'end'.
 * - `trigger`: React node to trigger the tooltip. Should be a React node.
 * - `hoverTrigger`: React node to trigger the tooltip on hover. Should be a React node. If you don't want a hover state trigger, you can pass in the same node as the `trigger` prop.
 *
 * The `Tooltip` is designed to be fully flexible and customizable through props, allowing you to specify the
 * size, labels, icons, validation function, and other key properties.
 */
const StyledTooltip = ({
  side = 'right',
  alignment = 'center',
  header,
  size,
  content,
  trigger = <img src={helpCircle} />,
  hoverTrigger = <img src={hoverHelpCircle} />,
}: TooltipProps) => {
  const [hovered, setHovered] = React.useState(false);
  return (
    <Tooltip.Provider delayDuration={300}>
      <Tooltip.Root>
        <div onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
          <Tooltip.Trigger asChild>{hovered ? hoverTrigger : trigger}</Tooltip.Trigger>
        </div>
        <Tooltip.Portal>
          <ContentContainer
            side={side}
            size={size}
            alignment={alignment}
            className="ContentContainer"
          >
            <Content side={side} size={size} align={alignment} avoidCollisions={false}>
              <HeaderText size="small">{header}</HeaderText>
              {size == 'large' && <BodyText size="small">{content}</BodyText>}
              {side == 'right' && <Arrow width={8} height={8} />}
            </Content>
          </ContentContainer>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};

export default StyledTooltip;
