import styled from 'styled-components';

export interface LinkTextProps {
  size: 'small' | 'medium' | 'large';
}

const sizeStyles = {
  small: {
    fontSize: '12px',
    lineHeight: '16px',
  },
  medium: {
    fontSize: '14px',
    lineHeight: '20px',
  },
  large: {
    fontSize: '16px',
    lineHeight: '22px',
  },
};

/**
 * LinkText is a styled-component that renders a <p> element with custom styles
 * based on the provided size. It uses the Nunito Sans font family, normal font style,
 * 700 font weight, and is underlined. The text color can be customized through the optional `color`
 * prop, or it will default to the value specified in the theme. The `size` prop
 * is mandatory and determines the font size and line height.
 *
 * Sizes:
 * - small:  font-size: 12px; line-height: 16px;
 * - medium: font-size: 14px; line-height: 20px;
 * - large:  font-size: 16px; line-height: 22px;
 *
 * @component
 * @example
 * // Render the BodyText with a small size and custom color
 * <BodyText size="small" color="red">This is a small BodyText</BodyText>
 *
 * @example
 * // Render the BodyText with a medium size and default color
 * <BodyText size="medium">This is a medium BodyText with default color</BodyText>
 *
 * @example
 * // Render the BodyText with a large size and custom color
 * <BodyText size="large" color="green">This is a large BodyText with green color</BodyText>
 */
export const LinkText = styled.p<LinkTextProps>`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  text-decoration-line: underline;
  font-size: ${(props) => sizeStyles[props.size].fontSize};
  line-height: ${(props) => sizeStyles[props.size].lineHeight};
  color: ${(props) => props.theme.primary.primary100};
`;
