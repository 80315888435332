import { RevolvingAccountData } from '../models/RevolvingAccounts';
import { TabData } from '../components/navigation/Tabs';

export const numberWithCommas = (value: string) => {
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/**
 * Converts a number to a string with shorthand abbreviations.
 *
 * This function takes a number as input and returns a string representation of the number
 * with the appropriate abbreviation for thousands (K), millions (M), or billions (B).
 * The abbreviation is rounded to one decimal place, and trailing zeros and decimal points
 * are removed when not needed.
 *
 * @param {number} num - The input number to be abbreviated.
 * @returns {string} The abbreviated string representation of the input number.
 *
 * @example
 * abbreviateNumber(1000000000) // returns '1B'
 * abbreviateNumber(1000000)    // returns '1M'
 * abbreviateNumber(1000)       // returns '1K'
 * abbreviateNumber(123456789)  // returns '123.5M'
 */
export function abbreviateNumber(num: number): string {
  let abbreviatedNum: string;

  if (num >= 1_000_000_000) {
    abbreviatedNum = (num / 1_000_000_000).toFixed(1) + 'B';
  } else if (num >= 1_000_000) {
    abbreviatedNum = (num / 1_000_000).toFixed(1) + 'M';
  } else if (num >= 1_000) {
    abbreviatedNum = (num / 1_000).toFixed(1) + 'K';
  } else {
    abbreviatedNum = num.toString();
  }

  // Remove trailing zeros and decimal point, if necessary
  abbreviatedNum = abbreviatedNum.replace(/\.0+$/, '');

  return abbreviatedNum;
}

export function abbreviateToInt(num: number): string {
  let abbreviatedNum: string;

  if (num >= 1_000_000_000) {
    abbreviatedNum = (num / 1_000_000_000).toFixed(0) + 'B';
  } else if (num >= 1_000_000) {
    abbreviatedNum = (num / 1_000_000).toFixed(0) + 'M';
  } else if (num >= 1_000) {
    abbreviatedNum = (num / 1_000).toFixed(0) + 'K';
  } else {
    abbreviatedNum = num.toString();
  }

  // Remove trailing zeros and decimal point, if necessary
  abbreviatedNum = abbreviatedNum.replace(/\.0+$/, '');

  return abbreviatedNum;
}

export const sanitizeLikelyRevolverData = (data: RevolvingAccountData[]) => {
  return data.map((item) => {
    const sanitizedNumberOfMembers = parseInt(item.number_of_members);
    return {
      ...item,
      number_of_members: isNaN(sanitizedNumberOfMembers)
        ? '0'
        : sanitizedNumberOfMembers.toString(),
    };
  });
};

export const compareTabs = (parentTabs: TabData[], newTabs: TabData[]) => {
  let equal = true;
  if (parentTabs.length !== newTabs.length) {
    equal = false;
  }
  parentTabs.forEach((parentTab, idx) => {
    if (parentTab.subtabs?.length !== newTabs[idx].subtabs?.length) {
      equal = false;
    }
  });
  return equal;
};

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const areAllNumberFieldsZero = (obj: Record<string, any>): boolean => {
  for (const key in obj) {
    if (typeof obj[key] === 'number' && obj[key] !== 0) {
      return false;
    }
  }
  return true;
};
