import styled from 'styled-components';
import { getUserType } from '../utils/account-holder-translation';
import { useClientStore } from '../stores/clientStore';
import { HeaderText } from './typography/HeaderText';
import { BodyText } from './typography/BodyText';

const StyledBodyText = styled(BodyText)`
  padding-top: 16px;
`;

const MinimumDataContainer = styled.div`
  padding-top: 32px;
`;

const StyledListItem = styled.li`
  margin-bottom: 12px;
`;

export function MinimumDataSection() {
  const userClient = useClientStore((state) => state.client);
  const institutionType = userClient?.institutionType;

  return (
    <MinimumDataContainer>
      <HeaderText size="small">Minimum data requirements</HeaderText>

      <StyledBodyText size="medium">
        Your dataset should include all checking account ACH withdrawal transactions in a CSV file
        format with the following column and row headers:
      </StyledBodyText>

      <ul>
        <StyledListItem>
          <BodyText size="medium">
            UUID: Anonymous user ID (this uniquely identifies each{' '}
            {getUserType(institutionType, false, 'singular')} but does not indicate the{' '}
            {getUserType(institutionType, false, 'possessive')} identity)
          </BodyText>
        </StyledListItem>
        <StyledListItem>
          <BodyText size="medium">Date: Transaction date</BodyText>
        </StyledListItem>
        <StyledListItem>
          <BodyText size="medium">
            Merchant: Name of the merchant involved in the transaction
          </BodyText>
        </StyledListItem>
        <StyledListItem>
          <BodyText size="medium">Amount: Transaction amount</BodyText>
        </StyledListItem>
        <li>
          <BodyText size="medium">
            If your files are larger than 1GB please add them all to one folder, compress (zip)
            them, and upload the .zip.
          </BodyText>
        </li>
      </ul>
    </MinimumDataContainer>
  );
}
