/* eslint-disable */
import { ChartData, ChartOptions, Plugin } from 'chart.js';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Title as ChartTitle,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
} from 'chart.js';
import { AnyObject } from 'chart.js/dist/types/basic';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, ChartTitle);

interface DoughnutChartProps {
  data: ChartData<'doughnut', number[], string[]> | null;
  options?: ChartOptions<'doughnut'>;
  plugins?: Plugin<'doughnut', AnyObject>[];
}

const defaultOptions = {
  cutout: '70%',
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: true,
      callbacks: {
        title: function (context: any) {
          return context[0].label[5];
        },
        label: function (context: any) {
          return context.label[4];
        },
      },
      backgroundColor: '#FFF',
      titleFontSize: 16,
      titleColor: 'black',
      bodyColor: 'black',
      bodyFontSize: 14,
      displayColors: false,
    },
  },
};

const DoughnutChart: React.FC<DoughnutChartProps> = ({
  data,
  options = defaultOptions,
  plugins,
}) => {
  return data && <Doughnut data={data} options={options} plugins={plugins} />;
};

export default DoughnutChart;
