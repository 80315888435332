import styled from 'styled-components';
import { DesignSystemColorsTheme } from '../../components/themeing/themes';

interface PathProps {
  active: boolean;
  hovered: boolean;
}

const StyledPath = styled.path<{ active: boolean; hovered: boolean }>`
  fill: ${({ active, hovered }) => {
    if (active && hovered) {
      return DesignSystemColorsTheme.primary.primaryHover;
    } else if (active && !hovered) {
      return DesignSystemColorsTheme.primary.primary100;
    } else if (!active && hovered) {
      return DesignSystemColorsTheme.text.textBlue100;
    } else {
      return DesignSystemColorsTheme.text.textBlue80;
    }
  }};
`;

export const AnalysisIcon = ({ active, hovered }: PathProps) => (
  <>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <StyledPath
        hovered={hovered}
        active={active}
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.9851 6H13.881V8.05003H18.5351L12.8662 13.7756L9.52441 10.4003C9.12808 10 8.4855 10 8.08917 10.4003L2 16.5504L3.43523 18L8.80679 12.5747L12.1486 15.95C12.545 16.3503 13.1875 16.3503 13.5839 15.95L19.9702 9.49972V14.2001H21.9999V7.02502C21.9999 6.45892 21.5456 6 20.9851 6Z"
      />
    </svg>
  </>
);
