import * as React from 'react';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

type Props = {
  children?: React.ReactNode;
};

export const Auth0ProviderWithHistory: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate();

  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  let redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL;

  // note: this is specificially for deploy previews to get around auth0 callback issues
  if (process.env.REACT_APP_CONTEXT === 'deploy-preview') {
    const reviewId = process.env.REACT_APP_REVIEW_ID;
    redirectUri = `https://deploy-preview-${reviewId}--fastidious-sprinkles-51799e.netlify.app/callback`;
  }

  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!(domain && clientId && audience)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={redirectUri}
      onRedirectCallback={onRedirectCallback}
      audience={audience}
    >
      {children}
    </Auth0Provider>
  );
};
