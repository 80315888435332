import { withAuthenticationRequired } from '@auth0/auth0-react';
import React, { ComponentType } from 'react';
import { PageLoader } from './PageLoader';
import { PageLayout } from './PageLayout';

interface ProtectedRouteProps {
  component: ComponentType;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <PageLayout>
        <PageLoader />
      </PageLayout>
    ),
  });

  return <Component />;
};
