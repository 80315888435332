import { create } from 'zustand';
import { Client } from '../models/Client';

interface ClientStore {
  client: Client | null;
  updateClient: (newClient: Client) => void;
}

export const useClientStore = create<ClientStore>((set) => ({
  client: null,
  updateClient: (newClient) => set(() => ({ client: newClient })),
}));
