import styled from 'styled-components';

export interface HeaderTextProps {
  color?: string;
  size: 'tiny' | 'small' | 'medium' | 'large';
}

const sizeStyles = {
  tiny: {
    fontSize: '14px',
    lineHeight: '16px',
  },
  small: {
    fontSize: '18px',
    lineHeight: '24px',
  },
  medium: {
    fontSize: '24px',
    lineHeight: '32px',
  },
  large: {
    fontSize: '28px',
    lineHeight: '40px',
  },
};

/**
 * HeaderText is a styled-component that renders an <h1> element with custom styles
 * based on the provided size. It uses the Sora font family, normal font style,
 * and 600 font weight. The text color can be customized through the optional `color`
 * prop, or it will default to the value specified in the theme. The `size` prop
 * is mandatory and determines the font size and line height.
 *
 * Sizes:
 * - small:  font-size: 18px; line-height: 24px;
 * - medium: font-size: 24px; line-height: 32px;
 * - large:  font-size: 28px; line-height: 40px;
 *
 * @component
 * @example
 * // Render the HeaderText with a small size and custom color
 * <HeaderText size="small" color="red">This is a small HeaderText</HeaderText>
 *
 * @example
 * // Render the HeaderText with a medium size and default color
 * <HeaderText size="medium">This is a medium HeaderText with default color</HeaderText>
 *
 * @example
 * // Render the HeaderText with a large size and custom color
 * <HeaderText size="large" color="green">This is a large HeaderText with green color</HeaderText>
 */
export const HeaderText = styled.h1<HeaderTextProps>`
  font-family: 'Sora';
  font-style: normal;
  font-weight: 600;
  font-size: ${({ size }) => sizeStyles[size].fontSize};
  line-height: ${({ size }) => sizeStyles[size].lineHeight};
  color: ${({ color, theme }) => color || theme.text.textBlue100};
  margin-top: 0;
  margin-bottom: 0;
`;
