import { AxiosHeaders, AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { ApiResponse } from '../models/api-response';
import { callExternalApi } from './external-api.service';
import { Client } from '../models/Client';

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const getClient = async (
  accessToken: string,
  clientId: string,
): Promise<ApiResponse<Client>> => {
  const requestHeaders = new AxiosHeaders();
  requestHeaders.set('Authorization', `Bearer ${accessToken}`);

  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/clients/${clientId}`,
    method: 'GET',
    headers: requestHeaders as AxiosRequestHeaders,
  };

  const { data, status, headers, error } = (await callExternalApi({
    config,
  })) as ApiResponse<Client>;

  return {
    data,
    status,
    headers,
    error,
  };
};
