import React, { useEffect, useState } from 'react';
import { getPresignedDownloadUrl } from '../../services/marketing-resources.service';
import styled from 'styled-components';
import AdditionalButton from '../../components/buttons/AdditionalButton';

const ViewDownloadSection = styled.div`
  display: flex;
  margin-left: -22px;
`;

type ViewDownloadProps = {
  resourceId: string | undefined;
  accessToken: string | undefined;
  resourceName: string | undefined;
};

type ViewDownloadButtonProp = {
  link: string | undefined;
  resourceName?: string | undefined;
};

const ViewButton = ({ link }: ViewDownloadButtonProp) => {
  const handleClick = async () => {
    window.open(link, '_blank'); //to open new page
  };
  return <AdditionalButton onClick={() => handleClick()} label="View" />;
};

const DownloadButton = ({ link, resourceName }: ViewDownloadButtonProp) => {
  const handleClick = async () => {
    if (link) {
      fetch(link).then((response) => {
        response.blob().then((blob) => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          const alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = `${resourceName}.pdf`;
          alink.click();
        });
      });
    }
  };

  return (
    <>
      <AdditionalButton onClick={() => handleClick()} label={'Download'} />
    </>
  );
};

export function ViewDownload({ resourceId, accessToken, resourceName }: ViewDownloadProps) {
  const [downloadLink, setDownloadLink] = useState<string | undefined>();

  useEffect(() => {
    const getDownloadLink = async () => {
      if (accessToken) {
        const presignedUrl = await getPresignedDownloadUrl(accessToken, resourceId);

        if (presignedUrl.data) {
          setDownloadLink(presignedUrl.data);
        }
      }
    };
    getDownloadLink();
  }, [accessToken, resourceId]);

  return (
    <ViewDownloadSection>
      <ViewButton link={downloadLink} />
      <DownloadButton link={downloadLink} resourceName={resourceName} />
    </ViewDownloadSection>
  );
}
