import styled from 'styled-components';
import csvIcon from './../images/design system/csv-icon.svg';
import { DesignSystemColorsTheme } from './themeing/themes';
import { ButtonText } from './typography/ButtonText';
import { BodyText } from './typography/BodyText';

const DropZoneContainer = styled.div`
  width: 100%;
  min-height: 244px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const DropZoneContent = styled.div`
  text-align: center;
`;

const IconContainer = styled.img`
  margin-bottom: 32px;
`;

const TextContainer = styled.div`
  margin-top: 20px;
`;

export const DropZone = () => (
  <DropZoneContainer>
    <DropZoneContent>
      <IconContainer src={csvIcon} alt="CSV Upload Icon" />
      <ButtonText size="large" color={DesignSystemColorsTheme.primary.primary100}>
        Select CSVs to upload
      </ButtonText>
      <TextContainer>
        <BodyText size="large">Or drag and drop them here</BodyText>
      </TextContainer>
    </DropZoneContent>
  </DropZoneContainer>
);
